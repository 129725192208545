import LinkIcon from "@mui/icons-material/Link";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Modal,
  ModalDialog,
  TextField,
  Typography
} from "@mui/joy";
import dayjs from "dayjs";
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  writeBatch
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { Dispatch, SetStateAction, useState } from "react";
import { getColors } from "./Color";
import { toDS, useDocument, WithId } from "./data";
import { documentHash, getFunctionsURL } from "./Infospot";
import { Document, DocumentLink, PublishedDocument, Workspace } from "./Model";

/*
const toDataURL = async (blob: Blob): Promise<string | null> => {
  const reader = new FileReader();
  return new Promise(resolve => {
    reader.addEventListener(
      "load",
      () => {
        if (reader.result instanceof ArrayBuffer) {
          resolve(null);
        } else {
          resolve(reader.result);
        }
      },
      false
    );

    reader.readAsDataURL(blob);
  });
};
*/

const PublishCard = ({
  doc_,
  link,
  linkName,
  setLinkName,
  onClose
}: {
  doc_: WithId<Document>;
  link?: WithId<DocumentLink>;
  linkName: string;
  setLinkName: Dispatch<SetStateAction<string>>;
  onClose: () => void;
}) => {
  const cleanupLinkName = linkName.replace(/[^0-9a-zA-Z_]/g, "");
  const actualLinkName = cleanupLinkName ? `${cleanupLinkName}-` : "";

  const linkId = doc_.shortId || doc_._id;
  const url = `${window.location.origin}/d/${actualLinkName}${linkId}`;
  const prettyUrl = `${window.location.host}/d/${actualLinkName}${linkId}`;

  const published = () => {
    if (link) {
      if (link.published) {
        return link.published.format("YYYY-MM-DD HH:mm");
      } else {
        return "unknown";
      }
    } else {
      return "not published yet";
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minWidth: 300 }}>
      <Typography level="h5" gutterBottom>
        Share "{doc_.name}"
      </Typography>
      {false && (
        <Typography level="body2" gutterBottom sx={{ maxWidth: 400 }}>
          Share the document with your customers via email, linkedin or any
          messenger app. Use the link below.
        </Typography>
      )}{" "}
      {false && (
        <Typography level="body2" gutterBottom sx={{ maxWidth: 400 }}>
          Publish current version of the document. Changes after publish are not
          visible in the published document. After first publish a link to share
          is available below.
        </Typography>
      )}
      {false && (
        <Typography level="body2" gutterBottom sx={{ maxWidth: 400 }}>
          Last published: {published()}
        </Typography>
      )}
      <Typography level="body2" sx={{ mt: 1, fontWeight: 800 }}>
        Preview
      </Typography>
      {
        <Card
          sx={{
            alignItems: "center",
            borderRadius: 4,
            border: "1px solid #e5e7eb",
            padding: 0,
            mt: 0.5,
            boxShadow:
              "0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -2px rgb(0 0 0 / 10%)"
          }}
        >
          {!link?.ogImage ? (
            <CircularProgress sx={{ m: 8 }} variant="soft" />
          ) : (
            <img
              src={link?.ogImage}
              style={{ width: 300, height: 158, objectFit: "cover" }}
              alt="social media"
            />
          )}
          <Box
            sx={{
              width: "100%",
              pl: 2,
              pt: 1,
              m: 1,
              borderTop: "1px solid #e5e7eb"
            }}
          >
            <Typography level="body1" sx={{ fontWeight: 700 }}>
              {doc_.name}
            </Typography>
            <Typography level="body2">{prettyUrl}</Typography>
          </Box>
        </Card>
      }
      <TextField
        label="Link Name"
        sx={{ mt: 2 }}
        value={linkName}
        onChange={e => {
          setLinkName(e.target.value);
        }}
      />
      <Box
        sx={{
          mt: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            navigator.clipboard.writeText(url);
          }}
        >
          <LinkIcon sx={{ mr: 1 }} />
          Copy Link
        </Button>

        <Button
          disabled={!link}
          variant="outlined"
          onClick={async () => {
            await navigator.clipboard.write([
              new ClipboardItem({
                "text/html": new Blob(
                  [
                    `<div><a href=${url}
        style="margin: 0px; box-sizing: border-box; padding: 0px; text-decoration-line: none; letter-spacing: -0.1px; font-size: 14px;">
        <p style="margin: 0px; box-sizing: border-box; padding: 0px;">${url}</p>
        <img src=${link?.ogImage} style="margin: 0px; box-sizing: border-box; padding: 0px; max-width: 300px;"></a><br></div>`
                  ],
                  {
                    type: "text/html"
                  }
                )
              })
            ]);
          }}
        >
          <LinkIcon sx={{ mr: 1 }} />
          Copy GIF (email)
        </Button>

        <Button sx={{ width: "7em" }} onClick={onClose}>
          Done
        </Button>
      </Box>
    </Box>
  );
};

export function PublishButton({
  doc_,
  updateDoc,
  workspace
}: {
  doc_: WithId<Document>;
  updateDoc: (f: (doc: Document) => void) => void;
  workspace: string;
}) {
  const [linkName, setLinkName] = useState<string>(doc_?.linkName || "");
  const handleClose = () => {
    setAnchorEl(null);
    if (linkName !== doc_.linkName) {
      updateDoc(d => {
        d.linkName = linkName;
      });
    }
  };
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    publish();
  };
  const linkId = doc_.shortId || doc_._id;

  const { data: link } = useDocument<DocumentLink>(
    doc(collection(getFirestore(), "links"), linkId)
  );

  const publish = async () => {
    const workspaceDoc = (
      await getDoc(doc(collection(getFirestore(), "workspaces"), workspace))
    ).data() as Workspace;

    if (!workspaceDoc) {
      // TODO: Log
      return;
    }

    try {
      const db = getFirestore();
      const batch = writeBatch(db);

      const functionsUrl = getFunctionsURL();

      // TODO: We must save the document prior to fetching the preview....!
      const imageBlob = await (
        await fetch(
          `${functionsUrl}/api/og/${workspace}/${
            doc_._id
          }?version=${documentHash(doc_)}`
        )
      ).blob();

      const r = ref(getStorage(), `previews/og/${linkId}`);
      await uploadBytes(r, imageBlob, {
        cacheControl: "public, max-age=3600, s-maxage=3600"
      });

      const ogImageUrl = await getDownloadURL(r);

      const publishedColors = getColors(workspaceDoc);
      const publishedDoc: PublishedDocument = { ...doc_, publishedColors };

      batch.set(
        doc(collection(db, "workspaces", workspace, "published"), doc_._id),
        toDS(publishedDoc),
        { merge: true }
      );

      const link: DocumentLink = {
        doc: doc_._id,
        workspace,
        published: dayjs(),
        ogImage: ogImageUrl
      };
      batch.set(doc(collection(db, "links"), linkId), toDS(link));

      await batch.commit();
    } finally {
      // setBusy(false);
    }
  };

  return (
    <>
      <Button variant="soft" sx={{ mr: 1.5 }} onMouseDown={handleClick}>
        Share
      </Button>
      <Modal open={!!anchorEl} onClose={handleClose}>
        <ModalDialog
          sx={{
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Box>
            <PublishCard
              doc_={doc_}
              link={link}
              linkName={linkName}
              setLinkName={setLinkName}
              onClose={handleClose}
            />
          </Box>
        </ModalDialog>
      </Modal>
    </>
  );
}
