import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Card,
  CircularProgress,
  IconButton,
  Textarea,
  Typography,
  useTheme
} from "@mui/joy";
import dayjs from "dayjs";
import { getAuth } from "firebase/auth";
import {
  collection,
  doc,
  FirestoreError,
  getFirestore,
  orderBy,
  query,
  setDoc,
  writeBatch
} from "firebase/firestore";
import mixpanel from "mixpanel-browser";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "react";
import { toDS, useCollection, WithId } from "./data";
import { EmptyState } from "./EmptyState";
import { ErrorView } from "./ErrorView";
import { ANON_NAME_KEY, track } from "./Infospot";
import { ChatMessage, Document } from "./Model";
import { UserAvatar } from "./UserAvatar";
import { useUserInfo } from "./useUserInfo";

const ChatBox = ({
  me,
  msg,
  deleteMessage
}: {
  me?: string;
  msg: WithId<ChatMessage>;
  deleteMessage: (msg: WithId<ChatMessage>) => void;
}) => {
  const theme = useTheme();
  return (
    <div
      style={{
        display: "grid",
        flexDirection: "column"
      }}
    >
      <div
        style={{
          display: "flex",
          width: "60%",
          maxWidth: "32em",
          margin: "16px",
          marginLeft: msg.from.uid === me ? "auto" : "16px"
        }}
      >
        {msg.from.uid !== me && (
          <UserAvatar uid={msg.from.uid} size="md" sx={{ mr: 1 }} />
        )}
        <div style={{ flexGrow: 1 }}>
          <Card
            sx={{
              position: "relative",
              padding: "8px 8px 8px 12px",
              borderRadius: 6,
              backgroundColor: msg.deleted
                ? "#dddddd"
                : msg.from.uid === me
                ? "primary.400"
                : "neutral.100",
              color: msg.deleted
                ? "gray"
                : msg.from.uid === me
                ? "white"
                : "black"
            }}
          >
            {false && !msg.deleted && msg.from.uid === me && (
              <div
                style={{
                  position: "absolute",
                  right: "0",
                  top: "0"
                }}
              >
                <IconButton
                  variant="plain"
                  size="sm"
                  sx={{ minWidth: 24, minHeight: 24, mr: 0.5, mt: 0.5 }}
                  onClick={() => {
                    deleteMessage(msg);
                  }}
                >
                  <CloseIcon
                    style={{
                      color: "white",
                      width: 16,
                      height: 16
                    }}
                  />
                </IconButton>
              </div>
            )}
            <Typography level="body2">
              {msg.deleted ? <i>Message deleted</i> : ""}
            </Typography>
            <div style={{ fontSize: "14px", whiteSpace: "break-spaces" }}>
              {msg.message}
            </div>
          </Card>
          <Box
            sx={{
              textAlign: "right",
              marginTop: "4px",
              marginRight: "4px",
              fontSize: "12px",
              color: "neutral.400"
            }}
          >
            {msg.from.name},{" "}
            {(msg.from.uid === me && !msg.unread ? "Read " : "") +
              dayjs.duration(msg.date.diff(dayjs())).humanize() +
              " ago"}
          </Box>
        </div>
      </div>
    </div>
  );
};

function UnreadCircle({ count }: { count: number }) {
  if (!count) return null;
  return (
    <Box
      sx={{
        display: "inline-flex",
        borderRadius: "50%",
        fontSize: 14,
        width: 24,
        height: 24,
        color: `danger.solidColor`,
        fontWeight: 700,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "danger.solidBg"
      }}
    >
      {count > 0 ? count : ""}
    </Box>
  );
}

const isAnonymous = () => !getAuth().currentUser?.uid;

const DoChat = ({
  linkId,
  docId,
  workspace,
  readOnly,
  messages,
  loading,
  error,
  help = ""
}: {
  linkId: string;
  docId: string;
  workspace: string;
  readOnly: boolean;
  messages?: WithId<ChatMessage>[];
  loading: boolean;
  error?: FirestoreError;
  help?: string;
}) => {
  const [newMessage, setNewMessage] = useState("");
  const [reRender, setReRender] = useState(0);

  const reRenderRef = useRef(reRender);
  const chatEnd = useRef<HTMLDivElement>(null);
  reRenderRef.current = reRender;

  const firstRenderDone = useRef(false);

  const name =
    getAuth().currentUser?.displayName ??
    localStorage.getItem(ANON_NAME_KEY) ??
    "unknown";
  const anonymous = isAnonymous();
  const uid = getAuth().currentUser?.uid ?? `anon-${name}`;

  useLayoutEffect(() => {
    setTimeout(() => {
      firstRenderDone.current = true;
    }, 2000);
  }, []);

  useEffect(() => {
    const f = () => {
      setReRender(reRenderRef.current + 1);
      setTimeout(f, 10000);
    };
    const to = setTimeout(f, 10000);

    return () => {
      clearTimeout(to);
    };
  }, []);

  const markAsRead = useCallback(() => {
    const db = getFirestore();
    const b = writeBatch(db);
    let count = 0;
    if (!anonymous && messages) {
      for (const m of messages) {
        if (m.unread) {
          b.update(
            doc(
              getFirestore(),
              "workspaces",
              workspace,
              "published",
              docId,
              "chat",
              m._id
            ),
            { unread: false }
          );
          count++;
        }
      }

      if (count > 0) {
        b.commit();
      }
    }
  }, [anonymous, docId, messages, workspace]);

  useEffect(() => {
    (async () => {
      const behavior = firstRenderDone.current ? "smooth" : "auto";
      chatEnd?.current?.scrollIntoView({
        behavior
      });
    })();
  }, [messages]);

  const deleteMessage = (msg: WithId<ChatMessage>) => {
    //db.deleteMessage(requestId, msg._id);
  };

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 300,
          backgroundColor: "white"
        }}
      >
        <ErrorView msg={error?.message} />;
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        //height: 100,
        // width: 100,
        //backgroundColor: "red",
        flex: 1
        //position: "relative"
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1
          //position: "relative"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: 300,
            backgroundColor: "white",
            position: "relative",
            overflowY: "auto"
          }}
        >
          {loading && (
            <Box
              sx={{
                position: "absolute",
                display: "flex",

                justifyContent: "center",
                alignItems: "center",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {!loading && (messages || []).length === 0 && (
            <EmptyState msg="No Messsages" />
          )}
          {messages?.map((m, i) => (
            <ChatBox
              key={m._id}
              me={uid}
              msg={m}
              deleteMessage={deleteMessage}
            />
          ))}
          <div ref={chatEnd} />
        </div>
      </Box>
      {!readOnly && (
        <Box sx={{ p: 1, backgroundColor: "white" }}>
          <Textarea
            onFocus={() => {
              markAsRead();
            }}
            variant="outlined"
            disabled={loading || !!error}
            sx={{}}
            minRows={2}
            value={newMessage}
            onChange={e => setNewMessage(e.target.value)}
            onKeyPress={async e => {
              if (e.key === "Enter") {
                e.preventDefault();
                if (e.altKey) {
                  setNewMessage(newMessage + "\n");
                } else {
                  // TODO
                  const msg: ChatMessage = {
                    from: {
                      uid,
                      name
                    },
                    message: newMessage,
                    date: dayjs(),
                    unread: anonymous,
                    deleted: false,
                    ref: "TODO"
                  };

                  await setDoc(
                    doc(
                      collection(
                        getFirestore(),
                        "workspaces",
                        workspace,
                        "published",
                        docId,
                        "chat"
                      )
                    ),
                    toDS(msg)
                  );
                  //db.sendMessage(requestId, userInfo.uid, newMessage, "TODO");
                  setNewMessage("");

                  track("chat", {
                    id: linkId,
                    document: docId,
                    workspace,
                    uid,
                    name,
                    anonymous
                  });
                  mixpanel.track("chat", {
                    document: docId,
                    workspace,
                    uid,
                    name
                  });
                }
              }
            }}
            placeholder={help}
          />
        </Box>
      )}
    </Box>
  );
};

const fromCustomer = (m: ChatMessage) => m.from.uid.startsWith("anon");

export const ChatView = (props: {
  doc: WithId<Document>;
  workspace: string;
  readOnly: boolean;
  help?: string;
  collapsed?: boolean;
}) => {
  const [collapsed, setCollapsed] = useState(!!props.collapsed);
  const anonymous = isAnonymous();

  const uid = props.doc.createdBy?.uid || "";
  const { userInfo } = useUserInfo(uid);

  const {
    data: messages,
    loading,
    error
  } = useCollection<ChatMessage>(
    query(
      collection(
        getFirestore(),
        "workspaces",
        props.workspace,
        "published",
        props.doc._id,
        "chat"
      ),
      orderBy("date", "asc")
    )
  );

  const unread = (messages || [])
    .filter(m => fromCustomer(m))
    .filter(m => m.unread).length;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        position: "fixed",
        right: 8,
        bottom: 8,
        zIndex: 100
      }}
    >
      <Card
        sx={{ backgroundColor: "primary.400", p: 0, borderRadius: 8 }}
        variant="outlined"
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1.5, p: 1.5 }}>
          <UserAvatar
            uid={props.doc.createdBy?.uid || ""}
            size="lg"
            sx={{ border: "2px solid white" }}
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Typography level="body1" sx={{ color: "white" }}>
                {userInfo?.name}
              </Typography>
              {!anonymous && <UnreadCircle count={unread} />}
            </Box>
            {collapsed && (
              <Typography level="body3" sx={{ color: "white" }}>
                Let me know if you have any questions, I'm here! 💬
              </Typography>
            )}
          </Box>

          <Box sx={{ flex: 1 }} />
          <IconButton
            variant="plain"
            size="sm"
            onClick={() => setCollapsed(!collapsed)}
          >
            {collapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            width: [370, 450]
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            {!collapsed && (
              <DoChat
                messages={messages}
                loading={loading}
                error={error}
                linkId={props.doc.shortId || props.doc._id}
                docId={props.doc._id}
                {...props}
              />
            )}
          </div>
        </Box>
      </Card>
    </Box>
  );
};
