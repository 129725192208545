import {
  DragEndEvent,
  DragOverlay,
  useDndContext,
  useDndMonitor,
  useDraggable
} from "@dnd-kit/core";
import { Box, Option, Select, Typography } from "@mui/joy";
import dayjs from "dayjs";
import { collection, getFirestore, query, where } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { toJS, useCollection } from "./data";
import ElementTemplates from "./element-templates.json";
import EmbedTemplates from "./embed-templates.json";
import { ElementView } from "./ElementView";
import { cloneDocument, DocumentCtx, imageOrVideo, newId } from "./Infospot";
import { Document, DocumentElement, PageElement } from "./Model";
import SectionTemplates from "./section-templates.json";
import { SideBar } from "./SideBar";
import { Asset, useAssetManager } from "./useAssetManager";

const elementTemplates: Document = cloneDocument(
  toJS(ElementTemplates as any as Document)
)[0];

const embedTemplates: Document = cloneDocument(
  toJS(EmbedTemplates as any as Document)
)[0];

const sectionTemplates: Document = cloneDocument(
  toJS(SectionTemplates as any as Document)
)[0];

const WIDTH = 800;
const SCALE = 0.3;

function TemplateDraggable({
  id,
  doc,
  children
}: {
  id: string;
  doc: Document;
  children: any;
}) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id,
    data: { type: "template", doc }
  });
  const style = transform
    ? {
        display: "flex"
      }
    : undefined;

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {children}
    </div>
  );
}

function TemplateDraggableOverlay({ id, axis }: any) {
  const { active } = useDndContext();

  return createPortal(
    <DragOverlay dropAnimation={null}>
      {active?.data?.current?.type === "template" ? (
        <Box>
          <Box
            sx={{
              minWidth: WIDTH,
              transform: `scale(${SCALE})`,
              opacity: 0.8,
              transformOrigin: "left top"
            }}
          >
            {active.id && <ElementView eid={active.id as string} />}
          </Box>
        </Box>
      ) : null}
    </DragOverlay>,
    document.body
  );
}

const Templates = ({
  workspace,
  templates
}: {
  workspace: string;
  templates: Document;
}) => {
  const templateElements = templates.elements[templates.root]?.elements || [];
  const { colors } = useContext(DocumentCtx);

  return (
    <>
      <DocumentCtx.Provider
        value={{
          public: false,
          viewMode: "view",
          presentation: false,
          doc: templates,
          workspace,
          colors,
          updateDoc: () => {},
          fileDropActive: false,
          selectionElement: null,
          setSelectionElement: () => {},
          renderContext: "other",
          usePreviewAssets: true
        }}
      >
        <TemplateDraggableOverlay />
        {templateElements.map(x => (
          <TemplateDraggable key={x} doc={templates} id={x}>
            <Box
              key={x}
              sx={{
                position: "relative"
                //width: WIDTH
                //zoom: 0.5
              }}
            >
              <Box
                key={x}
                sx={{
                  position: "relative",
                  width: WIDTH,
                  zoom: SCALE
                }}
              >
                <ElementView eid={x} />
              </Box>
              <Typography level="body2" sx={{ textAlign: "center", mt: 1 }}>
                {templates.elements[x]?.name}
              </Typography>
            </Box>
          </TemplateDraggable>
        ))}
      </DocumentCtx.Provider>
    </>
  );
};

const makeImage = (asset: Asset): DocumentElement => {
  //const url = await getDownloadURL(asset.ref);
  if (asset.meta.contentType?.startsWith("image")) {
    return { type: "image", id: newId(), src: asset.url };
  } else {
    return {
      type: "video",
      id: newId(),
      src: asset.url
    };
  }
};
const makeAssetDocument = (assets: Asset[]) => {
  const images = assets.map(makeImage);
  const elements = Object.fromEntries(images.map(x => [x.id, x]));
  const page: PageElement = {
    id: "root",
    type: "page",
    elements: images.map(x => x.id)
  };

  const d: Document = {
    root: "root",
    type: "template",
    name: "Assets",
    created: dayjs(),
    modified: dayjs(),
    elements: { root: page, ...elements },
    selection: null
  };
  return d;
};

export function CreateBar({ workspace }: { workspace: string }) {
  //const templates = elementTemplates;
  //const templateElements = templates.elements[templates.root]?.elements || [];
  const { updateDoc, candidate } = useContext(DocumentCtx);

  const [page, setPage] = useState<"blocks" | "sections" | "assets">("blocks");

  const { assets } = useAssetManager(workspace);

  const { data: sectionsTemplates } = useCollection<Document>(
    query(
      collection(getFirestore(), "workspaces", workspace ?? "", "docs"),
      where("type", "==", "template")
      //orderBy("modified", "desc")
    )
  );

  const filteredSectionsTemplates = (sectionsTemplates || []).filter(
    d => !d.deleted
  );

  useEffect(() => {
    if (!assets) {
      return;
    }
    setAssetTemplates(makeAssetDocument(assets));
  }, [assets]);

  const [assetTemplates, setAssetTemplates] = useState<Document | undefined>();

  function handleDragEnd(e: DragEndEvent) {
    const { active } = e;

    if (active.data.current && candidate) {
      const [clonedTemplateDoc, idMap] = cloneDocument(active.data.current.doc);

      updateDoc(doc => {
        for (const x of Object.keys(clonedTemplateDoc.elements)) {
          doc.elements[x] = clonedTemplateDoc.elements[x];
        }
        const e = doc.elements[candidate.parent];
        if (e.type === "section" || e.type === "page") {
          const es = e.elements ?? [];
          const i = candidate.element
            ? es.indexOf(candidate.element) +
              (candidate.position === "after" ? 1 : 0)
            : 0;
          es.splice(i, 0, idMap[active.id]);

          //es.push(idMap[active.id]);
          e.elements = es;
        } else {
          //const es = (e.elements ?? []).map(x => x === candidate.p);
          let newEl = { ...clonedTemplateDoc.elements[idMap[active.id]] };
          newEl.id = candidate.parent;
          if (
            doc.elements[candidate.parent].style &&
            imageOrVideo(newEl) &&
            imageOrVideo(doc.elements[candidate.parent])
          ) {
            newEl.style = doc.elements[candidate.parent].style;
          }

          doc.elements[candidate.parent] = newEl;

          console.log("TODO", candidate.parent, newEl);
        }
      });
    }
  }

  useDndMonitor({
    onDragEnd(e) {
      handleDragEnd(e);
    }
  });

  const pages: { [id: string]: Document | undefined } = {
    blocks: elementTemplates,
    sections: sectionTemplates,
    assets: assetTemplates,
    embed: embedTemplates,
    ...Object.fromEntries(
      (filteredSectionsTemplates || []).map(s => [
        s._id,
        { ...s, selection: null }
      ])
    )
  };

  const RenderPage = ({ page }: { page: string }) => {
    const t = pages[page];
    if (t) {
      return <Templates workspace={workspace} templates={t} />;
    }
    return <Box sx={{ width: WIDTH * SCALE }}>Loading...</Box>;
  };

  return (
    <SideBar>
      <Select
        size="sm"
        sx={{ width: "100%" }}
        value={page}
        onChange={(_, v) => setPage(v as "blocks" | "sections")}
      >
        <Option value="blocks">Blocks</Option>
        <Option value="sections">Sections</Option>
        <Option value="assets">Assets</Option>
        <Option value="embed">Embed</Option>

        {filteredSectionsTemplates?.map(s => (
          <Option key={s._id} value={s._id}>
            {s.name}
          </Option>
        ))}
      </Select>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          transformOrigin: "left top",
          overflowY: "scroll",
          overflowX: "hidden",
          userSelect: "none",
          p: 1,
          gap: 4
        }}
      >
        <RenderPage page={page} />
      </Box>
    </SideBar>
  );
}
