import { Box } from "@mui/joy";
import { useMemo } from "react";
import { WithId } from "./data";
import { docFonts } from "./Infospot";
import { Document } from "./Model";
import { useGoogleFonts } from "./useGoogleFonts";

export function DocumentContainer({
  zoom = 1,
  doc,
  children
}: {
  zoom?: number;
  doc: WithId<Document>;
  children: any;
}) {
  const fonts = useMemo(() => {
    return docFonts(doc);
  }, [doc]);

  useGoogleFonts(fonts);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        //maxWidth: `min(100vw, ${1000 * zoom}px)`,
        // could have hidden here to clip loooong headings but uncertain if it's the correct place
        // the strange thing is that if overflowX is set to hidden we get a vertical scroll bar on public pages..
        //overflow: viewMode === "view" ? "hidden" : "unset",
        flex: 1,
        minWidth: 300,
        width: "100%"
      }}
    >
      <Box
        sx={{
          transform: `scale(${zoom})`,
          transformOrigin: "top left",
          width: `${100 / zoom}%`
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
