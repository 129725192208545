import {
  Active,
  ClientRect,
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DroppableContainer,
  MouseSensor,
  UniqueIdentifier,
  useDndContext,
  useDraggable,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { Coordinates } from "@dnd-kit/utilities";
import AddIcon from "@mui/icons-material/Add";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import Apps from "@mui/icons-material/Apps";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArticleIcon from "@mui/icons-material/Article";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import LogoutIcon from "@mui/icons-material/Logout";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PhotoIcon from "@mui/icons-material/Photo";
import TuneIcon from "@mui/icons-material/Tune";
import * as Sentry from "@sentry/react";
import {
  Alert,
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  ModalDialog,
  Option,
  Radio,
  RadioGroup,
  Select,
  styled,
  TextField,
  Typography
} from "@mui/joy";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import { radioClasses } from "@mui/joy/Radio";
import Tab, { tabClasses } from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import Tabs from "@mui/joy/Tabs";
import Tooltip from "@mui/joy/Tooltip";
import { SxProps } from "@mui/system";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import deepEqual from "deep-equal";
import {
  getAuth,
  GoogleAuthProvider,
  IdTokenResult,
  OAuthProvider,
  onAuthStateChanged,
  signInWithCustomToken,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  User
} from "firebase/auth";
import {
  collection,
  deleteDoc,
  doc,
  getFirestore,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where
} from "firebase/firestore";
import {
  getBytes,
  getMetadata,
  getStorage,
  ref,
  uploadBytes
} from "firebase/storage";
import produce from "immer";
import mixpanel from "mixpanel-browser";
import murmurhash from "murmurhash";
import {
  Dispatch,
  DragEvent,
  KeyboardEvent,
  lazy,
  MouseEvent,
  SetStateAction,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { createPortal } from "react-dom";
import {
  BrowserRouter,
  Link,
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
  useResolvedPath
} from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { Admin } from "./Admin";
import "./App.css";
import { AssetManager } from "./AssetManager";
import { ChatView } from "./ChatView";
import { getColors } from "./Color";
import { DefaultPalette } from "./Configuration";
import { CreateBar } from "./CreateBar";
import { CurrentUserAvatar } from "./CurrentUserAvatar";
import { toDS, useCollection, useDocument, WithId } from "./data";
import { DocPreview } from "./DocPreview";
import { DocumentContainer } from "./DocumentContainer";
import { ElementView } from "./ElementView";
import { EmptyState2 } from "./EmptyState2";
import { Error404 } from "./Error404";
import { FullPageCard } from "./FullPageCard";
import { FullscreenBusy } from "./FullscreenBusy";
import GOOGLE_LOGO from "./images/google.svg";
import MS_LOGO from "./images/ms.svg";
import {
  AppCtx,
  Candidate,
  DocumentContextType,
  DocumentCtx,
  documentHash,
  elementCreator,
  FEATURES,
  findParent,
  garbageCollect,
  getDocumentAssetURLs,
  getFunctionsURL,
  imageOrVideo,
  newId,
  randomShortId,
  upgradeDocDynamically,
  useTrack
} from "./Infospot";
import {
  ChatSummary,
  CompanyInfo,
  Document,
  DocumentElement,
  DOC_VERSION,
  ImageElement,
  PublishedDocument,
  VideoElement,
  Workspace
} from "./Model";
import { ProfileDialog } from "./ProfileDialog";
import { StyleEditor } from "./StyleEditor";
import { PublicDocView } from "./PublicDocView";
import { PublishButton } from "./PublishButton";
import { SelectWorkspaceDialog } from "./SelectWorkspaceDialog";
import { SideBar } from "./SideBar";
import { makeAssetURL, parseAssetURL } from "./storage";
import { TopBar } from "./TopBar";
import { useAssetManager } from "./useAssetManager";
import { useEventListener } from "./useEventListener";
import { WorkspaceSettings } from "./WorkspaceSettings";
import { DeveloperMenu } from "./DeveloperMenu";
import { DocumentStyles } from "./DocumentStyles";
const Analytics = lazy(() => import("./Analytics"));

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

const findFirstElementId = (e: Element): string | undefined => {
  const id = e.getAttribute("data-eid");
  if (id) {
    return id;
  } else {
    if (e.parentElement) {
      return findFirstElementId(e.parentElement);
    } else {
      return undefined;
    }
  }
};

// TODO: remove?
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const findFirstContainer = (doc: Document, e: Element): string | undefined => {
  const id = e.getAttribute("data-eid");
  const e2 = doc.elements[id ?? ""];
  if ((id && e2?.type === "page") || e2?.type === "section") {
    return id || undefined;
  } else {
    if (e.parentElement) {
      return findFirstContainer(doc, e.parentElement);
    } else {
      return undefined;
    }
  }
};

// TODO: Not in this file..
export const allHoverElements = (): string[] => {
  const nodes = document.querySelectorAll(":hover");

  // TODO: Why as string[] below!?
  return [...nodes]
    .map(n => n.getAttribute("data-eid"))
    .filter(n => n !== null) as string[];
};

const TEMPLATE_DOC: Document = {
  root: "root",
  type: "document",
  name: "Untitled",
  created: dayjs(),
  modified: dayjs(),
  selection: null,
  elements: {
    root: {
      id: "root",
      type: "page",
      elements: [],
      style: {
        padding: "0"
      }
    }
  }
};

function EditPreviewButton({
  mode,
  onMode
}: {
  mode: "edit" | "preview";
  onMode: (mode: "edit" | "preview") => void;
}) {
  return (
    <RadioGroup
      row
      aria-label="mode"
      name="mode"
      variant="outlined"
      value={mode}
      onChange={event => onMode(event.target.value as any)}
    >
      {["edit", "preview"].map(item => (
        <Box
          key={item}
          sx={theme => ({
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "lg",
            fontSize: 14,

            width: 100,
            height: 40,
            "&:not([data-first-child])": {
              borderLeft: "1px solid",
              borderColor: "divider"
            },
            [`&[data-first-child] .${radioClasses.action}`]: {
              borderTopLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`,
              borderBottomLeftRadius: `calc(${theme.vars.radius.sm} - 1px)`
            },
            [`&[data-last-child] .${radioClasses.action}`]: {
              borderTopRightRadius: `calc(${theme.vars.radius.sm} - 1px)`,
              borderBottomRightRadius: `calc(${theme.vars.radius.sm} - 1px)`
            }
          })}
        >
          <Radio
            value={item}
            disableIcon
            overlay
            label={
              {
                edit: "Edit",
                preview: "Preview"
              }[item]
            }
            variant={mode === item ? "soft" : "plain"}
            slotProps={{
              input: { "aria-label": item },
              action: {
                sx: {
                  borderRadius: 0,
                  transition: "none"
                }
              },
              label: { sx: { lineHeight: 0 } }
            }}
          />
        </Box>
      ))}
    </RadioGroup>
  );
}

function ElementDraggableOverlay({ zoom }: { zoom: number }) {
  const { active } = useDndContext();

  const { doc } = useContext(DocumentCtx);
  const { attributes, listeners } = useDraggable({
    id: "draggable",
    data: { type: "template" }
  });
  if (!active || !doc.elements[active?.id]) {
    return null;
  }

  return createPortal(
    <DragOverlay>
      {active ? (
        <Box
          {...listeners}
          {...attributes}
          sx={{
            transform: `scale(${zoom})`,
            transformOrigin: "top left",
            width: `${100 / zoom}%`
          }}
        >
          <ElementView eid={active.id as string} />
        </Box>
      ) : null}
    </DragOverlay>,
    document.body
  );
}

function distanceBetween(p1: Coordinates, p2: Coordinates) {
  return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
}

function isPointWithinRect(point: Coordinates, rect: ClientRect): boolean {
  const { top, left, bottom, right } = rect;

  return (
    top <= point.y && point.y <= bottom && left <= point.x && point.x <= right
  );
}

export const myPointerWithin = (
  props: {
    active: Active;
    collisionRect: ClientRect;
    droppableRects: Map<UniqueIdentifier, ClientRect>;
    droppableContainers: DroppableContainer[];
    pointerCoordinates: Coordinates | null;
  },
  doc_: Document
) => {
  const { active, droppableContainers, droppableRects, pointerCoordinates } =
    props;

  const parents: { [id: string]: DocumentElement } = {};

  for (const e of Object.values(doc_.elements)) {
    for (const c of e.elements ?? []) {
      parents[c] = e;
    }
  }

  if (active.data?.current?.type !== "template") {
    const rs = closestCenter(props);
    const pe = parents[active.id];
    if (pe) {
      const cs = pe?.elements || [];
      const bla = rs.filter(
        x => x.id === pe.id || cs.indexOf(x.id as string) !== -1
      );
      return bla;
    }
  }

  /*
  const r = active.rect.current.translated;

  if (!r) {
    return [];
  }*/

  /*const pointerCoordinates = {
    x: (r.left + r.right) * 0.5,
    y: r.bottom
    //y: (r.top + r.bottom) * 0.5
  };*/

  if (!pointerCoordinates) {
    return [];
  }

  const rs: {
    id: string;
    distance: number;
    pointerCoordinates: Coordinates;
  }[] = [];
  for (const dc of droppableContainers) {
    const e = doc_.elements[dc.id];
    const pe = dc.id === doc_.root ? e : parents[dc.id];
    const r = droppableRects.get(dc.id);
    const pr = pe && droppableRects.get(pe.id);

    if (r && pr && e && pe && isPointWithinRect(pointerCoordinates, pr)) {
      if (pe?.type === "section" || pe?.type === "page") {
        const dir =
          pe.type === "section" ? pe.style?.direction ?? "column" : "column";
        //let d = Number.MAX_VALUE;
        let d1 = distanceBetween(
          dir === "column"
            ? { x: r.left + r.width * 0.5, y: r.bottom }
            : { x: r.right, y: r.top + 0.5 * r.height },
          pointerCoordinates
        );
        let d2 = distanceBetween(
          dir === "column"
            ? { x: r.left + r.width * 0.5, y: r.top }
            : { x: r.left, y: r.top + 0.5 * r.height },
          pointerCoordinates
        );

        const d = Math.min(d1, d2);
        /*const d = distanceBetween(
          { x: r.left + r.width * 0.5, y: r.top },
          pointerCoordinates
        );*/

        // TODO: See pointerCoordinates in onDragMove
        rs.push({ id: dc.id as string, distance: d, pointerCoordinates });
      }
    }
  }

  rs.sort((a, b) => a.distance - b.distance);
  //console.log(rs);
  //  console.log("rs", rs[0], pointerCoordinates);

  return rs;
};

function DoEditDoc({
  doc_,
  workspaceDoc,
  updateDoc,
  zoom,
  editMode
}: {
  doc_: WithId<Document>;
  workspaceDoc: WithId<Workspace>;
  updateDoc: (f: (flow: Document) => void) => void;
  zoom: number;
  editMode: "designMode" | "authoringMode";
}) {
  const [busy, setBusy] = useState(false);
  const [selectionElement, setSelectionElement] = useState<HTMLElement | null>(
    null
  );

  const [candidate, setCandidate] = useState<Candidate>();

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5
        // delay: 150,
        // tolerance: 5
      }
    })
    // TODO: We can't use KeyboardSensor. When editing text the section view will get focus...
    //useSensor(KeyboardSensor, {
    //  coordinateGetter: sortableKeyboardCoordinates
    //})
  );

  const over_ = useRef<string>();
  const [fileDropActive, setFileDropActive] = useState(false);

  const { upload } = useAssetManager(workspaceDoc._id);

  const onDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setFileDropActive(false);
    if (e.dataTransfer.items) {
      try {
        setBusy(true);
        const f = e.dataTransfer.items[0].getAsFile();
        const type = f?.type;
        const over = over_.current;
        if (
          over &&
          f &&
          (type?.startsWith("video") || type?.startsWith("image"))
        ) {
          /*
          const ext = f.name.split(".").pop();
          const fileId = nanoid() + "." + ext;
          const image = await f.arrayBuffer();
          const storage = getStorage();
          const storageRef = ref(storage, `/${workspace}/assets/${fileId}`);
          //const task = uploadBytesResumable(storageRef, image, {
          //  contentType: f.type
          //});
          const sha1_ = await sha1(image);
          const r = await uploadBytes(storageRef, image, {
            contentType: f.type,
            customMetadata: {
              sha1: sha1_
            }
          });*/
          const asset = await upload(f);
          //          const url = await getDownloadURL(storageRef);
          let el: DocumentElement;
          if (type.startsWith("image")) {
            el = elementCreator("image") as ImageElement;
            el.src = asset.url;
          } else {
            el = elementCreator("video") as VideoElement;
            el.src = asset.url;
          }

          const dp = produce(doc_, draft => {
            draft.elements[el.id] = el;
            const s = draft.elements[over];
            if (s.type === "section" || s.type === "page") {
              const es = s.elements ?? [];
              es.push(el.id);
              s.elements = es;
            } else {
              if (
                doc_.elements[over].style &&
                imageOrVideo(el) &&
                imageOrVideo(draft.elements[over])
              ) {
                el.style = doc_.elements[over].style;
              }
              draft.elements[over] = el;
              el.id = over;
            }
          });
          updateDoc(_ => dp);
          over_.current = undefined;

          //const el = elementCreator(type.startsWith("image") ? "Image" : "Video");
        }
      } finally {
        setBusy(false);
      }
    }
    return false;
  };

  const collDetect = useMemo(
    () => (props: any) => myPointerWithin(props, doc_),
    [doc_]
  );

  const colors = useMemo(() => getColors(workspaceDoc), [workspaceDoc]);

  const onDragEnd = (e: DragEndEvent) => {
    setCandidate(undefined);
  };

  const docCtx: DocumentContextType = useMemo(() => {
    return {
      public: false,
      colors,
      viewMode: "edit",
      presentation: false,
      doc: doc_,
      workspace: workspaceDoc._id,
      updateDoc,
      fileDropActive,
      selectionElement,
      setSelectionElement,
      renderContext: "main",
      candidate
    };
  }, [
    candidate,
    colors,
    doc_,
    fileDropActive,
    selectionElement,
    updateDoc,
    workspaceDoc._id
  ]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collDetect}
      onDragCancel={() => setCandidate(undefined)}
      onDragEnd={onDragEnd}
      onDragMove={e => {
        const { active, over, /*delta,*/ collisions } = e;
        //console.log("active", active.rect.current?.translated);
        const r = active.rect.current?.translated;
        let c: Candidate | undefined = undefined;
        let position: "after" | "before" = "after";
        //console.log(over, active.rect.current.translated);
        if (
          r &&
          collisions &&
          collisions.length > 0 &&
          active?.data?.current?.type === "template" &&
          over?.id
        ) {
          const el = doc_.elements[over.id];

          // TODO: This is strange that we store the coordinates here
          // but couldn't find... should we perhaps calculate everything in
          // the collision code and pass in the data property?
          const x = (collisions[0] as any).pointerCoordinates.x;
          const y = (collisions[0] as any).pointerCoordinates.y;
          //const x = (r.left + r.right) * 0.5;
          //const y = (r.top + r.bottom) * 0.5;
          if (el /*|| el?.type === "section" || el?.type === "page"*/) {
            const p = findParent(doc_, el);
            if (p) {
              if (p.type === "section" || p.type === "page") {
                const dir =
                  p.type === "section"
                    ? p.style?.direction ?? "column"
                    : "column";
                /*const y =
                  active.rect?.current?.translated?.bottom -
                  active.rect?.current?.translated?.height * 0.5;*/
                //const x = delta.x;
                //const y = delta.y;

                // TODO: Hmmm should be something more flexible...
                const empty =
                  ((el.type === "section" || el.type === "page") &&
                    !el.elements) ||
                  el.elements?.length === 0 ||
                  el.type === "image" ||
                  el.type === "placeholderImage" ||
                  el.type === "video";

                if (dir === "column") {
                  //console.log(y, over.rect.top + over.rect.height * 0.5);
                  const mid = over.rect.top + over.rect.height * 0.5;
                  if (empty && Math.abs(y - mid) < over.rect.height * 0.2) {
                    c = { parent: el.id };
                  } else {
                    position = y > mid ? "after" : "before";
                    c = { parent: p.id, element: el.id, position };
                  }
                } else {
                  const mid = over.rect.left + over.rect.width * 0.5;

                  if (empty && Math.abs(x - mid) < over.rect.width * 0.2) {
                    c = { parent: el.id };
                  } else {
                    position = x > mid ? "after" : "before";
                    c = { parent: p.id, element: el.id, position };
                  }
                }
              }
              if (false && (!el.elements || el.elements?.length === 0)) {
                c = { parent: el.id };
                console.log("YES!", c);
              } else {
                //                c = { parent: p.id, element: el.id, position };
              }
            } else {
              c = { parent: el.id };
              console.log("YES2!", over, c);
            }
          }
        }

        if (c) {
          setCandidate(old => {
            if (deepEqual(old, c)) {
              return old;
            } else {
              return c;
            }
          });
        }
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column"
        }}
        onMouseDown={e => {
          if (e.currentTarget === e.target) {
            updateDoc(d => {
              d.selection = null;
            });
            setSelectionElement(null);
          }
        }}
        onMouseMove={e => {
          const nodes = document.querySelectorAll(":hover");
          if (nodes.length > 0) {
            const e = nodes[nodes.length - 1];
            const i = findFirstElementId(e);
            over_.current = i;
          }
        }}
        onDragOver={(e: DragEvent<HTMLElement>) => {
          e.preventDefault();
          setFileDropActive(true);
          // TODO: as any...?
          //setOver(findFirstContainer(doc_, e.target as any));
          over_.current = findFirstElementId(e.target as any);
          return false;
        }}
        onDragEnter={e => {
          setFileDropActive(true);
        }}
        onDragLeave={e => {
          setFileDropActive(false);
          over_.current = undefined;
        }}
        onDrop={onDrop}
      >
        <DocumentCtx.Provider value={docCtx}>
          {true && (
            <Box
              sx={{
                display: "flex",
                flex: 1,
                minHeight: 0,
                backgroundColor: "white",
                width: "100vw"
              }}
            >
              <Box
                sx={{
                  //zoom: 0.4,
                  display: "flex",
                  flexDirection: "column",
                  //flex: 1,
                  //maxWidth: 400,
                  //height: "200%",
                  transformOrigin: "left top",
                  //transform: "scale(0.5)",
                  overflowY: "auto"
                }}
              >
                <CreateBar workspace={workspaceDoc._id} />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flex: 1
                  //overflow: "auto"
                  //m: 4
                }}
              >
                <ElementDraggableOverlay zoom={zoom} />

                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    overflow: "auto",
                    flexDirection: "column",
                    alignItems: "center"
                  }}
                  onClick={() => {
                    updateDoc(d => {
                      d.selection = null;
                    });
                  }}
                >
                  <DocumentContainer zoom={zoom} doc={doc_}>
                    <ElementView eid={doc_.root} />
                    {/* Space for menu here as well. Similar to mt above but mb above didn't work.. */}
                    {true && <Box sx={{ height: 64 }} />}
                  </DocumentContainer>
                </Box>
                {editMode === "designMode" && (
                  <StyleEditor workspace={workspaceDoc} />
                )}
              </Box>
            </Box>
          )}
        </DocumentCtx.Provider>

        {busy && <FullscreenBusy />}
      </Box>
    </DndContext>
  );
}

function EditDoc() {
  const { id, workspace } = useParams();
  useTrack("edit", { document: id, workspace });

  const navigate = useNavigate();

  const appCtx = useContext(AppCtx);
  const [undoStack, setUndoStack] = useState<WithId<Document>[]>([]);
  const [zoom, setZoom] = useState(1);
  const [editMode, setEditMode] = useState<"designMode" | "authoringMode">(
    "designMode"
  );

  const {
    data: doc_,
    setData: setDoc_,
    loading
  } = useDocument<Document>(
    doc(collection(getFirestore(), "workspaces", workspace ?? "", "docs"), id),
    {
      upgrade: upgradeDocDynamically
    }
  );

  const { data: workspaceDoc, loading: workspaceDocLoading } =
    useDocument<Workspace>(
      doc(collection(getFirestore(), "workspaces"), workspace)
    );

  const [mode, setMode] = useState<"edit" | "preview">("edit");
  const onKeyDown = async (e: KeyboardEvent) => {
    if (document.activeElement?.tagName !== "BODY") {
      //return;
    }

    if (e.metaKey && e.key === "z") {
      undo();
    }
  };
  useEventListener("keydown", onKeyDown);

  const save = useDebouncedCallback(async () => {
    if (doc_ && id && workspace) {
      const docPrim = garbageCollect(
        produce(doc_, draft => {
          draft.modified = dayjs();
        })
      );

      try {
        await setDoc(
          doc(collection(getFirestore(), "workspaces", workspace, "docs"), id),
          toDS(docPrim)
        );
      } catch (e) {
        console.error(e);
        // TODO: any here. bug in the sentry SDK?
        Sentry.captureException(e, (scope: any) => {
          // NOTE: JSON.stringify removes undefined values and we want to capture undefined
          // as they are forbidden in firebase
          const replacer = (key: any, value: any) =>
            typeof value === "undefined" ? "UNDEFINED ERROR" : value;

          scope.addAttachment({
            filename: "document.json",
            data: JSON.stringify(docPrim, replacer)
          });
          return scope;
        });
      }
    }
  }, 10000);

  useEffect(() => {
    return () => save.flush();
  }, [save]);

  const undo = () => {
    if (undoStack.length > 0) {
      setDoc_(undoStack[undoStack.length - 1]);
      const usp = produce(undoStack, draft => {
        draft.pop();
      });
      setUndoStack(usp);
    }
  };

  const equalDocs = <T extends Document>(t1: T, t2: T) => {
    const { selection: s1, ...rt1 } = t1;
    const { selection: s2, ...rt2 } = t2;

    return deepEqual(rt1, rt2);
  };

  const updateDoc = useCallback(
    (f: (s: WithId<Document>) => void): void => {
      // TODO: Investigate why we actual need garbageCollect
      // Witout garbageCollect the follow didn't work
      // 1. Clean doc
      // 2. Drag in stuff
      // 3. No drop markers

      setDoc_(d => {
        if (!d) {
          return;
        }
        let dp = produce(d, f);

        if (!equalDocs(d, dp)) {
          console.log("SAVE");
          save();
          setUndoStack(us => [...us, d]);
        }

        return garbageCollect(dp);
      });
    },
    [save, setDoc_]
  );

  if (loading || workspaceDocLoading) {
    return <FullscreenBusy props={{ backgroundColor: "unset" }} />;
  }

  if (!doc_ || !workspace || !id || !workspaceDoc) {
    return <Error404 />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "100vw",
        flexDirection: "column",
        height: "100vh"
      }}
    >
      <TopBar>
        <IconButton
          variant="plain"
          sx={{ "&:focus": { outline: "none" } }}
          onClick={() => navigate(`/dashboard/${workspace}`)}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography sx={{ pl: 1 }}>{doc_.name}</Typography>
        {doc_.type === "template" && (
          <Chip variant="soft" size="sm" sx={{ ml: 1 }}>
            Template
          </Chip>
        )}
        <Box sx={{ flex: 1 }} />
        <EditPreviewButton mode={mode} onMode={mode => setMode(mode)} />

        <Box sx={{ flex: 1 }} />
        <Button
          variant="plain"
          color="success"
          sx={{ mr: 1 }}
          onClick={() => {
            if (editMode === "designMode") {
              setEditMode("authoringMode");
            } else {
              setEditMode("designMode");
            }
          }}
        >
          {editMode === "designMode" ? "Designing" : "Authoring"}
        </Button>

        <IconButton
          sx={{ mr: 2 }}
          variant="plain"
          onClick={() => navigate("editInfo")}
        >
          <EditIcon />
        </IconButton>
        {mode === "edit" && (
          <>
            <Select
              value={zoom}
              size="md"
              sx={{ mr: 2, minWidth: "6rem" }}
              onChange={(_, v) => v && setZoom(v)}
            >
              <Option value={0.5}>50%</Option>
              <Option value={0.75}>75%</Option>
              <Option value={1}>100%</Option>
            </Select>
            <PublishButton
              doc_={doc_}
              updateDoc={updateDoc}
              workspace={workspace}
            />
          </>
        )}

        {appCtx?.idTokenResult?.claims.developer && (
          <DeveloperMenu
            workspace={workspace}
            document={doc_}
            updateDoc={updateDoc}
          />
        )}
      </TopBar>

      <Box sx={{ display: "flex", flex: 1, minHeight: 0 }}>
        <ChatView
          doc={doc_}
          workspace={workspaceDoc._id}
          readOnly={false}
          collapsed={true}
        />

        {mode === "edit" ? (
          <DoEditDoc
            doc_={doc_}
            updateDoc={updateDoc}
            workspaceDoc={workspaceDoc}
            zoom={zoom}
            editMode={editMode}
          />
        ) : (
          <DocPreview doc_={doc_} workspaceDoc={workspaceDoc} />
        )}
      </Box>

      <Routes>
        <Route
          path="editInfo"
          element={
            <EditCompanyInfoDialog
              document={doc_}
              workspaceDoc={workspaceDoc}
            />
          }
        />
      </Routes>
    </Box>
  );
}

function PreviewDoc() {
  const { id, workspace } = useParams();

  const { data: doc_, loading } = useDocument<Document>(
    doc(collection(getFirestore(), "workspaces", workspace ?? "", "docs"), id)
  );

  const { data: workspaceDoc, loading: workspaceDocLoading } =
    useDocument<Workspace>(
      doc(collection(getFirestore(), "workspaces"), workspace)
    );

  if (loading || workspaceDocLoading) {
    return <FullscreenBusy props={{ backgroundColor: "unset" }} />;
  }

  if (!doc_ || !workspace || !id || !workspaceDoc) {
    return <Error404 />;
  }

  return (
    <Box
      id="docview"
      sx={{
        display: "flex",
        width: "100vw",
        flexDirection: "column",
        height: "100vh"
      }}
    >
      <Box sx={{ display: "flex", flex: 1, minHeight: 0 }}>
        <DocPreview
          doc_={doc_}
          workspaceDoc={workspaceDoc}
          disableSizeSelect={true}
        />
      </Box>
    </Box>
  );
}

const SignIn = () => {
  const [agree, setAgree] = useState(false);
  const [error, setError] = useState("");
  const signInGoogle = async () => {
    const provider = new GoogleAuthProvider();

    const auth = getAuth();
    await signInWithRedirect(auth, provider);
  };

  const signInMs = async () => {
    const provider = new OAuthProvider("microsoft.com");
    const auth = getAuth();

    try {
      await signInWithPopup(auth, provider);
    } catch (error: any) {
      if (error.code === "auth/account-exists-with-different-credential") {
        setError("Your account is already associated with a Google Account");
      } else {
        setError("Unknown error");
      }
      console.error(error);
    }
  };
  return (
    <FullPageCard>
      <Box
        sx={{
          p: 4,
          pt: 6,
          pb: 6,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Typography level="h2" gutterBottom>
          Infospot
        </Typography>
        <Typography level="h4" gutterBottom>
          Let's get started
        </Typography>

        <Typography level="body2">Sign in or create your account</Typography>

        <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
          <Checkbox
            variant="soft"
            size="sm"
            checked={agree}
            onChange={e => setAgree(e.target.checked)}
          />
          <Typography level="body2" sx={{ ml: "0.5em" }}>
            Agreed to{" "}
            <a
              href="https://infospot.co/terms"
              target="_blank"
              rel="noreferrer"
            >
              terms
            </a>{" "}
            of use and{" "}
            <a
              href="https://infospot.co/privacy"
              target="_blank"
              rel="noreferrer"
            >
              privacy
            </a>{" "}
            statements
          </Typography>
        </Box>

        <Alert
          color="danger"
          variant="plain"
          size="sm"
          sx={{
            mb: 1,
            opacity: error ? 1 : 0,
            maxWidth: "20em",
            textAlign: "center"
          }}
        >
          {error}
        </Alert>

        <Box sx={{ display: "flex", flexDirection: "column", mt: 2, gap: 2 }}>
          <Button
            disabled={!agree}
            sx={{ minWidth: "24em", p: 1 }}
            variant="outlined"
            onClick={signInGoogle}
          >
            <img
              src={GOOGLE_LOGO}
              style={{ width: 28, marginRight: 12 }}
              alt="google logo"
            />
            Continue with Google
          </Button>
          <Button
            disabled={!agree}
            sx={{ minWidth: "24em", p: 1 }}
            variant="outlined"
            onClick={signInMs}
          >
            <img
              src={MS_LOGO}
              style={{ width: 28, marginRight: 12 }}
              alt="microsoft logo"
            />
            Continue with Microsoft 365
          </Button>
        </Box>
        <Box sx={{ height: 0 }} />
      </Box>
    </FullPageCard>
  );
};

export const editDocumentUrl = (workspace: string, document: string) =>
  `/edit/${workspace}/${document}`;

const DocumentMenu = ({
  workspace,
  document
}: {
  workspace: string;
  document: WithId<Document>;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectWorkspaceOpen, setSelectWorkspaceOpen] = useState(false);
  const [fullScreenBusy, setFullScreenBusy] = useState(false);
  const navigate = useNavigate();
  const appCtx = useContext(AppCtx);

  const renameDocument = async () => {
    const name = prompt("Rename document to", document.name ?? "Untitled");

    if (name) {
      const docRef = doc(
        collection(getFirestore(), "workspaces", workspace, "docs"),
        document._id
      );
      updateDoc(docRef, { name });
    }
  };

  const deleteDocument = async () => {
    const r = window.confirm("Are you sure?");

    if (r) {
      const docRef = doc(
        collection(getFirestore(), "workspaces", workspace, "docs"),
        document._id
      );
      await updateDoc(docRef, { deleted: true });

      await deleteDoc(
        doc(
          collection(getFirestore(), "workspaces", workspace, "published"),
          document._id
        )
      );
    }
  };

  const newTemplate = async (template: WithId<Document>) => {
    const id = newId();
    const docRef = doc(
      collection(getFirestore(), "workspaces", workspace, "docs"),
      id
    );
    await setDoc(
      docRef,
      toDS({ ...template, name: `${template.name} template`, type: "template" })
    );
  };

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const copyToWorkspace = async (document: WithId<Document>) => {
    setSelectWorkspaceOpen(true);
  };

  const doCopyToWorkspace = async (
    toWorkspace: string,
    document: WithId<Document>
  ) => {
    try {
      setFullScreenBusy(true);
      const assetsMap: { [id: string]: string } = {};
      const urls = getDocumentAssetURLs(document);

      const storage = getStorage();
      for (const url of urls) {
        const { workspace, name } = parseAssetURL(url);
        const r = ref(storage, `${workspace}/assets/${name}`);

        assetsMap[url] = makeAssetURL(toWorkspace, name);
        const blob = await getBytes(r);
        const meta = await getMetadata(r);
        await uploadBytes(
          ref(storage, `/${toWorkspace}/assets/${name}`),
          blob,
          {
            contentType: meta.contentType,
            customMetadata: meta.customMetadata
          }
        );
      }

      const newDoc = produce(document, draft => {
        for (const k of Object.keys(document.elements)) {
          const e = draft.elements[k];
          if (e.type === "image") {
            e.src = assetsMap[e.src];
          } else if (e.type === "video") {
            e.src = assetsMap[e.src];
          }
        }
      });
      const companyInfo: CompanyInfo = {
        type: "string",
        name: "",
        contact: { name: "", email: "" }
      };

      await createNewDoc(newDoc, toWorkspace, document.name, companyInfo);
    } finally {
      setFullScreenBusy(false);
    }
  };

  const handleClose = (e: MouseEvent, a: string | null) => {
    setAnchorEl(null);
    e.stopPropagation();
    //onAction(a);
    switch (a) {
      case "rename":
        renameDocument();
        break;
      case "delete":
        deleteDocument();
        break;
      case "edit":
        navigate(editDocumentUrl(workspace, document._id));
        break;
      case "convertToTemplate":
        newTemplate(document);
        break;
      case "copyToWorkspace":
        copyToWorkspace(document);
        break;
    }
  };

  const handleListKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Tab") {
      setAnchorEl(null);
    } else if (event.key === "Escape") {
      anchorEl?.focus();
      setAnchorEl(null);
    }
  };

  return (
    <div>
      <IconButton
        disabled={document.readonly}
        variant="plain"
        onClick={handleClick}
        sx={{ "&:focus": { outline: "none" } }}
      >
        <MoreVertIcon />
      </IconButton>

      {fullScreenBusy && <FullscreenBusy />}
      {selectWorkspaceOpen && (
        <SelectWorkspaceDialog
          workspace={workspace}
          onWorkspace={w => {
            setSelectWorkspaceOpen(false);
            if (w) {
              doCopyToWorkspace(w, document);
            }
          }}
        />
      )}
      <Menu
        variant="outlined"
        size="sm"
        anchorEl={anchorEl}
        onKeyDown={handleListKeyDown}
        onClose={() => {
          setAnchorEl(null);
        }}
        sx={{ boxShadow: "md", bgcolor: "background.body" }}
        open={!!anchorEl}
      >
        {!document.readonly && (
          <MenuItem onClick={e => handleClose(e, "edit")}>
            <ListItemDecorator></ListItemDecorator>Edit
          </MenuItem>
        )}
        <MenuItem onClick={e => handleClose(e, "rename")}>
          <ListItemDecorator></ListItemDecorator>Rename
        </MenuItem>
        {document.type === "document" && (
          <MenuItem onClick={e => handleClose(e, "convertToTemplate")}>
            <ListItemDecorator></ListItemDecorator>Convert to template
          </MenuItem>
        )}
        {appCtx?.idTokenResult?.claims.superAdmin && (
          <MenuItem onClick={e => handleClose(e, "copyToWorkspace")}>
            <ListItemDecorator></ListItemDecorator>Copy to workspace
          </MenuItem>
        )}
        <MenuItem onClick={e => handleClose(e, "delete")} color="danger">
          <ListItemDecorator sx={{ color: "inherit" }}>
            <DeleteOutlineIcon />
          </ListItemDecorator>
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
};

const DocumentCard = ({
  workspace,
  document,
  onAction,
  addIconOverlay = false
}: {
  workspace: string;
  document: WithId<Document>;
  onAction: (action: "click", document: WithId<Document>) => void;
  addIconOverlay?: boolean;
}) => {
  const functionsUrl = getFunctionsURL();

  const versionSource = `${document.modified.valueOf()}`;
  const versionHash =
    murmurhash.v3(versionSource) + import.meta.env.VITE_BUILD_HASH;

  return (
    <Box sx={{ m: 1 }}>
      <Card
        variant="outlined"
        sx={{
          position: "relative",
          width: 250,
          height: document.type === "document" ? 170 : 150,
          borderRadius: 6,
          boxShadow: "0 8px 26px rgb(103 110 144 / 0%)",
          p: 0,
          "&:hover": {
            boxShadow: "0 8px 26px rgb(103 110 144 / 20%)"
          }
        }}
      >
        {true && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              width: 120,
              height: 120,
              overflow: "hidden",
              color: "white",
              fontSize: 12,
              textTransform: "uppercase"
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 18,
                left: -12,
                width: 200,
                height: 22,
                alignItems: "center",
                fontSize: 10,

                display: "flex",
                justifyContent: "center",
                overflow: "hidden",
                padding: 1,
                color: "primary.softColor",
                backgroundColor: "primary.softBg",
                textAlign: "center",
                transform: "rotate(45deg)"
              }}
            >
              Template
            </Box>
          </Box>
        )}
        <Box
          sx={{ position: "absolute", left: 0, top: 0, bottom: 0, right: 0 }}
          onClick={() => {
            onAction("click", document);
          }}
        />
        <Box
          sx={{
            p: 2,
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundSize: "cover",
            backgroundImage: document.readonly
              ? "unset"
              : `url(${functionsUrl}/api/preview/${workspace}/${document._id}?version=${versionHash})`
          }}
        >
          {addIconOverlay && (
            <AddIcon sx={{ width: 32, height: 32, color: "#888" }} />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderTop: "1px solid #ccc",
            p: 1.5,
            pt: document.type === "document" ? 1.5 : 0.5,
            pb: document.type === "document" ? 1.5 : 0.5
          }}
        >
          <Box>
            <Typography level="body2" sx={{ fontWeight: 600 }}>
              {document.name}
            </Typography>
            {document.type === "document" && (
              <Typography level="body3">
                Updated {document.modified.format("YYYY-MM-DD HH:mm")}
              </Typography>
            )}
          </Box>
          <Box sx={{ flex: 1 }}></Box>

          <DocumentMenu workspace={workspace} document={document} />
        </Box>
      </Card>
    </Box>
  );
};

const DocumentsGrid = ({
  workspaceDoc
}: {
  workspaceDoc: WithId<Workspace>;
}) => {
  const navigate = useNavigate();
  const { template } = useParams();

  const { data: allDocs, loading } = useCollection<Document>(
    query(
      collection(getFirestore(), "workspaces", workspaceDoc._id ?? "", "docs"),
      orderBy("modified", "desc")
    )
  );

  const blank: WithId<Document> = {
    _id: "blank",
    ...TEMPLATE_DOC,
    name: "Blank",
    type: "template",
    readonly: true
  };

  const docs = (allDocs || []).filter(d => d.type === "document");
  const templates = [
    blank,
    ...(allDocs || []).filter(d => d.type === "template")
  ];

  const templateDoc = templates?.find(t => t._id === template);

  if (loading) {
    return <FullscreenBusy props={{ backgroundColor: "unset" }} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1
      }}
    >
      {templateDoc && (
        <NewDocumentDialog template={templateDoc} workspaceDoc={workspaceDoc} />
      )}
      <Typography level="h3" sx={{ fontWeight: 600 }}>
        Create document
      </Typography>
      <Typography level="body2" gutterBottom sx={{}}>
        Use any of the templates below
      </Typography>
      <Box
        sx={{
          //display: "grid",
          //gridAutoFlow: "column",
          //columnGap: 2,
          display: "flex",
          flexWrap: "wrap",
          mt: 2
        }}
      >
        {templates
          ?.filter(d => !d.deleted)
          ?.map(d => (
            <DocumentCard
              key={d._id}
              workspace={workspaceDoc._id}
              document={d}
              onAction={(action, document) => {
                action === "click" && navigate(`new/${document._id}`);
              }}
            />
          ))}
      </Box>
      <Typography level="h3" sx={{ mt: 4, fontWeight: 600 }}>
        Recently edited
      </Typography>
      <Typography level="body2" gutterBottom sx={{ mb: 2 }}>
        Documents for your customers
      </Typography>

      <DocumentsTable workspaceDoc={workspaceDoc} />
      {false && (
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {docs
            ?.filter(d => !d.deleted)
            ?.map(d => (
              <DocumentCard
                key={d._id}
                workspace={workspaceDoc._id}
                document={d}
                onAction={(action, document) => {
                  if (action === "click") {
                    navigate(`/edit/${workspaceDoc._id}/${document._id}`);
                  }
                }}
              />
            ))}
        </Box>
      )}
    </Box>
  );
};

const TemplatesGrid = ({ workspace }: { workspace: string }) => {
  const navigate = useNavigate();

  const { data: allDocs, loading } = useCollection<Document>(
    query(
      collection(getFirestore(), "workspaces", workspace ?? "", "docs"),
      orderBy("modified", "desc")
    )
  );

  const templates = (allDocs || [])
    ?.filter(d => d.type === "template")
    ?.filter(d => !d.deleted);

  if (loading) {
    return <FullscreenBusy props={{ backgroundColor: "unset" }} />;
  }

  if (templates.length === 0) {
    return (
      <EmptyState2
        title="No templates yet"
        message={
          <Box>
            Create a first template by converting a document to a template from
            your <Link to="..">documents</Link>
          </Box>
        }
      />
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      <Typography level="h3" gutterBottom sx={{ mt: 4, fontWeight: 600 }}>
        Templates
      </Typography>

      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {templates?.map(d => (
          <DocumentCard
            key={d._id}
            workspace={workspace}
            document={d}
            onAction={(action, document) => {
              if (action === "click") {
                navigate(editDocumentUrl(workspace, document._id));
              }
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

const CompanyInfoForm = ({
  name,
  setName,
  companyInfo,
  setCompanyInfo
}: {
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  companyInfo: CompanyInfo;
  setCompanyInfo: Dispatch<SetStateAction<CompanyInfo>>;
}) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: ["1fr 1fr", "1fr 1fr", "1fr 1fr", "1fr"],
        flexDirection: "column",
        mt: 2,
        gap: 1
      }}
    >
      <TextField
        label="Document Name"
        value={name}
        onChange={e => setName(e.target.value)}
      />

      <Typography level="h6" sx={{ mt: 1 }}>
        Customer (recipient)
      </Typography>
      <TextField
        label="Company Name"
        placeholder="Customer company name"
        value={companyInfo.name}
        onChange={e =>
          setCompanyInfo(ci =>
            produce(ci, draft => {
              draft.name = e.target.value;
            })
          )
        }
      />
      <TextField
        label="Contact Name"
        placeholder="Customer contact person name"
        value={companyInfo.contact.name}
        onChange={e =>
          setCompanyInfo(ci =>
            produce(ci, draft => {
              draft.contact.name = e.target.value;
            })
          )
        }
      />
      <TextField
        label="Contact E-Mail"
        placeholder="Customer contact person e-mail"
        value={companyInfo.contact.email}
        onChange={e =>
          setCompanyInfo(ci =>
            produce(ci, draft => {
              draft.contact.email = e.target.value;
            })
          )
        }
      />
    </Box>
  );
};

const EditCompanyInfoDialog = ({
  document,
  workspaceDoc
}: {
  document: WithId<Document>;
  workspaceDoc: WithId<Workspace>;
}) => {
  const navigate = useNavigate();
  const [busy, setBusy] = useState(false);

  const [name, setName] = useState(document.name);

  const [companyInfo, setCompanyInfo] = useState<CompanyInfo>(
    document.companyInfo ?? {
      type: "string",
      name: "",
      contact: { name: "", email: "" }
    }
  );

  const updateDocInfo = async () => {
    try {
      setBusy(true);

      const docRef = doc(
        collection(getFirestore(), "workspaces", workspaceDoc._id, "docs"),
        document._id
      );

      const d: Document = {
        ...document,
        name,
        companyInfo
      };

      await updateDoc(docRef, toDS(d));
      navigate("..");

      //navigate(editDocumentUrl(workspaceDoc._id, id));
    } finally {
      setBusy(false);
    }
  };

  return (
    <Modal
      open={true}
      onClose={() => {
        navigate("..");
      }}
    >
      <ModalDialog
        sx={{
          width: 700,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          {false && (
            <Box sx={{ pb: 1, mb: 2, borderBottom: "1px solid grey" }}>
              <Typography level="h1">{document.name}</Typography>
              <Typography level="body2">
                Updated {document.modified.format("YYYY-MM-DD HH:mm")}
              </Typography>
            </Box>
          )}

          <Typography level="h2">Edit Document Info</Typography>

          <CompanyInfoForm
            name={name}
            setName={setName}
            companyInfo={companyInfo}
            setCompanyInfo={setCompanyInfo}
          />

          <Box sx={{ display: "flex" }}>
            <Box sx={{ flex: 1 }} />
            <Button
              loading={busy}
              variant="soft"
              sx={{ mt: 3, alignSelf: "end" }}
              onClick={() => {
                updateDocInfo();
              }}
            >
              Update
            </Button>
          </Box>
        </Box>
      </ModalDialog>
    </Modal>
  );
};

export const createNewDoc = async (
  template: Document,
  workspace: string,
  name: string,
  companyInfo: CompanyInfo
): Promise<string> => {
  const ui = getAuth().currentUser;
  if (!ui) {
    throw new Error("no current user");
  }

  const id = newId();
  const docRef = doc(
    collection(getFirestore(), "workspaces", workspace, "docs"),
    id
  );

  const shortId = randomShortId();

  const d: Document = {
    ...template,
    version: DOC_VERSION,
    shortId,
    name,
    type: "document",
    readonly: false,
    created: dayjs(),
    modified: dayjs(),
    createdBy: { uid: ui.uid, name: ui.displayName ?? "" },
    companyInfo
  };

  await setDoc(docRef, toDS(d));
  return id;
};
const NewDocumentDialog = ({
  template,
  workspaceDoc
}: {
  template: WithId<Document>;
  workspaceDoc: WithId<Workspace>;
}) => {
  const navigate = useNavigate();
  const [busy, setBusy] = useState(false);

  const [name, setName] = useState(template.name);

  const [companyInfo, setCompanyInfo] = useState<CompanyInfo>({
    type: "string",
    name: "",
    contact: { name: "", email: "" }
  });

  const newDoc = async () => {
    try {
      setBusy(true);

      const id = await createNewDoc(
        template,
        workspaceDoc._id,
        name,
        companyInfo
      );

      navigate(editDocumentUrl(workspaceDoc._id, id));
    } finally {
      setBusy(false);
    }
  };

  return (
    <Modal
      open={true}
      onClose={() => {
        navigate("..");
      }}
    >
      <ModalDialog
        sx={{
          width: 700,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflowY: "scroll"
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Box sx={{ pb: 1, mb: 2, borderBottom: "1px solid grey" }}>
            <Typography level="h1">{template.name}</Typography>
            <Typography level="body2">
              Updated {template.modified.format("YYYY-MM-DD HH:mm")}
            </Typography>
          </Box>

          <Box sx={{ maxHeight: "50vh", overflow: "auto", zoom: 0.5 }}>
            <DocPreview
              doc_={template}
              workspaceDoc={workspaceDoc}
              disableSizeSelect={true}
            />
          </Box>

          {false && (
            <Box sx={{ pt: 1, borderTop: "1px solid grey" }}>
              <Typography level="h1">{template.name}</Typography>
              <Typography level="body2">
                Updated {template.modified.format("YYYY-MM-DD HH:mm")}
              </Typography>
            </Box>
          )}

          <CompanyInfoForm
            name={name}
            setName={setName}
            companyInfo={companyInfo}
            setCompanyInfo={setCompanyInfo}
          />

          <Box sx={{ display: "flex" }}>
            <Box sx={{ flex: 1 }} />
            <Button
              loading={busy}
              variant="soft"
              sx={{ mt: 3, alignSelf: "end" }}
              onClick={() => {
                newDoc();
              }}
            >
              CREATE
            </Button>
          </Box>
        </Box>
      </ModalDialog>
    </Modal>
  );
};

/*
            value={name}
            onChange={e => setName(e.target.value.toLowerCase())}

*/

const TableCell = ({ sx = {}, children }: { sx?: SxProps; children: any }) => {
  return (
    <td>
      <Box sx={{ p: 1, pl: 3, textAlign: "left", ...sx }}>{children}</Box>
    </td>
  );
};

const TableHeaderCell = ({
  sx = {},
  children = undefined
}: {
  sx?: SxProps;
  children?: any;
}) => {
  return (
    <th>
      <Box
        sx={{
          pl: 3,
          pr: 3,
          pt: 2,
          pb: 2,
          textAlign: "left",
          textTransform: "uppercase",
          ...sx
        }}
      >
        {children}
      </Box>
    </th>
  );
};

const Tr = styled("tr")({});
const Table = styled("table")({});

const DocumentsTable = ({
  workspaceDoc
}: {
  workspaceDoc: WithId<Workspace>;
}) => {
  const navigate = useNavigate();
  const appCtx = useContext(AppCtx);

  const { data: allDocs, loading } = useCollection<Document>(
    query(
      collection(getFirestore(), "workspaces", workspaceDoc._id ?? "", "docs"),
      orderBy("modified", "desc")
    )
  );
  const docs = (allDocs || [])
    .filter(d => d.type === "document")
    .filter(d => !d.deleted);

  const { data: allPublished, loading: loadingPublished } =
    useCollection<PublishedDocument>(
      query(
        collection(
          getFirestore(),
          "workspaces",
          workspaceDoc._id ?? "",
          "published"
        ),
        orderBy("modified", "desc")
      )
    );
  const published = (allPublished || []).filter(d => d.type === "document");

  const publisedMap = useMemo(() => {
    return Object.fromEntries(published.map(d => [d._id, d]));
  }, [published]);

  const Published = ({ d }: { d: WithId<Document> }) => {
    const published = publisedMap[d._id] ? "Published" : "Not Published";
    const color = publisedMap[d._id] ? "primary" : "neutral";
    if (
      publisedMap[d._id] &&
      publisedMap[d._id].modified.valueOf() < d.modified.valueOf()
    ) {
      return (
        <Tooltip title="You have unpublished changes">
          <Chip size="sm" color="warning" variant="soft">
            Outdated
          </Chip>
        </Tooltip>
      );
    } else {
      return (
        <Chip color={color} size="sm" variant="soft">
          {published}
        </Chip>
      );
    }
  };

  const maxWidth = 1250;

  if (loading || loadingPublished) {
    return (
      <Card
        sx={{
          maxWidth,
          p: 0,
          minHeight: 100,
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <CircularProgress variant="soft" />
      </Card>
    );
  }

  const functionsUrl = "https://europe-west1-getformalife.cloudfunctions.net";

  if (false && docs.length === 0) {
    return (
      <EmptyState2
        title="No documents yet"
        message="Create a first document using a template above"
      />
    );
  }

  return (
    <Box>
      <Card sx={{ maxWidth, p: 0 }}>
        <Table
          sx={{ borderSpacing: 0, userSelect: "none", fontSize: "0.875rem" }}
        >
          <thead>
            <tr>
              <TableHeaderCell></TableHeaderCell>
              <TableHeaderCell>Name</TableHeaderCell>
              <TableHeaderCell>Company</TableHeaderCell>
              <TableHeaderCell>Contact</TableHeaderCell>
              <TableHeaderCell>Updated</TableHeaderCell>
              <TableHeaderCell>Published</TableHeaderCell>
              <TableHeaderCell>Created By</TableHeaderCell>
              <TableHeaderCell>Messages</TableHeaderCell>
              <TableHeaderCell>Views</TableHeaderCell>
              <TableHeaderCell></TableHeaderCell>
            </tr>
          </thead>
          <tbody>
            {docs.map(d => (
              <Tr
                sx={{ [`&:hover`]: { bgcolor: "neutral.100" } }}
                key={d._id}
                onClick={() => {
                  navigate(editDocumentUrl(workspaceDoc._id, d._id));
                }}
              >
                <TableCell>
                  <Box
                    sx={{
                      //p: 2,
                      flex: 1,
                      minWidth: 80,
                      maxWidth: 80,
                      height: 48,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundSize: "cover",
                      backgroundImage: d.readonly
                        ? "unset"
                        : `url(${functionsUrl}/api/preview/${
                            workspaceDoc._id
                          }/${d._id}?version=${documentHash(d)})`
                    }}
                  ></Box>
                </TableCell>
                <TableCell>
                  {d.name}
                  {appCtx?.idTokenResult?.claims.developer &&
                    d.version !== DOC_VERSION &&
                    ` (v${d.version ?? 1})`}
                </TableCell>
                <TableCell>{d.companyInfo?.name ?? ""}</TableCell>
                <TableCell>{d.companyInfo?.contact?.email ?? ""}</TableCell>
                <TableCell>{d.modified.format("YYYY-MM-DD HH:mm")}</TableCell>
                <TableCell>
                  <Published d={d} />

                  {/*publisedMap[d._id] &&
                  publisedMap[d._id].modified.valueOf() <
                    d.modified.valueOf() && (
                    <Box
                      sx={{
                        display: "inline-flex",
                        ml: 1,
                        borderRadius: "50%",
                        width: 24,
                        height: 24,
                        color: "white",
                        fontWeight: 700,
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "danger.600"
                      }}
                    >
                      !
                    </Box>
                    )*/}
                </TableCell>
                <TableCell>{d.createdBy?.name ?? "-"}</TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  <MessageCount summary={publisedMap[d._id]?.chatSummary} />
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {publisedMap[d._id]?.trackingSummary?.views || ""}
                </TableCell>
                <TableCell>
                  <DocumentMenu workspace={workspaceDoc._id} document={d} />
                </TableCell>
              </Tr>
            ))}
          </tbody>
        </Table>
      </Card>
      {docs.length === 0 && (
        <EmptyState2
          sx={{ mt: 4 }}
          title="No documents yet"
          message="Create a first document using a template above"
        />
      )}
    </Box>
  );
};

const TopMenuItem = ({
  icon,
  name,
  to
}: {
  icon: React.FC;
  name: string;
  to: string;
}) => {
  const path = useResolvedPath(to);
  const Icon = icon;

  return (
    <ListItem component={Link} to={to}>
      <ListItemButton selected={path.pathname === window.location.pathname}>
        <ListItemDecorator>
          <Icon />
        </ListItemDecorator>
        {name}
      </ListItemButton>
    </ListItem>
  );
};

function CountCircle({ count, color }: { count: number; color: string }) {
  if (!count) return null;
  return (
    <Box
      sx={{
        display: "inline-flex",
        borderRadius: "50%",
        width: 24,
        height: 24,
        color: `${color}.softColor`,
        fontWeight: 700,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          color === "transparent" ? "transparent" : `${color}.softBg`
      }}
    >
      {count > 0 ? count : ""}
    </Box>
  );
}

const MessageCount = ({ summary }: { summary?: ChatSummary }) => {
  if (!summary) {
    return null;
  }
  const { unread, total } = summary;

  if (unread) {
    return (
      <>
        <CountCircle count={total} color="transparent" />
        <CountCircle count={unread} color="danger" />
      </>
    );
  } else {
    return <CountCircle count={total} color="transparent" />;
  }
};

function TopMenuList() {
  const appCtx = useContext(AppCtx);
  return (
    <List
      row
      sx={{
        '& [role="button"]': {
          borderRadius: 6
        }
      }}
    >
      {appCtx?.idTokenResult?.claims.superAdmin && (
        <TopMenuItem name="Admin" to="admin" icon={AdminPanelSettingsIcon} />
      )}

      <TopMenuItem name="Documents" to="." icon={ArticleIcon} />
      <TopMenuItem name="Templates" to="templates" icon={ContentPasteIcon} />
      <TopMenuItem name="Analytics" to="analytics" icon={Apps} />
      <TopMenuItem name="Assets" to="assets" icon={PhotoIcon} />
      <TopMenuItem name="Settings" to="settings" icon={TuneIcon} />
    </List>
  );
}

const Dashboard = () => {
  const { workspace } = useParams();
  const [profileDialogOpen, setProfileDialogOpen] = useState(false);

  useTrack("dashboard", { workspace });

  const { data: workspaces, loading: loadingWorkspaces } =
    useCollection<Workspace>(
      query(
        collection(getFirestore(), "workspaces"),
        where(
          `members.${getAuth().currentUser?.uid}.uid`,
          "==",
          getAuth().currentUser?.uid
        )
      )
    );

  const { data: workspaceDoc, loading: workspaceDocLoading } =
    useDocument<Workspace>(
      doc(collection(getFirestore(), "workspaces"), workspace)
    );

  if (loadingWorkspaces || workspaceDocLoading) {
    return <FullscreenBusy props={{ backgroundColor: "unset" }} />;
  }

  if (!workspace || !workspaceDoc || !workspaces) {
    return <Error404 />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100vw",
        height: "100vh",
        backgroundColor: "#f6f6f9"
      }}
    >
      {profileDialogOpen && (
        <ProfileDialog onClose={() => setProfileDialogOpen(false)} />
      )}
      <WorkspacesTabs workspace={workspace} workspaces={workspaces} />
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <TopBar>
          {false && (
            <Typography level="body2" sx={{ ml: 1 }}>
              {workspaceDoc?.name} workspace
            </Typography>
          )}
          <TopMenuList />
          {false && (
            <IconButton
              component={Link}
              to={"settings"}
              size="sm"
              variant="soft"
              sx={{ ml: 1 }}
            >
              <TuneIcon />
            </IconButton>
          )}
          <Box sx={{ flex: 1 }} />
          <IconButton
            variant="plain"
            sx={{ "&:focus": { outline: "none" } }}
            onClick={() => setProfileDialogOpen(true)}
          >
            <CurrentUserAvatar size="sm" />
          </IconButton>
          <IconButton
            variant="plain"
            sx={{ "&:focus": { outline: "none" } }}
            onClick={() => {
              signOut(getAuth());
            }}
          >
            <LogoutIcon />
          </IconButton>
        </TopBar>

        <Box sx={{ display: "flex", p: 2, overflow: "auto" }}>
          <Routes>
            <Route
              path="/settings"
              element={<WorkspaceSettings workspace={workspaceDoc} />}
            />
            <Route
              path="/analytics"
              element={<Analytics workspace={workspace} />}
            />
            <Route
              path="/templates"
              element={<TemplatesGrid workspace={workspace} />}
            />
            <Route
              path="/assets"
              element={<AssetManager workspace={workspace} />}
            />
            <Route
              path="/new/:template"
              element={<DocumentsGrid workspaceDoc={workspaceDoc} />}
            />
            <Route
              path="/admin"
              element={<Admin workspaceDoc={workspaceDoc} />}
            />
            <Route
              path="*"
              element={<DocumentsGrid workspaceDoc={workspaceDoc} />}
            />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
};

const WorkspacesTab = ({ workspace }: { workspace: WithId<Workspace> }) => {
  const { data } = useCollection<PublishedDocument>(
    query(
      collection(getFirestore(), "workspaces", workspace._id, "published"),
      where("chatSummary.unread", ">", 0)
    )
  );

  const unread = data?.reduce((a, b) => a + (b?.chatSummary?.unread ?? 0), 0);
  return (
    <Tooltip title={workspace.name} variant="soft">
      <Badge
        badgeInset="15%"
        badgeContent={unread || 0}
        size="sm"
        color="danger"
      >
        <Tab
          component={Link}
          value={workspace._id}
          to={`/dashboard/${workspace._id}`}
        >
          {workspace.name?.[0]}
        </Tab>
      </Badge>
    </Tooltip>
  );
};

const WorkspacesTabs = ({
  workspace,
  workspaces
}: {
  workspace: string;
  workspaces: WithId<Workspace>[];
}) => {
  return (
    <SideBar>
      <Tabs
        variant="soft"
        aria-label="Icon tabs"
        value={workspace}
        orientation="vertical"
        sx={theme => ({
          borderRadius: "lg",
          mx: "auto",
          "--Tabs-gap": "8px",
          [`& .${tabClasses.root}`]: {
            textTransform: "capitalize",
            color: "white",
            bgcolor: "primary.400",
            whiteSpace: "nowrap",
            transition: "0.3s",
            fontWeight: "lg",
            flex: 1,
            width: 40,
            height: 40,
            [`&:not(.${tabClasses.selected}):not(:hover)`]: {
              opacity: 0.72
            },
            [`&:hover`]: {
              opacity: 1,
              bgcolor: "primary.300",
              color: "white"
            },
            [`&.${tabClasses.selected}:before`]: {
              content: '""',
              display: "block",
              position: "absolute",
              left: -12,
              height: "70%",
              width: 4,
              bgcolor: "primary.400"
            }
          }
        })}
      >
        <TabList sx={{ backgroundColor: "white" }}>
          {workspaces?.map(w => (
            <WorkspacesTab key={w._id} workspace={w} />
          ))}
        </TabList>
      </Tabs>
    </SideBar>
  );
};

const DefaultWorkspace = () => {
  const { data: workspaces, loading: loadingWorkspaces } =
    useCollection<Workspace>(
      query(
        collection(getFirestore(), "workspaces"),
        where(
          `members.${getAuth().currentUser?.uid}.uid`,
          "==",
          getAuth().currentUser?.uid
        )
      )
    );

  if (loadingWorkspaces) {
    return <FullscreenBusy />;
  }

  const wp = workspaces || [];

  if (wp.length > 0) {
    return <Navigate to={`/dashboard/${wp[0]._id}`} replace />;
  }

  return <Navigate to="/new-workspace" replace />;
};

const NewWorkspace = () => {
  const [name, setName] = useState("");
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState<any>();

  const navigate = useNavigate();

  const validName = () => /^[a-z0-9_]+$/.test(name);

  const newWorkspace = async () => {
    setBusy(true);
    setError(undefined);
    const uid = getAuth().currentUser?.uid;
    // TODO: Pass uid in context...
    if (!uid) {
      return;
    }

    const workspace: Workspace = {
      name,
      members: { [uid]: { uid, role: "owner" } },
      colors: DefaultPalette
    };

    try {
      await setDoc(
        doc(collection(getFirestore(), "workspaces"), name),
        toDS(workspace),
        { merge: true }
      );

      navigate("/");
    } catch (e) {
      console.error(JSON.stringify(e));
      setError(e);
    } finally {
      setBusy(false);
    }
  };

  const errorMessage = () => {
    if (error) {
      return "Workspace already exists. Use another name.";
    } else if (name && !validName()) {
      return "The name can only include characters, numbers and _";
    }
    return undefined;
  };

  return (
    <FullPageCard>
      <Box
        sx={{
          p: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Typography level="h2" gutterBottom>
          Create a new Workspace
        </Typography>
        <Typography level="body2">
          A workspace is where you can create and share documents with
          customers.
        </Typography>

        <Box sx={{ display: "grid", rowGap: 3, mt: 4 }}>
          <Box sx={{ width: 400 }}>
            <Alert
              color="danger"
              variant="plain"
              size="sm"
              sx={{ mb: 1, opacity: errorMessage() ? 1 : 0 }}
            >
              {errorMessage()}
            </Alert>

            <TextField
              placeholder="Workspace name"
              value={name}
              onChange={e => {
                setError(undefined);
                setName(e.target.value.toLowerCase());
              }}
            />
          </Box>
          <Button
            disabled={busy || !name || !validName()}
            sx={{ minWidth: "24em", p: 1 }}
            variant="soft"
            onClick={newWorkspace}
          >
            Create workspace
          </Button>
        </Box>
        <Box sx={{ flex: 1 }} />
      </Box>
    </FullPageCard>
  );
};

const Main = () => {
  return (
    <Routes>
      <Route path="/" element={<DefaultWorkspace />} />
      <Route path="/new-workspace" element={<NewWorkspace />} />
      <Route path="/edit/:workspace/:id/*" element={<EditDoc />} />
      <Route path="/preview/:workspace/:id" element={<PreviewDoc />} />
      <Route path="/dashboard/:workspace/*" element={<Dashboard />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

const TheApp = () => {
  const [user, setUser] = useState<User>();
  const [idTokenResult, setIdTokenResult] = useState<IdTokenResult>();
  const [loading, setLoading] = useState(true);
  const headlessToken = localStorage.getItem("_TOKEN");

  useEffect(() => {
    const signIn = async () => {
      try {
        if (headlessToken) {
          const uc = await signInWithCustomToken(getAuth(), headlessToken);
          setUser(uc.user);
        }
      } finally {
        setLoading(false);
      }
    };
    if (headlessToken) {
      signIn();
    }
  }, []);

  useEffect(() => {
    if (!headlessToken) {
      onAuthStateChanged(getAuth(), async user => {
        setLoading(false);
        setUser(u => u || user || undefined);

        const itr = await user?.getIdTokenResult();
        if (itr) {
          setIdTokenResult(itr);
        }
        if (user?.uid) {
          mixpanel.identify(user?.uid);
          mixpanel.people.set({ $name: user.displayName, $email: user.email });
        }
      });
    }
  }, []);

  if (loading) {
    return <FullscreenBusy />;
  }

  return (
    <AppCtx.Provider
      value={{ features: FEATURES, debug: false, idTokenResult }}
    >
      <DocumentStyles />
      <Routes>
        <Route path="/d/:id" element={<PublicDocView />} />
        <Route path="*" element={user ? <Main /> : <SignIn />} />
      </Routes>
    </AppCtx.Provider>
  );
};

const ForceUpdateDialog = () => {
  return (
    <Modal open={true}>
      <ModalDialog
        sx={{
          width: 500,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Typography level="h4" gutterBottom sx={{ textAlign: "center" }}>
          New version available
        </Typography>
        <Typography level="body1">Current version outdated</Typography>
        <Button
          variant="soft"
          sx={{ mt: 4, alignSelf: "center" }}
          onClick={() => window.location.reload()}
        >
          UPDATE
        </Button>
      </ModalDialog>
    </Modal>
  );
};

const App = () => {
  const [forceUpdate, setForceUpdate] = useState(false);
  const checkVersion = useDebouncedCallback(async () => {
    if (forceUpdate) {
      return;
    }
    const v = await (await fetch("/version.json")).json();
    if (
      v &&
      import.meta.env.VITE_BUILD_HASH &&
      v.hash !== import.meta.env.VITE_BUILD_HASH
    ) {
      console.log(
        `new version available ${v.hash} (${import.meta.env.VITE_BUILD_HASH})`
      );

      setForceUpdate(true);
    }
  }, 1000);

  useEventListener("mousemove", () => {
    checkVersion();
  });

  return (
    <BrowserRouter>
      <Suspense fallback={<FullscreenBusy />}>
        {forceUpdate && <ForceUpdateDialog />}
        <TheApp />
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
