import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/joy";
import { KeyboardEvent, MouseEvent, useState } from "react";
import { WithId } from "./data";
import { Document, DOC_VERSION } from "./Model";
import { upgradeDoc } from "./upgradeDoc";

export function DeveloperMenu({
  workspace,
  document,
  updateDoc
}: {
  workspace: string;
  document: WithId<Document>;
  updateDoc: (f: (s: WithId<Document>) => void) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: MouseEvent, a: string | null) => {
    setAnchorEl(null);
    e.stopPropagation();
    //onAction(a);
    switch (a) {
      case "copy":
        navigator.clipboard.writeText(JSON.stringify(document, null, 2));

        break;
      case "upgrade":
        updateDoc(d => upgradeDoc(d, DOC_VERSION));
        break;

      case "destoryDoc":
        updateDoc(d => {
          (d as any).foo = undefined;
        });
        break;

      case "simulateError":
        const doesNoExists: any = undefined;
        doesNoExists();
        break;
    }
  };

  const handleListKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Tab") {
      setAnchorEl(null);
    } else if (event.key === "Escape") {
      anchorEl?.focus();
      setAnchorEl(null);
    }
  };

  return (
    <div>
      <IconButton
        disabled={document.readonly}
        variant="plain"
        onClick={handleClick}
        sx={{ "&:focus": { outline: "none" } }}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        variant="outlined"
        size="sm"
        anchorEl={anchorEl}
        onKeyDown={handleListKeyDown}
        onClose={() => {
          setAnchorEl(null);
        }}
        sx={{ boxShadow: "md", bgcolor: "background.body" }}
        open={!!anchorEl}
      >
        <MenuItem disabled onClick={e => handleClose(e, "copy")}>
          Version: {document.version ?? "undefined"}
        </MenuItem>
        <MenuItem onClick={e => handleClose(e, "copy")}>
          Copy to Clipboard
        </MenuItem>
        <MenuItem onClick={e => handleClose(e, "simulateError")}>
          Simulate Error
        </MenuItem>
        <MenuItem onClick={e => handleClose(e, "destoryDoc")}>
          Destroy Document
        </MenuItem>
        <MenuItem
          disabled={(document.version ?? 1) === DOC_VERSION}
          onClick={e => handleClose(e, "upgrade")}
        >
          Upgrade
        </MenuItem>
      </Menu>
    </div>
  );
}
