import { Box } from "@mui/joy";
import { pageMaxWidth } from "./defaults";
import { DeleteWrapper } from "./DeleteWrapper";
import { PlaceholderImageElement } from "./Model";

export function PlaceholderImageView({
  placeholder
}: {
  placeholder: PlaceholderImageElement;
}) {
  const dataAttributes = placeholder?.style?.attributes ?? {};

  return (
    <DeleteWrapper element={placeholder}>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          maxHeight: "100%",
          overflow: "hidden"
        }}
      >
        <Box
          {...dataAttributes}
          sx={{
            display: "flex",
            flex: 1,
            aspectRatio: "4 / 3",
            backgroundColor: "white",
            border: "1px solid #dddddd",
            borderRadius: 8,
            maxWidth: `${pageMaxWidth}px`
          }}
        >
          <Box
            sx={{
              display: "flex",
              flex: 1,
              backgroundColor: "#dddddd",
              m: 1,
              borderRadius: 8
            }}
          ></Box>
        </Box>
      </Box>
    </DeleteWrapper>
  );
}
