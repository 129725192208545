import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  List,
  ListDivider,
  ListItem,
  ListItemContent,
  Option,
  Select,
  TextField,
  Typography
} from "@mui/joy";
import { getAuth } from "firebase/auth";
import { collection, doc, getFirestore, setDoc } from "firebase/firestore";
import produce from "immer";
import { Fragment, useRef, useState } from "react";
import { ColorEditorDialog } from "./ColorEditorDialog";
import { toDS, WithId } from "./data";
import { getFn } from "./fns";
import { newId } from "./Infospot";
import { NamedColor, Role, Roles, SlackState, Workspace } from "./Model";
import { UserAvatar } from "./UserAvatar";
import { useUserInfo } from "./useUserInfo";

const Name = ({ uid }: { uid: string }) => {
  const { userInfo, loading } = useUserInfo(uid);

  const getString = () => {
    if (loading) {
      return "...";
    }
    if (userInfo?.email) {
      if (userInfo?.name) {
        return `${userInfo.name} (${userInfo.email})`;
      } else {
        return `${userInfo.email}`;
      }
    }

    return "unknown";
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
      <UserAvatar uid={userInfo?.uid ?? ""} />
      <span>{getString()}</span>
    </Box>
  );
};

const ColorView = ({
  color,
  onClick
}: {
  color: NamedColor;
  onClick: () => void;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
      onClick={onClick}
    >
      <Box
        sx={{ width: 100, height: 80, bgcolor: color.value, borderRadius: 8 }}
      ></Box>
      <Typography level="body3" sx={{ mt: 0.5 }}>
        {color.name} <i>{color.value}</i>
      </Typography>
    </Box>
  );
};

const isOwner = (workspace: Workspace) => {
  const u = getAuth().currentUser;
  if (u) {
    return workspace.members[u.uid]?.role === "owner";
  }
  return false;
};

const WorkspaceMembers = ({ workspace }: { workspace: WithId<Workspace> }) => {
  const count = Object.values(workspace.members).length;
  const [busy, setBusy] = useState(false);

  const setRole = async (uid: string, role: Role) => {
    try {
      const docRef = doc(
        collection(getFirestore(), "workspaces"),
        workspace._id
      );

      const wp = produce(workspace, draft => {
        if (draft.members?.[uid]) {
          draft.members[uid].role = role;
        }
      });
      await setDoc(docRef, toDS(wp));
    } finally {
    }
  };

  const ownerCount = Object.values(workspace.members).filter(
    m => m.role === "owner"
  ).length;

  const remove = async (uid: string) => {
    const r = window.confirm("Are you sure?");
    if (!r) {
      return;
    }

    try {
      setBusy(false);
      const docRef = doc(
        collection(getFirestore(), "workspaces"),
        workspace._id
      );

      if (uid) {
        const wp = produce(workspace, draft => {
          const m = draft.members || {};
          delete m[uid];
          draft.members = m;
        });
        await setDoc(docRef, toDS(wp));
      }
    } finally {
      setBusy(false);
    }
  };

  return (
    <List
      sx={{
        //width: 600,
        borderRadius: "sm",
        boxShadow: "sm",
        bgcolor: "background.body"
      }}
    >
      {Object.values(workspace.members).map((m, i) => (
        <Fragment key={m.uid}>
          <ListItem>
            <ListItemContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Name uid={m.uid} />
                <Box sx={{ display: "flex", gap: 1 }}>
                  <SelectRole
                    disabled={
                      !isOwner(workspace) ||
                      (ownerCount === 1 && m.role === "owner")
                    }
                    role={m.role}
                    onRole={r => {
                      if (r !== "remove") {
                        setRole(m.uid, r);
                      } else {
                        remove(m.uid);
                      }
                    }}
                  />
                  {false && (
                    <Button
                      loading={busy}
                      color="danger"
                      size="sm"
                      onClick={() => remove(m.uid)}
                    >
                      Remove
                    </Button>
                  )}{" "}
                </Box>
              </Box>
            </ListItemContent>
          </ListItem>
          {i < count - 1 && <ListDivider />}
        </Fragment>
      ))}
    </List>
  );
};

const SelectRole = ({
  role,
  onRole,
  disabled = false
}: {
  role: Role;
  onRole: (r: Role | "remove") => void;
  disabled?: boolean;
}) => {
  return (
    <Select
      disabled={disabled}
      value={role}
      size="sm"
      sx={{ minWidth: "8em" }}
      slotProps={{
        button: {
          sx: {
            textTransform: "capitalize"
          }
        }
      }}
      onChange={(e, v) => {
        if (v) {
          onRole(v);
        }
      }}
    >
      {Roles.map(r => (
        <Option key={r} value={r} sx={{ textTransform: "capitalize" }}>
          {r}
        </Option>
      ))}
      <Option
        key={"remove"}
        value={"remove"}
        color="danger"
        sx={{ textTransform: "capitalize" }}
      >
        remove
      </Option>
    </Select>
  );
};

const SlackIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: "16px", width: "16px", marginRight: "12px" }}
    viewBox="0 0 122.8 122.8"
  >
    <path
      d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
      fill="#e01e5a"
    ></path>
    <path
      d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
      fill="#36c5f0"
    ></path>
    <path
      d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
      fill="#2eb67d"
    ></path>
    <path
      d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
      fill="#ecb22e"
    ></path>
  </svg>
);

const SlackButton = ({ workspace }: { workspace: WithId<Workspace> }) => {
  const [busy, setBusy] = useState(false);

  const state: SlackState = {
    workspace: workspace._id,
    redirectTo: window.location.href
  };

  const stateJson = encodeURIComponent(JSON.stringify(state));
  //const host = "https://e81d-46-39-96-162.eu.ngrok.io";
  const host = "https://app.infospot.co";
  const slackUrl = `https://slack.com/oauth/v2/authorize?client_id=4648359109703.4749096614836&scope=incoming-webhook&redirect_uri=${host}/slack/auth&state=${stateJson}`;
  return (
    <Button
      disabled={busy}
      loading={busy}
      style={{
        alignItems: "center",
        color: "#fff",
        backgroundColor: "#4A154B",
        border: "0",
        borderRadius: "4px",
        display: "inline-flex",
        fontSize: "14px",
        fontWeight: "600",
        height: "44px",
        justifyContent: "center",
        textDecoration: "none",
        width: "204px"
      }}
      onClick={async () => {
        if (workspace.slackConnection) {
          const r = window.confirm("Are you sure?");
          if (r) {
            const remove = getFn("removeSlack");
            try {
              setBusy(true);
              await remove({ workspace: workspace._id });
            } finally {
              setBusy(false);
            }
          }
        } else {
          window.location.assign(slackUrl);
        }
      }}
    >
      <SlackIcon />
      {workspace.slackConnection ? "Remove from Slack" : "Connect to Slack"}
    </Button>
  );
};

export function WorkspaceSettings({
  workspace
}: {
  workspace: WithId<Workspace>;
}) {
  const [editColor, setEditColor] = useState<NamedColor>();
  const [email, setEmail] = useState<string>("");
  const [busy, setBusy] = useState(false);

  const validEmail = !!email.match(/^\S+@\S+\.\S+$/);

  const currentUser = getAuth().currentUser;

  const invitationEnabled =
    currentUser && workspace.members[currentUser.uid]?.role === "owner";

  // NOTE: ref as color can be stale when calling onClose directly after onColor.. (Delete)
  const editColorRef = useRef<NamedColor>();

  const addColor = async () => {
    const docRef = doc(collection(getFirestore(), "workspaces"), workspace._id);

    const wp = produce(workspace, draft => {
      const cs = draft.colors || [];
      cs.push({ id: newId(), name: "Unnamed", value: "#000000" });
      draft.colors = cs;
    });
    await setDoc(docRef, toDS(wp));
  };

  const updateColor = async (color: NamedColor) => {
    const docRef = doc(collection(getFirestore(), "workspaces"), workspace._id);

    const wp = produce(workspace, draft => {
      draft.colors = (draft.colors || []).map(c =>
        c.id === color.id ? color : c
      );
    });
    await setDoc(docRef, toDS(wp));
  };

  const invite = async () => {
    try {
      setBusy(true);

      const f = getFn<{ workspace: string; email: string }>("invite");
      await f({ workspace: workspace._id, email });

      setEmail("");
    } finally {
      setBusy(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start"
        }}
      >
        <Typography level="h3" gutterBottom sx={{ fontWeight: 600 }}>
          Palette
        </Typography>

        {editColor && (
          <ColorEditorDialog
            color={editColor}
            onColor={c => {
              setEditColor(c);
              editColorRef.current = c;
            }}
            onClose={() => {
              setEditColor(undefined);
              if (editColorRef.current) {
                updateColor(editColorRef.current);
              }
            }}
          />
        )}

        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
          {workspace.colors
            ?.filter(c => !c.deleted)
            ?.map(c => (
              <ColorView key={c.id} color={c} onClick={() => setEditColor(c)} />
            ))}
        </Box>

        <Button variant="soft" size="sm" sx={{ mt: 2 }} onClick={addColor}>
          <AddIcon />
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 700,
          maxWidth: 700
        }}
      >
        <Typography level="h3" gutterBottom sx={{ fontWeight: 600 }}>
          Workspace Members
        </Typography>
        <Box>
          <Box
            sx={{
              display: "flex",
              gap: 1,
              mb: 2,
              alignSelf: "flex-end",
              justifyContent: "flex-end"
            }}
          >
            <TextField
              placeholder="email"
              size="sm"
              disabled={!invitationEnabled}
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <Button
              size="sm"
              disabled={!invitationEnabled || !email || !validEmail}
              onClick={invite}
              loading={busy}
            >
              Invite
            </Button>
          </Box>
          <WorkspaceMembers workspace={workspace} />
        </Box>
      </Box>

      <Box>
        <Typography level="h3" gutterBottom sx={{ fontWeight: 600 }}>
          Slack
        </Typography>

        {workspace.slackConnection && (
          <Typography level="body1" gutterBottom>
            Information about document visits are sent to channel{" "}
            <b>
              <i>{workspace.slackConnection.channel}</i>
            </b>
          </Typography>
        )}

        <SlackButton workspace={workspace} />
      </Box>
      <Box sx={{ minHeight: 60 }} />
    </Box>
  );
}
