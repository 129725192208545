import { NamedColor } from "./Model";

export const DefaultPalette: NamedColor[] = [
  { id: "_grey", name: "Grey", value: "#D4D4D4" },
  { id: "_lightgreen", name: "Light Green", value: "#8AE1CE" },
  { id: "_magenta", name: "Magenta", value: "#D85D9C" },
  { id: "_purple", name: "Purple", value: "#6E30EC" },
  { id: "_red", name: "Red", value: "#D85846" },
  { id: "_orange", name: "Orange", value: "#E09033" },
  { id: "_yellow", name: "Yellow", value: "#EFCF5F" },
  { id: "_green", name: "Green", value: "#7CC171" },
  { id: "_lightblue", name: "Light Blue", value: "#76C1F1" },
  { id: "_blue", name: "Blue", value: "#375EEA" }
];
