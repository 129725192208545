import { Box, styled } from "@mui/joy";
import { useContext } from "react";
import { pageMaxWidth } from "./defaults";
import { DeleteWrapper } from "./DeleteWrapper";
import { DocumentCtx, styleToSx } from "./Infospot";
import { ImageElement, Style } from "./Model";
const Img = styled("img")({});

export function ImageView({ image }: { image: ImageElement }) {
  const { colors } = useContext(DocumentCtx);

  const dataAttributes = image?.style?.attributes ?? {};

  const fixWidth = (sx: Style): any => {
    const mw = sx.maxWidth || `min(${pageMaxWidth}px, 100%)`;
    //    return { ...sx, maxWidth: `calc(min(99%, ${mw}))` };
    return { ...sx, maxWidth: `${mw}` };
  };
  return (
    <DeleteWrapper element={image}>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          justifyContent: "center"
        }}
      >
        <Img
          {...dataAttributes}
          src={image.src}
          sx={{
            objectFit: "cover",
            ...fixWidth(styleToSx(image.style, colors))
          }}
          alt="imageview"
        />
      </Box>
    </DeleteWrapper>
  );
}
