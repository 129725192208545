import { Box } from "@mui/joy";
import { DeleteWrapper } from "./DeleteWrapper";
import { EmbedElement } from "./Model";

export function EmbedView({ embed }: { embed: EmbedElement }) {
  return (
    <DeleteWrapper element={embed}>
      <Box style={{ display: "flex", height: 400 }}>
        <iframe
          width="100%"
          src="https://docs.google.com/presentation/d/17DsCMCVKCxt7fGLFYm9IuU8SO5LKLqUmveVyyEWKbYg/embed"
          allowFullScreen
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          frameBorder={0}
          title="infospot"
        ></iframe>
      </Box>
    </DeleteWrapper>
  );
}
