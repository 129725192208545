import {
  FullMetadata,
  getDownloadURL,
  getMetadata,
  getStorage,
  list,
  ref,
  StorageReference,
  updateMetadata,
  uploadBytes
} from "firebase/storage";
import { useEffect, useState } from "react";
import { newId, sha1 } from "./Infospot";

const g_AssetManagers: { [id: string]: AssetManager } = {};

export type Asset = {
  ref: StorageReference;
  meta: FullMetadata;
  url: string;
};

function blobToDataURL(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = _e => resolve(reader.result as string);
    reader.onerror = _e => reject(reader.error);
    reader.onabort = _e => reject(new Error("Read aborted"));
    reader.readAsDataURL(blob);
  });
}

function getVideoCover(file: File, seekTo = 0.1): Promise<Blob | null> {
  console.log("getting video cover for file: ", file);
  return new Promise((resolve, reject) => {
    // load the file to a video player
    console.log(1);
    const videoPlayer = document.createElement("video");
    videoPlayer.setAttribute("src", URL.createObjectURL(file));
    videoPlayer.load();
    videoPlayer.addEventListener("error", ex => {
      console.log(2);
      reject("error when loading video file");
    });
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener("loadedmetadata", () => {
      console.log(3);
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < seekTo) {
        reject("video is too short.");
        return;
      }
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        console.log(4);

        videoPlayer.currentTime = seekTo;
      }, 200);
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener("seeked", () => {
        console.log("video is now paused at %ss.", seekTo);
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement("canvas");
        const aspect = videoPlayer.videoHeight / videoPlayer.videoWidth;
        canvas.width = 300;
        canvas.height = canvas.width * aspect;
        // draw the video frame to canvas
        const ctx = canvas.getContext("2d");
        if (ctx) {
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
          // return the canvas image as a blob

          ctx.canvas.toBlob(
            blob => {
              resolve(blob);
            },
            "image/jpeg",
            0.5 /* quality */
          );
        }
      });
    });
  });
}

class AssetManager {
  workspace: string;
  assets: Asset[];
  listeners: (() => void)[];
  loading: boolean;

  constructor(workspace: string) {
    this.workspace = workspace;
    this.assets = [];
    this.listeners = [];
    this.loading = false;
    this.init();
  }

  init() {
    (async () => {
      this.loading = true;
      this.notify();
      const storage = getStorage();
      const storageRef = ref(storage, `/${this.workspace}/assets`);
      const r = await list(storageRef);
      const ms = await Promise.all(r.items.map(x => getMetadata(x)));

      const urls = await Promise.all(r.items.map(x => getDownloadURL(x)));

      const as = ms.map((m, i) => ({ ref: r.items[i], meta: m, url: urls[i] }));
      this.assets = as;
      this.loading = false;
      this.notify();
    })();
  }

  async upload(f: File): Promise<Asset> {
    const ext = f.name.split(".").pop();
    const fileId = newId() + "." + ext;
    const image = await f.arrayBuffer();
    const sha1_ = await sha1(image);

    let preview = undefined;

    for (const a of this.assets) {
      if (a.meta.customMetadata?.sha1 === sha1_) {
        console.log("USING CACHED ASSET", a.ref.fullPath);
        return a;
      }
    }

    if (f.type.startsWith("video")) {
      const t = await getVideoCover(f);
      if (t) {
        const u = await blobToDataURL(t);
        preview = u;
        console.log(preview);
      }
    }

    const storage = getStorage();
    const storageRef = ref(storage, `/${this.workspace}/assets/${fileId}`);
    //const task = uploadBytesResumable(storageRef, image, {
    //  contentType: f.type
    //});
    const customMetadata: { sha1: string; name: string; preview?: string } = {
      sha1: sha1_,
      name: f.name
    };
    if (preview) {
      customMetadata.preview = preview;
    }
    /*const r =*/ await uploadBytes(storageRef, image, {
      contentType: f.type,
      customMetadata
    });
    const meta = await getMetadata(storageRef);
    const url = await getDownloadURL(storageRef);
    const a: Asset = { ref: storageRef, meta, url };
    this.assets.push(a);
    this.notify();
    return a;
  }

  async delete(a: Asset) {
    const cm = a.meta.customMetadata ?? {};
    cm.deleted = "true";
    a.meta.customMetadata = cm;
    await updateMetadata(a.ref, a.meta);
    this.notify();
  }

  async undoDelete(a: Asset) {
    const cm = a.meta.customMetadata ?? {};
    cm.deleted = "";
    a.meta.customMetadata = cm;
    console.log("!!!!", cm);
    await updateMetadata(a.ref, a.meta);
    this.notify();
  }

  notify() {
    for (const l of this.listeners) {
      try {
        l();
      } catch (e) {
        console.error(e);
      }
    }
  }

  addListener(l: () => void) {
    this.listeners.push(l);
  }

  removeListener(l: () => void) {
    this.listeners = this.listeners.filter(x => x !== l);
  }
}

export const useAssetManager = (workspace: string) => {
  let am = g_AssetManagers[workspace];
  if (!am) {
    am = new AssetManager(workspace);
    g_AssetManagers[workspace] = am;
  }

  const [assets, setAssets] = useState<Asset[]>(
    am.assets.filter(a => !a.meta.customMetadata?.deleted)
  );

  const [deletedAssets, setDeletedAssets] = useState<Asset[]>(
    am.assets.filter(a => a.meta.customMetadata?.deleted)
  );

  const [loading, setLoading] = useState(am.loading);

  useEffect(() => {
    const l = () => {
      setLoading(am.loading);
      setAssets([...am.assets].filter(a => !a.meta.customMetadata?.deleted));
      setDeletedAssets(
        [...am.assets].filter(a => a.meta.customMetadata?.deleted)
      );
    };
    am.addListener(l);
    return () => {
      am.removeListener(l);
    };
  }, []);

  const upload = async (f: File): Promise<Asset> => {
    return am.upload(f);
  };

  const getPreview = (url: string): string | undefined => {
    for (const a of assets) {
      if (a.url === url) {
        return a.meta.customMetadata?.preview;
      }
    }
    for (const a of deletedAssets) {
      if (a.url === url) {
        return a.meta.customMetadata?.preview;
      }
    }
    return undefined;
  };

  const deleteAsset = async (a: Asset) => {
    await am.delete(a);
  };

  const undoDeleteAsset = async (a: Asset) => {
    await am.undoDelete(a);
  };

  return {
    loading,
    assets,
    deletedAssets,
    upload,
    getPreview,
    deleteAsset,
    undoDeleteAsset
  };
};
