import { Box, Input } from "@mui/joy";
import { useContext } from "react";
import { DeleteWrapper } from "./DeleteWrapper";
import { DocumentCtx, styleToSx } from "./Infospot";
import { LoomElement } from "./Model";
import LOOM_LOGO from "./assets/loom.svg";
import { Toolbar } from "./Toolbar";

// https://loom.com/451438010

export const loomID = (url: string) => {
  try {
    const pathname = new URL(url).pathname;

    const m = /\/(.*?)\/(.*?)$/.exec(pathname);
    if (m) {
      return m[2];
    } else {
      return undefined;
    }
  } catch (e) {
    return undefined;
  }
};

export function LoomView({ loom }: { loom: LoomElement }) {
  const { usePreviewAssets, colors, doc, selectionElement, updateDoc } =
    useContext(DocumentCtx);

  const id = loomID(loom.url ?? "");
  return (
    <DeleteWrapper element={loom}>
      <Toolbar
        open={doc.selection?.element === loom.id && !!selectionElement}
        anchorEl={selectionElement}
        onClose={() => {}}
      >
        <Input
          className="toolbar-item"
          value={loom.url}
          sx={{ width: "30em" }}
          variant="plain"
          onChange={e => {
            updateDoc(d => {
              (d.elements[loom.id] as LoomElement).url = e.target.value;
            });
          }}
          placeholder="Loom Link"
          size="sm"
        />
      </Toolbar>

      <Box
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          ...styleToSx(loom.style, colors)
        }}
      >
        {usePreviewAssets || !id ? (
          <img
            src={LOOM_LOGO}
            alt="loom Logo"
            style={{ padding: 32, width: "100%", maxWidth: 600 }}
          />
        ) : (
          <iframe
            src={`https://www.loom.com/embed/${id}`}
            width="640"
            height="360"
            frameBorder={0}
            allowFullScreen
            title="Video"
            style={{ maxWidth: "100%" }}
          ></iframe>
        )}
      </Box>
    </DeleteWrapper>
  );
}
