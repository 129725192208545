import { Box } from "@mui/joy";
import produce from "immer";
import { useContext } from "react";
import { getColor } from "./Color";
import { ColorPicker } from "./ColorPicker";
import { DeleteWrapper } from "./DeleteWrapper";
import { DocumentCtx } from "./Infospot";
import { HeadingElement } from "./Model";
import { SimpleEditor } from "./SimpleEditor";
import { Toolbar } from "./Toolbar";

export function HeadingView({ heading }: { heading: HeadingElement }) {
  const { doc, updateDoc, selectionElement, viewMode, colors } =
    useContext(DocumentCtx);

  const size = heading.size === 1 ? 64 : 48;
  const color = getColor(heading.color ?? "black", colors);

  return (
    <DeleteWrapper element={heading}>
      <Toolbar
        open={doc.selection?.element === heading.id && !!selectionElement}
        anchorEl={selectionElement}
        onClose={() => {
          //setSelectionElement(null);
        }}
      >
        <ColorPicker
          colors={colors}
          color={heading.color}
          onColor={c => {
            updateDoc(_ =>
              produce(doc, draft => {
                (draft.elements[heading.id] as HeadingElement).color = c as any;
              })
            );
          }}
        />
      </Toolbar>
      <Box sx={{ display: "flex", flex: 1, justifyContent: "center" }}>
        <h1
          style={{
            fontSize: size,
            color,
            outline: "none",
            marginBlockStart: 0,
            marginBlockEnd: 0
          }}
        >
          {viewMode === "edit" ? (
            <SimpleEditor
              text={heading.text}
              onChange={t => {
                updateDoc(d => {
                  (d.elements[heading.id] as HeadingElement).text = t;
                });
              }}
            />
          ) : (
            heading.text
          )}
        </h1>
      </Box>
    </DeleteWrapper>
  );
}
