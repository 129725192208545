import { DndContext } from "@dnd-kit/core";
import { Box } from "@mui/joy";
import { collection, doc, getFirestore } from "firebase/firestore";
import { ChatView } from "./ChatView";
import { useDocument } from "./data";
import { DocumentContainer } from "./DocumentContainer";
import { ElementView } from "./ElementView";
import { Error404 } from "./Error404";
import { ErrorView } from "./ErrorView";
import { FullscreenBusy } from "./FullscreenBusy";
import { DocumentCtx, upgradeDocDynamically } from "./Infospot";
import { PublishedDocument } from "./Model";

export function DocView({
  publicDoc,
  id,
  workspace,
  disableChat = false
}: {
  publicDoc: boolean;
  id: string;
  workspace: string;
  disableChat?: boolean;
}) {
  const { data: doc_, loading } = useDocument<PublishedDocument>(
    doc(
      collection(getFirestore(), "workspaces", workspace ?? "", "published"),
      id
    ),
    {
      upgrade: upgradeDocDynamically
    }
  );

  if (loading) {
    return <FullscreenBusy props={{ backgroundColor: "unset" }} />;
  }

  if (!doc_) {
    if (publicDoc) {
      return <Error404 />;
    } else {
      return <ErrorView msg="Document not published" />;
    }
  }

  if (!workspace) {
    return <Error404 />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      {!disableChat && (
        <ChatView doc={doc_} workspace={workspace} readOnly={false} />
      )}
      {/* TODO: DndContext as we have useDndMonitor in SectionView... better solution!?? */}
      <DndContext>
        <DocumentCtx.Provider
          value={{
            public: true,
            viewMode: "view",
            presentation: true,
            doc: { ...doc_, selection: null },
            workspace: workspace,
            colors: doc_.publishedColors ?? {},
            //setDoc,
            updateDoc: () => {},
            fileDropActive: false,
            selectionElement: null,
            setSelectionElement: () => {},
            renderContext: "other"
          }}
        >
          <DocumentContainer doc={doc_}>
            <ElementView eid={doc_.root} />
          </DocumentContainer>
        </DocumentCtx.Provider>
      </DndContext>
    </Box>
  );
}
