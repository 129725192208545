import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  Modal,
  ModalDialog,
  Typography
} from "@mui/joy";
import { getAuth } from "firebase/auth";
import { collection, getFirestore, query, where } from "firebase/firestore";
import { useCollection } from "./data";
import { Workspace } from "./Model";

export const SelectWorkspaceDialog = ({
  workspace,
  onWorkspace
}: {
  workspace: string;
  onWorkspace: (workspace: string | undefined) => void;
}) => {
  const { data: workspaces, loading: loadingWorkspaces } =
    useCollection<Workspace>(
      query(
        collection(getFirestore(), "workspaces"),
        where(
          `members.${getAuth().currentUser?.uid}.uid`,
          "==",
          getAuth().currentUser?.uid
        )
      )
    );

  return (
    <Modal
      open={true}
      onClose={e => {
        onWorkspace(undefined);
      }}
    >
      <ModalDialog
        sx={{
          width: 700,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Typography level="h4">Select Workspace</Typography>
          {loadingWorkspaces && (
            <CircularProgress
              variant="soft"
              sx={{ alignSelf: "center", m: 4 }}
            />
          )}

          <List>
            {(workspaces || [])
              .filter(w => w._id !== workspace)
              .map(w => (
                <ListItem key={w._id}>
                  <ListItemButton
                    onClick={e => {
                      e.stopPropagation();
                      onWorkspace(w.name);
                    }}
                  >
                    {w.name}
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Box>
      </ModalDialog>
    </Modal>
  );
};
