import { Input } from "@mui/joy";
import { SxProps } from "@mui/material";
import { useRef, useState } from "react";

export function CssInput({
  value = "",
  onValue,
  sx = {}
}: {
  value: string;
  onValue: (v: string) => void;
  sx?: SxProps;
}) {
  const state = useRef({ focus: false, value: "" });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dummy, setDummy] = useState(0);
  const ref = useRef<any>();

  return (
    <Input
      size="sm"
      sx={{ backgroundColor: "white", ...sx }}
      ref={ref}
      onFocus={e => {
        state.current.focus = true;
        state.current.value = value;
        e.target.select();
      }}
      onBlur={() => {
        if (onValue) {
          onValue(state.current.value);
        }
        state.current.focus = false;
        state.current.value = "";
      }}
      onKeyDown={e => {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
          const t = e.target as any;
          if (t.blur) {
            t.blur();
          }
        }
      }}
      onChange={e => {
        if (state.current.focus) {
          state.current.value = e.target.value;
          setDummy((d: number) => d + 1);
        }
      }}
      value={state.current.focus ? state.current.value : value}
    />
  );
}
