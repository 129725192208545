import { TextStyle } from "./Model";

export const Display1: TextStyle = {
  id: "display-1",
  name: "Display 1",
  element: "h1",
  fontFamily: "Public Sans",
  fontSize: "4.5rem",
  fontWeight: "700",
  lineHeight: "1.25",
  letterSpacing: "-0.01em",
  margin: "0px 0px 0.35em 0px"
};

export const Display2: TextStyle = {
  id: "display-2",
  name: "Display 2",
  element: "h2",
  fontFamily: "Public Sans",
  fontSize: "3.75rem",
  fontWeight: "700",
  lineHeight: "1.25",
  letterSpacing: "-0.01em",
  margin: "0px 0px 0.35em 0px"
};

export const Heading1: TextStyle = {
  id: "heading-1",
  name: "Heading 1",
  element: "h1",
  fontFamily: "Public Sans",
  fontSize: "3rem",
  fontWeight: "600",
  lineHeight: "1.25",
  letterSpacing: "-0.01em",
  margin: "0px 0px 0.35em 0px"
};

export const Heading2: TextStyle = {
  id: "heading-2",
  name: "Heading 2",
  element: "h2",
  fontFamily: "Public Sans",
  fontSize: "2.25rem",
  fontWeight: "600",
  lineHeight: "1.25",
  letterSpacing: "-0.01em",
  margin: "0px 0px 0.35em 0px"
};

export const Heading3: TextStyle = {
  id: "heading-3",
  name: "Heading 3",
  element: "h3",
  fontFamily: "Public Sans",
  fontSize: "1.875rem",
  fontWeight: "500",
  lineHeight: "1.25",
  margin: "0px 0px 0.35em 0px"
};

export const Heading4: TextStyle = {
  id: "heading-4",
  name: "Heading 4",
  element: "h4",
  fontFamily: "Public Sans",
  fontSize: "1.5rem",
  fontWeight: "500",
  lineHeight: "1.5",
  margin: "0px 0px 0.35em 0px"
};

export const Body1: TextStyle = {
  id: "paragraph",
  name: "Paragraph",
  element: "p",
  fontFamily: "Public Sans",
  fontSize: "1rem",
  fontWeight: "400",
  lineHeight: "1.5",
  margin: "0px 0px 0.35em 0px"
};

export const LargeText: TextStyle = {
  id: "large-text",
  name: "Large Text",
  element: "p",
  fontFamily: "Public Sans",
  fontSize: "1.19rem",
  fontWeight: "400",
  lineHeight: "1.5"
};

export const pageMaxWidth = 1000;

export const allStyles = [
  Display1,
  Display2,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Body1,
  LargeText
];
