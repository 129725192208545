import { Box, Typography } from "@mui/material";

export function ErrorView({
  msg,
  size = "normal"
}: {
  msg: string;
  size?: "small" | "normal";
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      {size !== "small" && <Typography sx={{ fontSize: 100 }}>🤷‍♀️</Typography>}
      <Typography>Error. {msg}</Typography>
    </Box>
  );
}
