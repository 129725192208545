import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/joy";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import { KeyboardEvent, MouseEvent, useState } from "react";

export type Command = {
  id: string;
  label: string;
  icon: any;
};

export const CommandMenu = ({
  commands,
  onCommand
}: {
  commands: Command[];
  onCommand: (id: string) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: MouseEvent, a: string | null) => {
    setAnchorEl(null);
    e.stopPropagation();
    if (a) {
      onCommand(a);
    }
  };

  const handleListKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Tab") {
      setAnchorEl(null);
    } else if (event.key === "Escape") {
      anchorEl?.focus();
      setAnchorEl(null);
    }
  };

  const Icon = ({ c }: { c: Command }) => {
    const I = c.icon;
    return <I />;
  };

  return (
    <div>
      <IconButton
        variant="plain"
        onClick={handleClick}
        size="sm"
        sx={{ "&:focus": { outline: "none" } }}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        variant="outlined"
        size="sm"
        anchorEl={anchorEl}
        onKeyDown={handleListKeyDown}
        onClose={() => {
          setAnchorEl(null);
        }}
        sx={{ boxShadow: "md", bgcolor: "background.body" }}
        open={!!anchorEl}
      >
        {commands.map(c => (
          <MenuItem key={c.id} onClick={e => handleClose(e, c.id)}>
            <ListItemDecorator sx={{ color: "inherit" }}>
              <Icon c={c} />
            </ListItemDecorator>
            {c.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
