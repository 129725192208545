import { styled, Typography } from "@mui/joy";
import { useContext } from "react";
import { pageMaxWidth } from "./defaults";
import { DeleteWrapper } from "./DeleteWrapper";
import { DocumentCtx, styleToSx } from "./Infospot";
import { VideoElement } from "./Model";
import { useAssetManager } from "./useAssetManager";

const Video = styled("video")({});

export function VideoView({ video }: { video: VideoElement }) {
  const { usePreviewAssets, workspace, colors } = useContext(DocumentCtx);
  const { getPreview } = useAssetManager(workspace);
  const dataAttributes = video?.style?.attributes ?? {};

  const preview = getPreview(video.src);

  return (
    <DeleteWrapper element={video}>
      {usePreviewAssets ? (
        preview ? (
          <img
            src={preview}
            style={{
              objectFit: "cover",
              width: "100%",
              ...styleToSx(video.style, colors)
            }}
            alt="video"
          />
        ) : (
          <Typography level="display2" sx={{ textAlign: "center" }}>
            No preview available
          </Typography>
        )
      ) : (
        <Video
          {...dataAttributes}
          className="video"
          sx={{
            maxWidth: `${pageMaxWidth}px`,
            ...styleToSx(video.style, colors)
          }}
          controls
          playsInline
          muted={false}
          autoPlay={true}
          loop={false}
          data-inline-media=""
          data-inline-media-plugins="ViewportSource, PlayPauseButton"
          preload="none"
          role="presentation"
          data-inline-media-basepath="/105/media/ww/tv-home/2022/4447b88b-1a33-4bb3-98a1-61d8949e1098/anim/sizzle/sizzle_3/"
          src={video.src}
        />
      )}
    </DeleteWrapper>
  );
}
