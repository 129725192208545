import { Avatar, AvatarProps, Badge } from "@mui/joy";
import { getAuth } from "firebase/auth";

const isPhotoURLSet = (url: string | null | undefined) => {
  if (!url) {
    return false;
  } else {
    return true;
  }
};

export const CurrentUserAvatar = (p: AvatarProps) => {
  const count = isPhotoURLSet(getAuth().currentUser?.photoURL) ? 0 : 1;
  return (
    <Badge badgeContent={count} color="danger" size={p.size ?? "sm"}>
      <Avatar
        src={getAuth().currentUser?.photoURL || ""}
        imgProps={{ referrerPolicy: "no-referrer" }}
        {...p}
      />
    </Badge>
  );
};
