import { Box, CircularProgress } from "@mui/joy";
import { useEffect, useState } from "react";

export function FullscreenBusy({ props = {} }: { props?: any }) {
  const [active, setActive] = useState(false);
  useEffect(() => {
    setTimeout(() => setActive(true), 30);
  }, []);
  if (!active) {
    return null;
  }

  return (
    <Box
      sx={{
        position: "fixed",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        opacity: 0.5,
        backgroundColor: "black",
        zIndex: 10000,
        ...props
      }}
    >
      <CircularProgress variant="soft" />
    </Box>
  );
}
