import {
  Box,
  Card,
  CircularProgress,
  Menu,
  MenuItem,
  Typography
} from "@mui/joy";
import { PopperProps } from "@mui/material";
import { useState } from "react";
import { EmptyState2 } from "./EmptyState2";
import { Asset, useAssetManager } from "./useAssetManager";

const AssetCard = ({
  workspace,
  asset,
  deleteAsset,
  undo = false
}: {
  workspace: string;
  asset: Asset;
  deleteAsset: (a: Asset) => Promise<void>;
  undo?: boolean;
}) => {
  const { getPreview } = useAssetManager(workspace);

  const [anchorEl, setAnchorEl] = useState<PopperProps["anchorEl"]>(null);

  const handleContextMenu = (event: any) => {
    event.preventDefault();
    const x = event.clientX;
    const y = event.clientY;
    const getBoundingClientRect = () => ({
      x,
      y,
      width: 0,
      height: 0,
      top: y,
      right: x,
      bottom: y,
      left: x,
      toJSON: () => ""
    });

    setAnchorEl({
      getBoundingClientRect
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box sx={{ m: 1 }} onContextMenu={handleContextMenu}>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} size="sm">
        <MenuItem
          onClick={async () => {
            setAnchorEl(null);
            await deleteAsset(asset);
          }}
        >
          {undo ? "Undo Delete" : "Delete"}
        </MenuItem>
      </Menu>

      <Card
        variant="outlined"
        sx={{
          position: "relative",
          width: 250,
          height: 150,
          borderRadius: 6,
          boxShadow: "0 8px 26px rgb(103 110 144 / 0%)",
          justifyContent: "center",
          p: 0,
          "&:hover": {
            boxShadow: "0 8px 26px rgb(103 110 144 / 20%)"
          }
        }}
      >
        <Box
          sx={{ position: "absolute", left: 0, top: 0, bottom: 0, right: 0 }}
          onClick={() => {}}
        />
        {asset.meta.contentType?.startsWith("image") && (
          <img
            src={asset.url}
            style={{ objectFit: "cover", maxWidth: "100%", maxHeight: "100%" }}
            alt="asset"
          />
        )}
        {asset.meta.contentType?.startsWith("video") && (
          <img
            src={getPreview(asset.url)}
            style={{ objectFit: "cover", maxWidth: "100%", maxHeight: "100%" }}
            alt="asset"
          />
        )}
      </Card>
    </Box>
  );
};
// TODO: rename to AssetBrowser
export function AssetManager({ workspace }: { workspace: string }) {
  const { loading, assets, deleteAsset, undoDeleteAsset, deletedAssets } =
    useAssetManager(workspace);

  const B = () => (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        mt: 2
      }}
    >
      <CircularProgress variant="soft" />
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1
      }}
    >
      <Typography level="h3" gutterBottom sx={{ fontWeight: 600 }}>
        Assets
      </Typography>
      <Typography level="body2" gutterBottom sx={{}}>
        Deleted assets will be permanently deleted when no documents are
        referring to the asset after 30 days.
        <br /> Deleting an asset will never break existing documents.
      </Typography>

      <Box sx={{ display: "flex", flexFlow: "row wrap" }}>
        {loading && <B />}

        {!loading && assets.length === 0 && (
          <EmptyState2
            title="No assets yet"
            message="Images and videos added to documents will appear here"
          />
        )}
        {assets.map(a => (
          <AssetCard
            key={a.ref.fullPath}
            workspace={workspace}
            asset={a}
            deleteAsset={deleteAsset}
          />
        ))}
      </Box>
      {false && (
        <>
          <Typography level="h3" sx={{ mt: 4, fontWeight: 600 }}>
            Deleted Assets
          </Typography>
          <Typography level="body2" gutterBottom sx={{}}>
            Deleted assets will be permanently deleted when no documents are
            referring to the asset after 30 days
          </Typography>
          <Box sx={{ display: "flex", flexFlow: "row wrap" }}>
            {loading && <B />}

            {deletedAssets.map(a => (
              <AssetCard
                key={a.ref.fullPath}
                undo={true}
                workspace={workspace}
                asset={a}
                deleteAsset={undoDeleteAsset}
              />
            ))}
          </Box>
        </>
      )}
      <Box sx={{ minHeight: 30 }} />
    </Box>
  );
}
