import produce from "immer";
import { Fragment, useContext } from "react";
import { AlignmentSelector } from "./AlignmentSelector";
import { getTextColor } from "./Color";
import { ColorPicker } from "./ColorPicker";
import { DeleteWrapper } from "./DeleteWrapper";
import { DocumentCtx } from "./Infospot";
import { BodyElement } from "./Model";
import { SimpleEditor } from "./SimpleEditor";
import { Toolbar } from "./Toolbar";

export function BodyView({ body }: { body: BodyElement }) {
  const { doc, updateDoc, selectionElement, viewMode, colors } =
    useContext(DocumentCtx);

  return (
    <DeleteWrapper element={body}>
      <Toolbar
        open={doc.selection?.element === body.id && !!selectionElement}
        anchorEl={selectionElement}
        onClose={() => {
          //setSelectionElement(null);
        }}
      >
        <ColorPicker
          colors={colors}
          color={body.color}
          onColor={c => {
            updateDoc(_ =>
              produce(doc, draft => {
                (draft.elements[body.id] as BodyElement).color = c as any;
              })
            );
          }}
        />

        <AlignmentSelector
          alignment={body.alignment}
          onAlignment={p => {
            updateDoc(_ =>
              produce(doc, draft => {
                if (p !== undefined) {
                  (draft.elements[body.id] as BodyElement).alignment = p;
                }
              })
            );
          }}
        />
      </Toolbar>
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: body.alignment,
          outline: "none",
          textAlign: "start",
          fontSize: 19,
          color: getTextColor(body.color ?? "black")
          //
        }}
      >
        {viewMode === "edit" ? (
          <SimpleEditor
            text={body.text}
            onChange={t => {
              updateDoc(d => {
                (d.elements[body.id] as BodyElement).text = t;
              });
            }}
          />
        ) : (
          body.text.split("\n").map((x, i) => (
            <Fragment key={i}>
              {x} <br />
            </Fragment>
          ))
        )}
      </div>
    </DeleteWrapper>
  );
}
