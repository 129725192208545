import { Box, Input } from "@mui/joy";
import { useContext } from "react";
import { DeleteWrapper } from "./DeleteWrapper";
import { DocumentCtx, styleToSx } from "./Infospot";
import { CalendlyElement } from "./Model";
import CALENDLY_LOGO from "./assets/calendly.svg";
import { Toolbar } from "./Toolbar";
import { InlineWidget } from "react-calendly";

const Calendly = ({ url }: { url?: string }) => {
  if (url) {
    return <InlineWidget url={url} pageSettings={{ hideGdprBanner: true }} />;
  } else {
    return (
      <img src={CALENDLY_LOGO} style={{ maxWidth: 320 }} alt="Calendly Logo" />
    );
  }
};

export function CalendlyView({ calendly }: { calendly: CalendlyElement }) {
  const { doc, usePreviewAssets, selectionElement, updateDoc, colors } =
    useContext(DocumentCtx);
  return (
    <DeleteWrapper element={calendly}>
      <Toolbar
        open={doc.selection?.element === calendly.id && !!selectionElement}
        anchorEl={selectionElement}
        onClose={() => {}}
      >
        <Input
          className="toolbar-item"
          value={calendly.url}
          sx={{ width: "30em" }}
          variant="plain"
          onChange={e => {
            updateDoc(d => {
              (d.elements[calendly.id] as CalendlyElement).url = e.target.value;
            });
          }}
          placeholder="Calendly Meeting Link"
          size="sm"
        />
      </Toolbar>

      <Box
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          ...styleToSx(calendly.style, colors)
        }}
      >
        {usePreviewAssets ? (
          <img
            src={CALENDLY_LOGO}
            alt="Calendly Logo"
            style={{ padding: 32 }}
          />
        ) : (
          <Calendly url={calendly.url} />
        )}
      </Box>
    </DeleteWrapper>
  );
}
