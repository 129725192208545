import { Box, Menu, MenuItem } from "@mui/joy";
import { PopperProps } from "@mui/material";
import { useContext, useState } from "react";
import { DocumentCtx, cloneDocument, findParent } from "./Infospot";
import { DocumentElement } from "./Model";

export function SelectionWrapper({
  element,
  children
}: {
  element: DocumentElement;
  children: any;
}) {
  const {
    doc: doc_,
    updateDoc,
    fileDropActive,
    setSelectionElement,
    renderContext
  } = useContext(DocumentCtx);

  const [anchorEl, setAnchorEl] = useState<PopperProps["anchorEl"]>(null);

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renameSection = async () => {
    const name = prompt("Rename element to", element.name ?? "Untitled");

    if (name) {
      updateDoc(d => {
        d.elements[element.id].name = name;
      });
    }
  };

  const duplicate = () => {
    const [clonedTemplateDoc, idMap] = cloneDocument(doc_);

    const p = findParent(doc_, element);
    if (p?.elements) {
      updateDoc(doc => {
        for (const x of Object.keys(clonedTemplateDoc.elements)) {
          doc.elements[x] = clonedTemplateDoc.elements[x];
        }

        const i = doc.elements[p.id].elements?.indexOf(element.id);
        if (i !== undefined && i !== -1) {
          doc.elements[p.id].elements?.splice(i, 0, idMap[element.id]);
        }
      });
    }
  };

  const onClick = async (a: string) => {
    switch (a) {
      case "rename":
        await renameSection();
        break;
      case "duplicate":
        duplicate();
        break;
    }
    setAnchorEl(null);
  };

  const lineType = fileDropActive ? "dashed" : "solid";

  const handleContextMenu = (event: any) => {
    // TODO: Disable rename context menu for asset browser
    // Perhaps something else than renderContext here..
    if (renderContext !== "main") {
      return;
    }
    event.preventDefault();
    const x = event.clientX;
    const y = event.clientY;
    const getBoundingClientRect = () => ({
      x,
      y,
      width: 0,
      height: 0,
      top: y,
      right: x,
      bottom: y,
      left: x,
      toJSON: () => ""
    });

    setAnchorEl({
      getBoundingClientRect
    });
  };

  return (
    <div
      onClick={e => {
        e.stopPropagation();

        updateDoc(d => {
          d.selection = { element: element.id, slateSelection: null };
        });

        setSelectionElement(e.currentTarget);
      }}
      style={{
        position: "relative",
        display: "flex",
        flex: 1
      }}
      onContextMenu={handleContextMenu}
    >
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} size="sm">
        <MenuItem onClick={() => onClick("duplicate")}>Duplicate</MenuItem>
        <MenuItem onClick={() => onClick("rename")}>Rename Element</MenuItem>
      </Menu>
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          borderRadius: 4,
          pointerEvents: "none",
          zIndex: 1,
          border: `1px ${lineType} transparent`
        }}
      />

      <Box
        sx={theme => ({
          position: "absolute",
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          borderRadius: 4,
          pointerEvents: "none",
          zIndex: 1,
          border:
            doc_.selection?.element === element.id
              ? `1.5px ${lineType} #054DA644`
              : `1.5px ${lineType} transparent`
        })}
      />
      {children}
    </div>
  );
}
