import { Box } from "@mui/joy";
import { ClickAwayListener } from "@mui/material";
import { Popup } from "./Popup";

export function Toolbar({
  open,
  anchorEl,
  onClose,
  children
}: {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  children: any;
}) {
  //const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  // onClickAway={handleClose}
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%"
        }}
      >
        <Popup
          role={undefined}
          open={open}
          anchorEl={anchorEl}
          disablePortal
          placement="top"
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [0, 4]
              }
            }
          ]}
        >
          <ClickAwayListener onClickAway={onClose} mouseEvent="onMouseDown">
            <div
              style={{
                display: "flex",
                border: "1px solid #ccc",
                //padding: 6,
                fontSize: 13,
                borderRadius: 20,
                boxShadow: "0 1px 3px rgba(43,42,53,.12)",
                backgroundColor: "white"
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  columnGap: 0,
                  gridAutoFlow: "column",
                  alignItems: "center",
                  ".toolbar-item:first-child": {
                    borderTopLeftRadius: 20,
                    borderBottomLeftRadius: 20
                  },
                  ".toolbar-item:last-child": {
                    borderTopRightRadius: 20,
                    borderBottomRightRadius: 20
                  }

                  /*"*": {
                    "&:first-child": {
                      borderTopLeftRadius: 20,
                      borderBottomLeftRadius: 20
                    },
                    "&:last-child": {
                      borderTopRightRadius: 20,
                      borderBottomRightRadius: 20
                    }
                  }*/
                }}
              >
                {children}
              </Box>
            </div>
          </ClickAwayListener>
        </Popup>
      </div>
    </div>
  );
}
