import { Box, Chip } from "@mui/joy";
import { useContext } from "react";
import { AppCtx } from "./Infospot";
import { DocumentElement } from "./Model";

export function ElementLabel({ element }: { element: DocumentElement }) {
  const { debug } = useContext(AppCtx);

  return (
    <Box sx={{ position: "absolute", left: 0, top: -1 }}>
      {
        <Chip
          size="sm"
          sx={{
            userSelect: "none",
            pointerEvents: "none",
            "--Chip-radius": "4px",
            opacity: 0.7,
            textTransform: "capitalize"
          }}
        >
          {element.type} ({element.name}){debug && <>{element.id}</>}
          {element.type === "section" &&
            (element.style?.direction === "row" ? "→" : "↓")}
        </Chip>
      }
    </Box>
  );
}
