import { useDroppable } from "@dnd-kit/core";
import { Box } from "@mui/joy";
import { PageElement, SectionElement } from "./Model";
import { SectionView } from "./SectionView";
export function PageView({ page }: { page: PageElement }) {
  const { setNodeRef } = useDroppable({
    id: page.id
  });

  const section: SectionElement = {
    id: page.id,
    elements: page.elements,
    type: "section",
    style: page.style
  };
  return (
    <Box
      ref={setNodeRef}
      sx={{
        display: "flex",
        flex: 1,
        // TODO: Temphack to make initial DnD easier... (somewhere to drop...)
        minHeight: 800
      }}
    >
      <SectionView section={section} pageMode={true} />
    </Box>
  );
}
