import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/joy";
import produce from "immer";
import { MouseEvent, useContext } from "react";
import { DocumentCtx } from "./Infospot";
import { DocumentElement } from "./Model";

export function DeleteWrapper({
  element,
  children,
  disabled = false
}: {
  element: DocumentElement;
  children: any;
  disabled?: boolean;
}) {
  const { doc, updateDoc } = useContext(DocumentCtx);

  const onDelete = (e: MouseEvent) => {
    e.stopPropagation();
    updateDoc(_ =>
      produce(doc, draft => {
        // TODO: Recursive
        delete draft.elements[element.id];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const [id, e] of Object.entries(draft.elements)) {
          if (e.elements) {
            e.elements = e.elements.filter(x => x !== element.id);
          }
        }
      })
    );
  };

  return (
    <div style={{ position: "relative", display: "flex", flex: 1 }}>
      <div style={{ position: "absolute", right: 8, top: 8 }}>
        {!disabled && doc.selection?.element === element.id && (
          <IconButton
            sx={{ "--IconButton-size": "32px", zIndex: 2 }}
            onClick={onDelete}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </div>
      {children}
    </div>
  );
}
