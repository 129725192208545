import { Avatar, AvatarProps } from "@mui/joy";
import { useUserInfo } from "./useUserInfo";

export const UserAvatar = (p: AvatarProps & { uid: string }) => {
  const { userInfo } = useUserInfo(p.uid);

  return (
    <Avatar
      src={userInfo?.photoURL || ""}
      imgProps={{ referrerPolicy: "no-referrer" }}
      {...p}
    />
  );
};
