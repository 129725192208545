import { ListItemDecorator } from "@mui/joy";
import Option from "@mui/joy/Option";
import { NamedColor } from "./Model";
import { PlainSelect } from "./PlainSelect";

export function ColorPicker({
  disabled = false,
  colors,
  color,
  onColor
}: {
  disabled?: boolean;
  colors: { [id: string]: NamedColor };
  color: string | undefined;
  onColor: (color: string | undefined) => void;
}) {
  return (
    <PlainSelect
      className="toolbar-item"
      disabled={disabled}
      value={color ?? "-"}
      size="sm"
      sx={{
        minWidth: 150
      }}
      onChange={(e, v) => {
        onColor(v ?? undefined);
      }}
    >
      {Object.entries(colors)
        .filter(e => !e[1].deleted)
        .map(([id, c]) => (
          <Option key={id} value={id}>
            <ListItemDecorator>
              <div
                style={{
                  width: 20,
                  height: 20,
                  backgroundColor: colors[id]?.value ?? "red",
                  border: "1px solid #ccc",
                  borderRadius: "50%",
                  marginRight: 6
                }}
              />
            </ListItemDecorator>
            {c.name}
          </Option>
        ))}
    </PlainSelect>
  );
}
