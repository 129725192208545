import { NamedColor, Workspace } from "./Model";

export const TEXT_COLORS: { [id: string]: NamedColor } = {
  light: {
    id: "light",
    name: "Light",
    value: "white"
  },
  dark: {
    id: "dark",
    name: "Dark",
    value: "black"
  },
  black: {
    id: "black",
    name: "Black",
    value: "black"
  }
};

export const BUILTIN_COLORS: { [id: string]: NamedColor } = {
  transparent: {
    id: "transparent",
    name: "Transparent",
    value: "transparent"
  },
  white: {
    id: "white",
    name: "White",
    value: "white"
  },
  black: {
    id: "black",
    name: "Black",
    value: "black"
  },
  default: {
    id: "default",
    name: "Background",
    value: "#f5f5f7"
  },
  pop: {
    id: "pop",
    name: "Pop",
    value: "#01012a",
    deleted: true
  },
  light: {
    id: "light",
    name: "Light",
    value: "white",
    deleted: true
  },
  dark: {
    id: "dark",
    name: "Dark",
    value: "black",
    deleted: true
  }
};

export const getTextColor = (id: string): string => {
  return TEXT_COLORS[id]?.value ?? "red";
};

export const getColors = (
  workspace: Workspace
): { [id: string]: NamedColor } => {
  const colors = {
    ...BUILTIN_COLORS,
    ...Object.fromEntries(workspace?.colors?.map(c => [c.id, c]) ?? [])
  };
  return colors;
};

export const getColor = (id: string, colors: { [id: string]: NamedColor }) => {
  //const colors = getColors(workspace);
  return colors?.[id]?.value ?? "red";
};
