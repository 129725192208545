import { getApp } from "firebase/app";

export const makeStorageUrl = (workspace: string, path: string): string => {
  const p = encodeURIComponent(path);
  const bucket = getApp().options.storageBucket;
  return `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${workspace}%2F${p}?alt=media`;
};

export const makeAssetURL = (workspace: string, name: string): string => {
  const p = encodeURIComponent(`${workspace}/assets/${name}`);
  return `https://firebasestorage.googleapis.com/v0/b/getformalife.appspot.com/o/${p}?alt=media`;
};

export const parseAssetURL = (
  url: string
): { workspace: string; name: string } => {
  const u = new URL(url);
  const bucket = getApp().options.storageBucket;

  const p = decodeURIComponent(u.pathname);
  if (p.startsWith(`/v0/b/${bucket}/o/workspaces`)) {
    const re = new RegExp(`/v0/b/${bucket}/o/workspaces/(.*?)/assets/(.*)`);
    const m = p.match(re);
    if (m && m.length === 3) {
      return { workspace: m[1], name: m[2] };
    } else {
      throw new Error(`invalid url ${url}`);
    }
  } else {
    const re = new RegExp(`/v0/b/${bucket}/o/(.*?)/assets/(.*)`);
    const m = p.match(re);
    if (m && m.length === 3) {
      return { workspace: m[1], name: m[2] };
    } else {
      throw new Error(`invalid url ${url}`);
    }
  }
};
