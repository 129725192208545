import { Box, Button } from "@mui/joy";
import { Typography } from "@mui/material";

export function HttpErrorPage({
  status,
  msg,
  error
}: {
  status: string;
  msg: string;
  error?: Error;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center"
        //backgroundColor: theme => theme.palette.background.default
      }}
    >
      <Typography
        variant="h3"
        paragraph
        sx={{ textAlign: "center", lineHeight: "1.5em" }}
      >
        {status} - {msg}
      </Typography>
      <Typography variant="body1" paragraph>
        {error ? <div>{error.toString()}</div> : <div>That's all we know</div>}
      </Typography>

      <Button
        variant="soft"
        sx={{ marginTop: 2 }}
        onClick={() => {
          window.location.href = "/";
        }}
      >
        Back to home
      </Button>
    </Box>
  );
}
