import { Box, Card } from "@mui/joy";

export function FullPageCard({ children }: { children: any }) {
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "#f6f6f9",
        flex: 1,
        width: "100vw",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Card
        sx={{
          alignItems: "center"
        }}
      >
        {children}
      </Card>
    </Box>
  );
}
