import { Box } from "@mui/joy";
import { useContext } from "react";
import { useInView } from "react-intersection-observer";
import { BodyView } from "./BodyView";
import { CalendlyView } from "./CalendlyView";
import { DeleteWrapper } from "./DeleteWrapper";
import { ElementLabel } from "./ElementLabel";
import { EmbedView } from "./EmbedView";
import { HeadingView } from "./HeadingView";
import { ImageView } from "./ImageView";
import { DocumentCtx } from "./Infospot";
import { PageView } from "./PageView";
import { PlaceholderImageView } from "./PlaceholderImageView";
import { SectionView } from "./SectionView";
import { SelectionWrapper } from "./SelectionWrapper";
import TextView from "./TextView";
import { VideoView } from "./VideoView";
import { VimeoView } from "./VimeoView";
import { LoomView } from "./LoomView";

/*
NOTES:
 mouseEvent="onMouseDown" for ClickAwayListeners. Without problems with stopPropagation() in selctions
 should perhaps find another solution to stopPropagation?
*/
export function ElementView({ eid }: { eid: string }) {
  const { doc, viewMode, presentation } = useContext(DocumentCtx);
  const element = doc.elements[eid];
  let x: any;

  const { ref, inView } = useInView({
    triggerOnce: true
  });

  const visible =
    presentation && viewMode === "view" ? (inView ? true : false) : true;

  switch (element.type) {
    case "body":
      x = <BodyView body={element} />;
      break;
    case "text":
      x = <TextView text={element} />;
      break;
    case "image":
      x = <ImageView image={element} />;
      break;
    case "placeholderImage":
      x = <PlaceholderImageView placeholder={element} />;
      break;
    case "video":
      x = <VideoView video={element} />;
      break;
    case "heading":
      x = <HeadingView heading={element} />;
      break;
    case "section":
      x = <SectionView section={element} />;
      break;
    case "page":
      x = <PageView page={element} />;
      break;
    case "embed":
      x = <EmbedView embed={element} />;
      break;
    case "calendly":
      x = <CalendlyView calendly={element} />;
      break;
    case "loom":
      x = <LoomView loom={element} />;
      break;
    case "vimeo":
      x = <VimeoView vimeo={element} />;
      break;
  }
  if (x) {
    return (
      <SelectionWrapper element={element}>
        <Box
          ref={ref}
          data-eid={element.id}
          sx={{
            position: "relative",
            display: "flex",
            flex: 1,
            transition: "opacity 1.0s ease-out, transform 1.0s ease-out",
            transform: `translateY(${visible ? "0px" : "40px"})`,
            opacity: visible ? 1 : 0
          }}
        >
          {x}
          {doc.selection?.element === element.id && (
            <ElementLabel element={element} />
          )}
        </Box>
      </SelectionWrapper>
    );
  } else {
    return (
      // TODO: Create a proper component for this..
      <SelectionWrapper element={element}>
        <DeleteWrapper element={element}>
          <div>Unknown element {element.type}</div>
        </DeleteWrapper>
      </SelectionWrapper>
    );
  }
}
