import { Box, Button, Card, FormControl, FormLabel, TextField } from "@mui/joy";
import { ModalDialog } from "./ModalDialog";
import { NamedColor } from "./Model";
import { ModalBackdrop } from "./ModelBackdrop";

export function ColorEditorDialog({
  color,
  onColor,
  onClose
}: {
  color: NamedColor;
  onColor: (c: NamedColor) => void;
  onClose: () => void;
}) {
  return (
    <ModalDialog
      open={true}
      slots={{ backdrop: ModalBackdrop }}
      onClose={() => onClose()}
    >
      <Card>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr",
            rowGap: 24,
            columnGap: 12,
            padding: 24,
            maxWidth: 600,
            maxHeight: 500,
            alignItems: "center",
            overflow: "auto"
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <TextField
                autoFocus
                size="sm"
                variant="outlined"
                value={color.name}
                onChange={e => onColor({ ...color, name: e.target.value })}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    onClose();
                  }
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Color</FormLabel>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <input
                  type="color"
                  value={color.value}
                  onChange={e => onColor({ ...color, value: e.target.value })}
                ></input>
                <TextField
                  autoFocus
                  size="sm"
                  variant="outlined"
                  value={color.value}
                  onChange={e => onColor({ ...color, value: e.target.value })}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      onClose();
                    }
                  }}
                />
              </Box>
            </FormControl>
          </Box>
          <Button
            variant="soft"
            color="danger"
            size="sm"
            onClick={async () => {
              const r = window.confirm("Are you sure?");
              if (r) {
                await onColor({ ...color, deleted: true });
                onClose();
              }
            }}
          >
            Delete
          </Button>
        </div>
      </Card>
    </ModalDialog>
  );
}
