import { Box, Button, TextField, Typography } from "@mui/joy";
import { getAuth } from "firebase/auth";
import { collection, doc, getDoc, getFirestore } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { DocView } from "./DocView";
import { Error404 } from "./Error404";
import { FullPageCard } from "./FullPageCard";
import { FullscreenBusy } from "./FullscreenBusy";
import { ANON_NAME_KEY, useTrack } from "./Infospot";
import { DocumentLink, idCandidates } from "./Model";

export function PublicDocView() {
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      try {
        const candidates = idCandidates(id);
        for (const c of candidates) {
          const ref = doc(collection(getFirestore(), "links"), c);
          const snap = await getDoc(ref);

          if (snap.exists()) {
            setLink(snap.data() as DocumentLink);
            return;
          }
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  const [link, setLink] = useState<DocumentLink>();
  const [loading, setLoading] = useState(true);

  if (loading) {
    return <FullscreenBusy />;
  }

  if (!link) {
    return <Error404 />;
  }

  return <CheckName link={link} />;
}

function CheckName({ link }: { link: DocumentLink }) {
  let [searchParams] = useSearchParams();

  const [name, setName] = useState<string>(
    localStorage.getItem(ANON_NAME_KEY) ||
      getAuth().currentUser?.displayName ||
      (searchParams.get("anon") ? "anon" : "")
  );

  const [tmpName, setTmpName] = useState<string>();

  if (!name) {
    return (
      <FullPageCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: [350, 500],
            height: 300,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Typography level="h2" sx={{ fontWeight: 600 }} gutterBottom>
            Welcome!
          </Typography>
          <Typography level="body2" gutterBottom>
            What's your name?
          </Typography>
          <Box sx={{ display: "grid", rowGap: 3, mt: 1 }}>
            <TextField
              placeholder="Name"
              value={tmpName}
              onChange={e => setTmpName(e.target.value)}
            />
            <Button
              disabled={!tmpName}
              sx={{ minWidth: "24em", p: 1 }}
              variant="soft"
              onClick={() => {
                const n = tmpName || "";
                setName(n);
                localStorage.setItem(ANON_NAME_KEY, n);
              }}
            >
              Ok
            </Button>
          </Box>
        </Box>
      </FullPageCard>
    );
  }

  return <DoPublicDocView link={link} name={name} />;
}

function DoPublicDocView({ link, name }: { link: DocumentLink; name: string }) {
  const { id } = useParams();

  let [searchParams] = useSearchParams();

  const disableChat = !!searchParams.get("disableChat");
  const isAnonymous = () => !getAuth().currentUser?.uid;

  useTrack(
    "view_doc",
    {
      document: link.doc,
      workspace: link.workspace,
      id,
      name,
      anonymous: isAnonymous()
    },
    true
  );

  return (
    <DocView
      id={link.doc}
      workspace={link.workspace}
      publicDoc={true}
      disableChat={disableChat}
    />
  );
}
