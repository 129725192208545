export const removeKeys = (
  o: { [id: string]: any },
  keys: string[]
): { [id: string]: any } => {
  const r: { [id: string]: any } = {};
  const remove = new Set(keys);
  for (const k of Object.keys(o)) {
    if (!remove.has(k)) {
      r[k] = o[k];
    }
  }
  return r;
};
