import produce from "immer";
import { Document, ElementType, PageElement } from "./Model";

type ElementUpgrader = {
  from: number | undefined;
  to: number;
  type: ElementType;
  upgrader: (e: any) => any;
};

const elementUpgraders: ElementUpgrader[] = [
  {
    from: 3,
    to: 4,
    type: "page",
    upgrader: (p: PageElement) => {
      const s = p.style ?? {};

      if (s?.padding === "48px") {
        s.padding = "0px";
      }
      p.style = s;
    }
  }
];

const findUpgrader = (from: number, to: number, type: ElementType) => {
  for (const e of elementUpgraders) {
    if (e.from === from && e.to === to && e.type === type) {
      return e;
    }
  }
  return undefined;
};

export const upgradeDoc = <T extends Document>(doc: T, to: number): T => {
  const from = doc.version ?? 1;
  return produce(doc, draft => {
    draft.version = to;
    for (const e of Object.values(draft.elements)) {
      const ug = findUpgrader(from, to, e.type);
      if (ug) {
        ug.upgrader(e);
      }
    }
  });
};
