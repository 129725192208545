import { DragEndEvent, useDndMonitor } from "@dnd-kit/core";
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Box } from "@mui/joy";
import produce from "immer";
import { useContext, useMemo } from "react";
import { pageMaxWidth } from "./defaults";
import { DeleteWrapper } from "./DeleteWrapper";
import { ElementView } from "./ElementView";
import { DocumentCtx, styleToSx } from "./Infospot";
import { DocumentElement, SectionElement } from "./Model";

function SortableElement({
  element,
  visible,
  children
}: {
  element: DocumentElement;
  visible: boolean;
  children: any;
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: element.id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition
  };

  const { doc } = useContext(DocumentCtx);

  const selected = doc.selection?.element;
  const selectedElement = selected ? doc.elements[selected] : undefined;
  const outerListeners = selectedElement?.type === "text" ? {} : listeners;
  const innerListeners = listeners;

  return (
    <div
      ref={setNodeRef}
      style={{
        ...style,
        position: "relative",
        opacity: isDragging ? 0 : undefined,
        display: "flex",
        flex: 1
      }}
      {...attributes}
      {...outerListeners}
    >
      {element.type === "text" && (
        <div
          style={{
            position: "absolute",
            //display: "flex",
            flexDirection: "column",
            left: -24,
            alignItems: "center",
            justifyItems: "center",
            justifyContent: "center",
            flex: 1,
            //top: "50%",
            height: "100%",
            //backgroundColor: "yellow",
            zIndex: 10,
            display: visible ? "flex" : "none"
          }}
        >
          <DragIndicatorIcon {...innerListeners} sx={{ color: "#808080" }} />
        </div>
      )}
      {children}
    </div>
  );
}

function CandidateElement({
  dir,
  position,
  columnGap,
  rowGap
}: {
  dir: "column" | "row";
  position: "before" | "after";
  columnGap: number;
  rowGap: number;
}) {
  const sig = position === "after" ? 1 : -1;
  return (
    <Box
      sx={{
        display: "flex",
        flex: 0,
        zIndex: 100,
        position: "relative"
      }}
    >
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          flex: 1,
          top: dir === "column" ? sig * rowGap * 8 * 0.5 : 0,
          left: dir === "row" ? sig * columnGap * 8 * 0.5 : 0,
          height: dir === "column" ? "2px" : "100%",
          width: dir === "row" ? "2px" : "100%",
          backgroundColor: position === "after" ? "#999" : "#999"
        }}
      ></Box>
    </Box>
  );
}

const elementGridWidth = (e: DocumentElement) => {
  if (e.type === "image") {
    if (e.style?.maxWidth === "100%" || !e.style?.maxWidth) {
      return "1fr";
    } else {
      return "auto";
    }
    //return "minmax(auto, 1fr)";
  } else if (e.type === "placeholderImage") {
    return "1fr";
  } else {
    return "1fr";
  }
};

export function SectionView({
  section,
  pageMode = false
}: {
  section: SectionElement;
  pageMode?: boolean;
}) {
  //const bg = section.background ?? "default";
  const dir = section.style?.direction ?? "column";

  const {
    doc: doc_,
    colors,
    updateDoc,
    viewMode,
    renderContext,
    candidate
  } = useContext(DocumentCtx);

  useDndMonitor({
    onDragEnd(e) {
      handleDragEnd(e);
    }
  });

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    const thisSection =
      (section.elements || []).indexOf(active?.id as any) !== -1;

    if (
      over &&
      thisSection &&
      active?.id !== over?.id &&
      active.data?.current?.type !== "template"
    ) {
      updateDoc(doc =>
        produce(doc, draft => {
          const es = draft.elements[section.id].elements ?? [];
          const oldIndex = es.indexOf(active.id as string);
          const newIndex = es.indexOf(over.id as string);

          if (oldIndex !== -1 && newIndex !== -1) {
            draft.elements[section.id].elements = arrayMove(
              es,
              oldIndex,
              newIndex
            );
          }
        })
      );
    }
  }

  const elements = useMemo(() => {
    let e = [...(section.elements ?? [])];
    return e;
  }, [section.elements]);

  const columnGap = 3;
  const rowGap = pageMode ? 6 : 3;

  const dataAttributes = section?.style?.attributes ?? {};
  return (
    <SortableContext
      items={elements}
      strategy={
        dir === "column"
          ? verticalListSortingStrategy
          : horizontalListSortingStrategy
      }
    >
      <DeleteWrapper element={section} disabled={pageMode}>
        <Box
          {...dataAttributes}
          sx={{
            position: "relative",
            display: "flex",
            flexDirection:
              dir === "row" ? ["column", "column", "row"] : "column",
            flex: 1,
            //gridAutoFlow: dir === "row" ? "column" : "row",
            columnGap,
            rowGap,
            margin: pageMode ? 0 : 0,
            // TODO: change 1fr to auto and test
            /*gridTemplateColumns:
              dir === "row"
                ? viewMode === "view"
                  ? [
                      "1fr",
                      "1fr",
                      (section?.elements || [])
                        .map(e => elementGridWidth(doc_.elements[e]))
                        .join(" ")
                    ]
                  : (section?.elements || [])
                      .map(e => elementGridWidth(doc_.elements[e]))
                      .join(" ")
                : "unset",

            gridTemplateRows:
              dir === "column"
                ? `repeat(${section?.elements?.length ?? 0}, max-content)`
                : "unset",
*/
            //gridAutoColumns: "1fr",
            // TODO: remove gridAutoRows: "max-content", and test
            //gridAutoRows: "max-content",
            boxSizing: "border-box",
            //justifyContent: "center",
            ...styleToSx(section.style, colors),
            maxWidth: pageMode ? "unset" : `${pageMaxWidth}px`,
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          {elements.map((e, i) => (
            <Box
              key={e}
              sx={{
                display: "flex",
                position: "relative",
                flex: 1,
                //height: "100%",
                flexDirection: dir
              }}
            >
              {candidate?.parent === section.id &&
              candidate?.element === e &&
              candidate?.position === "before" ? (
                <CandidateElement
                  dir={dir}
                  position={candidate?.position}
                  columnGap={columnGap}
                  rowGap={rowGap}
                ></CandidateElement>
              ) : null}

              {renderContext === "main" ? (
                <SortableElement element={doc_.elements[e]} visible={true}>
                  <ElementView eid={e} />
                </SortableElement>
              ) : (
                <ElementView eid={e} />
              )}

              {candidate?.parent === section.id &&
              candidate?.element === e &&
              candidate?.position === "after" ? (
                <CandidateElement
                  dir={dir}
                  position={candidate?.position}
                  columnGap={columnGap}
                  rowGap={rowGap}
                ></CandidateElement>
              ) : null}
              <Box
                sx={{
                  position: "absolute",
                  border:
                    candidate?.parent === e && !candidate.element
                      ? "2px solid lightblue"
                      : "2px solid transparent",
                  left: 0,
                  top: 0,
                  right: 0,
                  bottom: 0,
                  borderRadius: 8,
                  pointerEvents: "none"
                }}
              />
            </Box>
          ))}
        </Box>
      </DeleteWrapper>
    </SortableContext>
  );
}
