import { getAuth, IdTokenResult } from "firebase/auth";
import produce from "immer";
import mixpanel from "mixpanel-browser";
import { customAlphabet } from "nanoid";
import { createContext, Dispatch, SetStateAction, useEffect } from "react";
import { getFn } from "./fns";
import {
  Document,
  DocumentElement,
  ElementType,
  GoogleFont,
  NamedColor,
  Style
} from "./Model";
import murmurhash from "murmurhash";
import { removeKeys } from "./util";
import { WithId } from "./data";
import { allStyles } from "./defaults";

export const ANON_NAME_KEY = "formalife-name";

export const FEATURES = new Set<string>([]);

export const track = (event: string, params: any) => {
  const distinct_id = mixpanel.get_distinct_id();
  const user_id = getAuth().currentUser?.uid;

  const customer = !user_id;

  const track = getFn<any>("track");
  track({
    event,
    properties: {
      distinct_id,
      user_id,
      environment: window.location.hostname,
      customer,
      ...params
    }
  });
};

export const useTrack = (
  event: string,
  params: any = undefined,
  internal = false
) => {
  useEffect(() => {
    console.log("track!", event, params);
    // TODO: mixpanel is writing to the second argument (token). Why!? Hence the copy..
    mixpanel.track(event, { ...params });

    if (internal) {
      track(event, params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export type Candidate = {
  parent: string;
  element?: string;
  position?: "before" | "after";
};

export type DocumentContextType = {
  public: boolean;
  doc: Document;
  //workspace: Workspace;
  workspace: string;
  colors: { [id: string]: NamedColor };
  viewMode: "edit" | "view";
  // presentation implies css animation
  presentation: boolean;
  //setDoc: Dispatch<SetStateAction<Document>>;
  updateDoc: (f: (flow: Document) => void) => void;
  fileDropActive: boolean;
  // TODO: Currently of out sync with selection in doc..
  selectionElement: HTMLElement | null;
  setSelectionElement: Dispatch<SetStateAction<HTMLElement | null>>;
  renderContext: "main" | "other";
  usePreviewAssets?: boolean;
  candidate?: Candidate;
};

export type AppContextType = {
  debug: boolean;
  features: Set<string>;
  idTokenResult?: IdTokenResult;
};

export const DocumentCtx = createContext<DocumentContextType>({} as any);
export const AppCtx = createContext<AppContextType>({} as any);

export const elementCreator = (t: ElementType): DocumentElement | undefined => {
  const id = newId();

  switch (t) {
    case "body": {
      return { id, type: "body", alignment: "start", text: "Hello" };
    }
    case "text": {
      return {
        id,
        type: "text",
        text: [{ type: "paragraph", children: [{ text: "" }] }]
      };
    }
    case "heading": {
      return { id, size: 1, color: "black", type: "heading", text: "Hello" };
    }
    case "section": {
      return {
        id,
        type: "section",
        elements: []
      };
    }
    case "video": {
      return {
        id,
        type: "video",
        src: "https://www.apple.com/105/media/ww/tv-home/2022/4447b88b-1a33-4bb3-98a1-61d8949e1098/anim/sizzle/sizzle_3/large_2x.mp4#t=0.391544"
      };
    }
    case "image": {
      return {
        id,
        type: "image",
        src: ""
      };
    }
    case "placeholderImage": {
      return {
        id,
        type: "placeholderImage"
      };
    }
    case "embed": {
      return {
        id,
        type: "embed",
        url: "https://docs.google.com/presentation/d/17DsCMCVKCxt7fGLFYm9IuU8SO5LKLqUmveVyyEWKbYg/embed"
      };
    }
  }
  return undefined;
};

export const garbageCollect = <T extends Document>(doc: T): T => {
  return produce(doc, draft => {
    const reachable = new Set<string>();

    const f = (id: string) => {
      reachable.add(id);
      for (const x of draft.elements[id].elements ?? []) {
        f(x);
      }
    };

    f(doc.root);

    for (const x of Object.keys(doc.elements)) {
      if (!reachable.has(x)) {
        delete draft.elements[x];
      }
    }
  });
};

export const cloneDocument = (
  doc: Document
): [Document, { [id: string]: string }] => {
  const idMap: { [id: string]: string } = {};
  const dp = produce(doc, draft => {
    for (const e of Object.keys(draft.elements)) {
      idMap[e] = newId();
    }
    draft.root = idMap[doc.root];

    draft.elements = Object.fromEntries(
      Object.entries(draft.elements).map(([id, e]) => [
        idMap[id],
        { ...e, id: idMap[id] }
      ])
    );

    for (const e of Object.values(draft.elements)) {
      if (e.elements) {
        e.elements = e.elements.map(x => idMap[x]);
      }
    }
  });

  return [dp, idMap];
};

export const findParent = (
  doc: Document,
  el: DocumentElement
): DocumentElement | null => {
  for (const e of Object.values(doc.elements)) {
    const i = e.elements?.indexOf(el.id);
    if (i !== undefined && i !== -1) {
      return e;
    }
  }
  return null;
};

export async function sha1(buffer: ArrayBuffer) {
  //const buffer = new TextEncoder("utf-8").encode(str);
  const hash = await crypto.subtle.digest("SHA-1", buffer);
  const hexCodes = [];
  const view = new DataView(hash);
  for (let i = 0; i < view.byteLength; i += 1) {
    const byte = view.getUint8(i).toString(16);
    hexCodes.push(byte);
  }
  return hexCodes.join("");
}

export const documentHash = (d: Document) => {
  const versionSource = `${d.modified.valueOf()}`;
  const versionHash = murmurhash.v3(versionSource); // NOTE: Skip for now. Too many cache missing when releasing all the time + import.meta.env.VITE_BUILD_HASH;
  return versionHash;
};

export const getFunctionsURL = () => {
  if (import.meta.env.VITE_FUNCTION_REGION === "local") {
    return "http://localhost:5001/getformalife/europe-west1";
  } else {
    return "https://europe-west1-getformalife.cloudfunctions.net";
  }
};

export const randomShortId = () => {
  const length = 8;
  const base =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789".split("");
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);
  let str = "";
  for (let i = 0; i < array.length; i++) {
    str += base[array[i] % base.length];
  }
  return str;
};

// same as urlAlphabet in nanoid but without "-"
const idAlphabet =
  "useandom26T198340PX75pxJACKVERYMINDBUSHWOLF_GQZbfghjklqvwyzrict";

const customNanoId = customAlphabet(idAlphabet);

export const newId = (): string => {
  return customNanoId();
};

export const getDocumentAssetURLs = (doc: Document) => {
  const ret: string[] = [];
  for (const x of Object.values(doc.elements)) {
    switch (x.type) {
      case "image":
        ret.push(x.src);
        break;
      case "video":
        ret.push(x.src);
        break;
    }
  }
  return ret;
};

const selectKeys = (
  o: { [id: string]: any },
  keys: string[]
): { [id: string]: any } => {
  const r: { [id: string]: any } = {};
  for (const k of keys) {
    r[k] = o[k];
  }
  return r;
};

const removeEmptyValues = (o: { [id: string]: any }) => {
  const r: { [id: string]: any } = {};
  for (const k of Object.keys(o)) {
    const v = o[k];
    if (v) {
      r[k] = v;
    }
  }
  return r;
};

const resolveColor = (
  o: any,
  key: string,
  colors: { [id: string]: NamedColor }
) => {
  const color = o[key];
  if (color) {
    const nc = colors[color];
    if (nc) {
      return { ...o, [key]: nc.value };
    } else {
      return { ...o, [key]: "red" };
    }
  } else {
    return o;
  }
};

const normalizeSize = (s: string) => {
  if (s.match(/[0-9]+$/)) {
    return `${s}px`;
  } else {
    return s;
  }
};

const resolveSize = (o: any, key: string) => {
  const size = o[key];

  if (size) {
    return { ...o, [key]: normalizeSize(size) };
  } else {
    return o;
  }
};

const removeFalsyAndWhitespaceStrings = (o: {
  [id: string]: any;
}): { [id: string]: any } => {
  const r: { [id: string]: any } = {};

  for (const k of Object.keys(o)) {
    let x = o[k];
    if (typeof x === "string") {
      x = x.trim();
    }

    if (x) {
      r[k] = o[k];
    }
  }
  return r;
};

export const styleToSx = (
  style: Style | undefined,
  colors: { [id: string]: NamedColor }
) => {
  const border =
    style?.borderEnabled && style?.borderWidth
      ? {
          ...selectKeys(style ?? {}, ["borderWidth"]),
          borderStyle: "solid",
          borderColor: style?.borderColor
        }
      : null;

  const rest = removeKeys(style ?? {}, [
    "borderWidth",
    "borderColor",
    "borderEnabled",
    "attributes"
  ]);

  let r = removeFalsyAndWhitespaceStrings(rest);
  r = resolveColor(
    removeEmptyValues({ ...border, ...r }),
    "backgroundColor",
    colors
  );
  r = resolveColor(r, "borderColor", colors);
  r = resolveSize(r, "borderWidth");
  r = resolveSize(r, "borderRadius");
  r = resolveSize(r, "padding");
  r = resolveSize(r, "maxWidth");
  r = resolveSize(r, "margin");

  return r;
};

export const imageOrVideo = (el: DocumentElement) =>
  el.type === "image" || el.type === "video";

export const upgradeDocDynamically = (d: WithId<Document>) => {
  return produce(d, draft => {
    if (!draft.styles) {
      draft.styles = allStyles;
    }
  });
};

export const docFonts = (doc: WithId<Document>): GoogleFont[] => {
  const families = new Set(
    (doc.styles || []).map(s => s.fontFamily).filter(x => !!x)
  ) as Set<string>;
  const fonts: GoogleFont[] = [...families.values()].map(family => ({
    family
  }));
  return fonts;
};

export const isBuiltinStyle = (style: string) => {
  return !!allStyles.find(s => s.id === style);
};
