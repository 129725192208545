import {
  Box,
  Button,
  CircularProgress,
  List,
  ListDivider,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Typography
} from "@mui/joy";
import { collection, getFirestore, query } from "firebase/firestore";
import { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { useCollection, WithId } from "./data";
import { getFn } from "./fns";
import { AppCtx } from "./Infospot";
import { Workspace } from "./Model";

const Workspaces = () => {
  const { data: workspaces, loading } = useCollection<Workspace>(
    query(collection(getFirestore(), "workspaces"))
  );
  const appCtx = useContext(AppCtx);

  if (loading) {
    return <CircularProgress variant="soft" />;
  }

  const count = (workspaces || []).length;
  return (
    <List
      sx={{
        width: 400,
        borderRadius: "sm",
        boxShadow: "sm",
        bgcolor: "background.body"
      }}
      variant="outlined"
    >
      {(workspaces || []).map((w, i) => (
        <Fragment key={w._id}>
          <ListItem>
            <ListItemButton component={Link} to={`/dashboard/${w._id}`}>
              <ListItemContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  {w.name}
                </Box>
              </ListItemContent>

              {appCtx?.idTokenResult?.claims.developer && (
                <ListItemDecorator>
                  <Button
                    size="sm"
                    onClick={async e => {
                      e.preventDefault();
                      //e.stopPropagation();
                      console.log("CLICK");

                      if (window.confirm("Are you sure?")) {
                        const upgradeWorkspace =
                          getFn<string>("upgradeWorkspace");
                        const r = await upgradeWorkspace({ workspace: w._id });
                        console.log("RRR", r);
                      }
                    }}
                  >
                    Upgrade
                  </Button>
                </ListItemDecorator>
              )}
            </ListItemButton>
          </ListItem>
          {i < count - 1 && <ListDivider />}
        </Fragment>
      ))}
    </List>
  );
};

export function Admin({ workspaceDoc }: { workspaceDoc: WithId<Workspace> }) {
  return (
    <Box>
      <Typography level="h3" gutterBottom sx={{ fontWeight: 600 }}>
        Admin
      </Typography>
      <Workspaces />
    </Box>
  );
}
