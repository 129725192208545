import Option from "@mui/joy/Option";
import { PlainSelect } from "./PlainSelect";

export function AlignmentSelector({
  alignment,
  onAlignment
}: {
  alignment: "start" | "center" | "end" | undefined;
  onAlignment: (alignment: "start" | "center" | "end" | undefined) => void;
}) {
  return (
    <PlainSelect
      className="toolbar-item"
      value={alignment}
      size="sm"
      sx={{
        minWidth: 150
      }}
      onChange={(e, v) => {
        onAlignment(v ?? undefined);
      }}
    >
      {["start", "center", "end"].map(p => (
        <Option key={p} value={p}>
          <span style={{ textTransform: "capitalize" }}> {p ?? "center"}</span>
        </Option>
      ))}
    </PlainSelect>
  );
}
