import { Dayjs } from "dayjs";
import { Descendant } from "slate";

export const DOC_VERSION = 4;

export type NamedColor = {
  id: string;
  name: string;
  value: string;
  deleted?: boolean;
};

export type ChatMessage = {
  from: { uid: string; name: string };
  message: string;
  date: Dayjs;
  unread: boolean;
  deleted: boolean;
  ref: string;
};

export type UserInfo = {
  uid: string;
  name: string;
};

export type UserInfoEmail = UserInfo & {
  email: string;
  photoURL?: string;
};

export type Role = "owner" | "collaborator";
export const Roles: Role[] = ["owner", "collaborator"];

export type WorkspaceMember = {
  uid: string;
  role: Role;
};

export type Workspace = {
  name: string;
  members: { [id: string]: WorkspaceMember };
  colors?: NamedColor[];
  slackConnection?: SlackConnection;
};

export type ContactInfo = {
  name: string;
  email: string;
};

export type CompanyInfoString = {
  type: "string";
  name: string;
};

export type CompanyInfoHubspot = {
  type: "hubspot";
  id: string;
  name: string;
};

export type CompanyInfo = { contact: ContactInfo } & (
  | CompanyInfoString
  | CompanyInfoHubspot
);

export type Style = {
  padding?: string;
  margin?: string;
  alignItems?: string;
  direction?: "row" | "column";

  backgroundColor?: string;
  borderRadius?: string;

  borderEnabled?: boolean;
  borderWidth?: string;
  borderColor?: string;

  maxWidth?: string;
  fontFamily?: string;

  attributes?: { [id: string]: string };
};

export type GoogleFont = {
  family: string;
  weights?: string[];
};

export type TextStyle = {
  id: string;
  name: string;
  element: "h1" | "h2" | "h3" | "h4" | "p";
  fontFamily?: string;
  fontSize?: string;
  margin?: string;
  fontWeight?: string;
  lineHeight?: string;
  letterSpacing?: string;
};

export type BaseElement = {
  id: string;
  type: ElementType;
  name?: string;
  elements?: string[];
  style?: Style;
  customStyleEnabled?: boolean;
  customStyle?: string;
};

export type ElementType =
  | "body"
  | "text"
  | "heading"
  | "section"
  | "page"
  | "image"
  | "placeholderImage"
  | "video"
  | "embed"
  | "calendly"
  | "loom"
  | "vimeo";

export type DocumentElement =
  | BodyElement
  | TextElement
  | HeadingElement
  | SectionElement
  | PageElement
  | ImageElement
  | PlaceholderImageElement
  | VideoElement
  | TableElement
  | EmbedElement
  | CalendlyElement
  | LoomElement
  | VimeoElement;

export type BodyElement = BaseElement & {
  type: "body";
  text: string;
  color?: string;
  alignment: "start" | "center" | "end";
};

export type TextElement = BaseElement & {
  type: "text";
  text: Descendant[];
};

export type HeadingElement = BaseElement & {
  type: "heading";
  size: number;
  color: string;
  text: string;
};

export type SectionElement = BaseElement & {
  type: "section";
};

export type PageElement = BaseElement & {
  type: "page";
};

export type ImageElement = BaseElement & {
  type: "image";
  src: string;
};
export type PlaceholderImageElement = BaseElement & {
  type: "placeholderImage";
};
export type VideoElement = BaseElement & {
  type: "video";
  src: string;
};
export type EmbedElement = BaseElement & { type: "embed"; url: string };

export type CalendlyElement = BaseElement & {
  type: "calendly";
  url?: string;
};

export type VimeoElement = BaseElement & {
  type: "vimeo";
  url?: string;
};

export type LoomElement = BaseElement & {
  type: "loom";
  url?: string;
};

export type TableElement = BaseElement & { type: "table"; columns: string[] };

export type Document = {
  version?: number;
  shortId?: string;
  linkName?: string;
  root: string;
  type: "document" | "template";
  readonly?: boolean;
  //template: boolean;
  //sectionTemplate?: boolean;
  companyInfo?: CompanyInfo;
  createdBy?: UserInfo;
  created: Dayjs;
  modified: Dayjs;
  deleted?: true;
  name: string;
  elements: { [id: string]: DocumentElement };
  selection: { element: string; slateSelection: any } | null;
  styles?: TextStyle[];
};

export type DocumentLink = {
  doc: string;
  workspace: string;
  published?: Dayjs; // ? for backwards compat
  ogImage?: string;
};

export type ChatSummary = {
  unread: number;
  total: number;
};

export type TrackingSummary = {
  views: number;
  unique: number;
};

export type PublishedDocument = Document & {
  publishedColors?: { [id: string]: NamedColor };
  chatSummary?: ChatSummary;
  trackingSummary?: TrackingSummary;
};

export type SlackState = {
  workspace: string;
  redirectTo: string;
};

export type SlackConnection = {
  channel: string;
};

export type Event = {
  event: "chat" | "view_doc";
  properties: {
    distinct_id: string;
    customer: boolean;
  };
};

// NOTE: This circus below is for compatability
// The syntax of a link is as follows NAME-ID
// where ID is the actual ID and NAME and NAME.
// ID should not contain "-" but old document does (nanoid with standard alphabet)
// If we would remove all docs with id:s containing "-" we could avoid try to guess the id
export const idCandidates = (id?: string): string[] => {
  const candidates: string[] = [];
  if (id) {
    const [, ...rest] = id.split("-");
    if (rest.length > 0) {
      candidates.push(rest.join("-"));
    }
    candidates.push(id);
  }
  return candidates;
};
