import dayjs from "dayjs";
import {
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  FirestoreError,
  onSnapshot,
  Query,
  QueryDocumentSnapshot,
  queryEqual,
  SnapshotOptions,
  Timestamp,
  WithFieldValue
} from "firebase/firestore";
import produce from "immer";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

export type WithId<T> = { _id: string } & {
  [P in keyof T]: T[P];
};

// NOTE: We can't import firebase-admin here.
let makeTimeStamp = (d: Date) => Timestamp.fromDate(d);

export const setMakeTimestamp = (f: (d: Date) => any) => {
  makeTimeStamp = f;
};

const doToJS = (obj: any) => {
  if (obj === null || obj === undefined) {
    return obj;
  } else if (obj instanceof Timestamp) {
    return dayjs(obj.toDate());
  } else if (
    obj["_seconds"] !== undefined &&
    obj["_nanoseconds"] !== undefined &&
    obj.constructor.name === "Timestamp"
  ) {
    return dayjs(obj.toDate());
  } else if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      obj[i] = doToJS(obj[i]);
    }
  } else if (typeof obj === "object") {
    for (const [k, v] of Object.entries(obj)) {
      // NOTE: Infinite recursion if DocumentReference is traversed. Circular?
      if (!(v instanceof DocumentReference)) {
        obj[k] = doToJS(v);
      }
    }
  }

  return obj;
};

const doToDS = (obj: any) => {
  if (obj === null || obj === undefined) {
    return obj;
  } else if (obj instanceof dayjs) {
    return makeTimeStamp((obj as dayjs.Dayjs).toDate());
  } else if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      obj[i] = doToDS(obj[i]);
    }
  } else if (typeof obj === "object") {
    for (const [k, v] of Object.entries(obj)) {
      obj[k] = doToDS(v);
    }
  }

  return obj;
};

export const toJS = <T>(obj: T): T => produce(obj, draft => doToJS(draft));

export const toDS = (obj: any): any =>
  produce(obj, (draft: any) => doToDS(draft));

export const fsConverter: FirestoreDataConverter<any> = {
  toFirestore(data: WithFieldValue<any>): DocumentData {
    return toDS(data);
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): any {
    const data = snapshot.data(options);
    return toJS(data as any);
  }
};

export const addId = <T>(o: T, id: string): WithId<T> => {
  const r: WithId<T> = Object.defineProperty({ ...o }, "_id", {
    enumerable: false,
    writable: true
  }) as WithId<T>;
  r._id = id;
  return r;
};

export const useDocument = <T>(
  ref: DocumentReference<any> | null,
  options?: { upgrade?: (d: WithId<T>) => WithId<T> }
): {
  data?: WithId<T>;
  setData: Dispatch<SetStateAction<WithId<T> | undefined>>;
  error?: FirestoreError;
  loading: boolean;
} => {
  const [data, setData] = useState<WithId<T>>();
  const [error, setError] = useState<FirestoreError>();
  const [loading, setLoading] = useState(true);
  const [r, setR] = useState(ref);

  if (ref) {
    if (!r) {
      setR(ref);
    } else if (ref.path !== r.path) {
      setR(ref);
    }
  }

  const upgrade = options?.upgrade ?? ((d: WithId<T>) => d);

  useEffect(() => {
    if (!r) {
      setLoading(false);
      return;
    }
    const unsubscribe = onSnapshot<T>(
      r,
      snapshot => {
        const s = snapshot.data();
        setLoading(false);
        if (s) {
          const d = upgrade(toJS<WithId<T>>(addId(s, snapshot.id)));
          // It seems like order it important
          setData(d);
          setLoading(false);
        } else {
          setData(undefined);
          setLoading(false);
        }
      },
      (e: FirestoreError) => {
        console.error(e);
        setLoading(false);
        setError(e);
      }
    );

    return unsubscribe;
  }, [r]);

  return { data, setData, error, loading };
};

export const useCollection = <T>(
  ref: Query<any> | null
): {
  data?: WithId<T>[];
  setData: Dispatch<SetStateAction<WithId<T>[] | undefined>>;
  error?: FirestoreError;
  loading: boolean;
} => {
  const [data, setData] = useState<WithId<T>[]>();
  const [error, setError] = useState<FirestoreError>();
  const [loading, setLoading] = useState(true);
  const [r, setR] = useState(ref);

  if (ref && !r) {
    setR(ref);
    setLoading(true);
  } else if (ref && r && !queryEqual(ref, r)) {
    setR(ref);
    setLoading(true);
  }

  useEffect(() => {
    if (!r) {
      return;
    }
    const unsubscribe = onSnapshot<T>(
      r,
      snapshot => {
        const xs = snapshot.docs.map(x => addId(toJS(x.data()), x.id));
        setData(xs);
        setLoading(false);
      },
      (e: FirestoreError) => {
        console.error(e);
        setLoading(false);
        setError(e);
      }
    );

    return unsubscribe;
  }, [r]);

  return { data, setData, error, loading };
};
