import { useEffect, useState } from "react";
import { getFn } from "./fns";
import { UserInfoEmail } from "./Model";

const g_UserInfoCache: { [id: string]: UserInfoEmail } = {};

export const useUserInfo = (uid: string) => {
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState<UserInfoEmail>();

  useEffect(() => {
    if (!uid) {
      return;
    }
    (async () => {
      try {
        if (g_UserInfoCache[uid]) {
          setUserInfo(g_UserInfoCache[uid]);
        } else {
          const getUserInfo = getFn<UserInfoEmail>("getUserInfo");
          const r = await getUserInfo({ uid });
          if (r.data.ok) {
            g_UserInfoCache[uid] = r.data.value;
            setUserInfo(r.data.value);
          }
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [uid]);

  return { userInfo, loading };
};
