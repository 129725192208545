import { GlobalStyles } from "@mui/system";

export const DocumentStyles = () => {
  return (
    <GlobalStyles
      styles={() => {
        return {
          'div[data-fullwidth="true"]': { maxWidth: "unset" },
          'video[data-fullwidth="true"]': { width: "100%", maxWidth: "100%" },
          'img[data-fullwidth="true"]': { maxWidth: "100%", width: "100%" },
          ".text": { marginLeft: "auto", marginRight: "auto" },
          ".video": { marginLeft: "auto", marginRight: "auto" }
        };
      }}
    />
  );
};
