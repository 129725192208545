import { CssBaseline, CssVarsProvider, StyledEngineProvider } from "@mui/joy";
import { initializeApp } from "firebase/app";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import mixpanel from "mixpanel-browser";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { firebaseConfig } from "./config";
import "./index.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

console.log(`build ${import.meta.env.VITE_BUILD_HASH}`);

const MIXPANEL_DEVELOPMENT = "8c2e95edbf5b7d016d60fd7e515b47dc";
const MIXPANEL_PRODUCTION = "e6830ff734f69582a92ff74865862906";

const MIXPANEL_TOKEN =
  window.location.hostname.indexOf("app.infospot.co") !== -1
    ? MIXPANEL_PRODUCTION
    : MIXPANEL_DEVELOPMENT;

mixpanel.init(MIXPANEL_TOKEN, {
  debug: MIXPANEL_TOKEN === MIXPANEL_DEVELOPMENT,
  ignore_dnt: true
});
mixpanel.track("start");

if (window.location.hostname.indexOf("app.infospot.co") !== -1) {
  Sentry.init({
    dsn: "https://b2bc101350b94eeb960428b797b4eca3@o4504922150404096.ingest.sentry.io/4504922155909120",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0
  });
}

const app = initializeApp(firebaseConfig);

if (import.meta.env.VITE_FUNCTION_REGION === "local") {
  console.log("Using local function emulator at http://localhost:5001");
  connectFunctionsEmulator(
    getFunctions(app, "europe-west1"),
    "localhost",
    5001
  );
}

if (import.meta.env.VITE_LOCAL_FIRESTORE) {
  console.log("Using local firestore at http://localhost:8080");
  const db = getFirestore();
  connectFirestoreEmulator(db, "localhost", 8080);
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <CssVarsProvider>
        <CssBaseline />
        <App />
      </CssVarsProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);
