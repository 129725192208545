import { createEditor } from "slate";
import { Slate, Editable, withReact } from "slate-react";
import { useMemo } from "react";

export function SimpleEditor({
  text,
  onChange,
  readOnly = false
}: {
  text: string;
  onChange: (text: string) => void;
  readOnly?: boolean;
}) {
  const editor = useMemo(() => withReact(createEditor()), []);

  return (
    <Slate
      editor={editor}
      value={[
        {
          type: "paragraph",
          children: [{ text }]
        } as any
      ]}
      onChange={value => {
        const isAstChange = editor.operations.some(
          op => "set_selection" !== op.type
        );
        if (isAstChange) {
          onChange(value.map(x => (x as any).children[0].text).join("\n"));
        }
      }}
    >
      <Editable readOnly={readOnly} placeholder="Enter some plain text..." />
    </Slate>
  );
}
