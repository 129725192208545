import { Box, Input } from "@mui/joy";
import { useContext } from "react";
import { DeleteWrapper } from "./DeleteWrapper";
import { DocumentCtx, styleToSx } from "./Infospot";
import { VimeoElement } from "./Model";
import VIMEO_LOGO from "./assets/vimeo.svg";
import { Toolbar } from "./Toolbar";

// https://vimeo.com/451438010

const vimeoID = (url: string) => {
  const m = /.*?vimeo.com\/(.*?)$/.exec(url);
  if (m) {
    return m[1];
  } else {
    return undefined;
  }
};

export function VimeoView({ vimeo }: { vimeo: VimeoElement }) {
  const { usePreviewAssets, colors, doc, selectionElement, updateDoc } =
    useContext(DocumentCtx);

  const id = vimeoID(vimeo.url ?? "");
  return (
    <DeleteWrapper element={vimeo}>
      <Toolbar
        open={doc.selection?.element === vimeo.id && !!selectionElement}
        anchorEl={selectionElement}
        onClose={() => {}}
      >
        <Input
          className="toolbar-item"
          value={vimeo.url}
          sx={{ width: "30em" }}
          variant="plain"
          onChange={e => {
            updateDoc(d => {
              (d.elements[vimeo.id] as VimeoElement).url = e.target.value;
            });
          }}
          placeholder="Vimeo Link"
          size="sm"
        />
      </Toolbar>

      <Box
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          ...styleToSx(vimeo.style, colors)
        }}
      >
        {usePreviewAssets || !id ? (
          <img
            src={VIMEO_LOGO}
            alt="Vimeo Logo"
            style={{ padding: 32, width: "100%", maxWidth: 600 }}
          />
        ) : (
          <iframe
            src={`https://player.vimeo.com/video/${id}?h=1a3b059653&title=0&byline=0&portrait=0`}
            width="640"
            height="360"
            frameBorder={0}
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="Video"
            style={{ maxWidth: "100%" }}
          ></iframe>
        )}
      </Box>
    </DeleteWrapper>
  );
}
