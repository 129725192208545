import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalDialog,
  Typography
} from "@mui/joy";
import { getAuth } from "firebase/auth";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { DragEvent, useState } from "react";
import { getFn } from "./fns";
import { resizeImage } from "./image";
import { sha1 } from "./Infospot";
import { CurrentUserAvatar } from "./CurrentUserAvatar";

export function ProfileDialog({ onClose }: { onClose: () => void }) {
  const [blob, setBlob] = useState<Blob>();
  const [busy, setBusy] = useState(false);
  const [name, setName] = useState(getAuth().currentUser?.displayName ?? "");
  const [dataUrl, setDataUrl] = useState("");

  const googleSignIn = !!(getAuth().currentUser?.providerData || []).find(
    p => p.providerId === "google.com"
  );

  const profileImageEditEnabled = !googleSignIn;

  const save = async () => {
    setBusy(true);
    const currentUser = getAuth().currentUser;
    if (!currentUser) {
      return;
    }
    try {
      let photoURL = "";

      if (blob) {
        const uid = currentUser.uid;
        const r = ref(getStorage(), `avatars/${uid}.jpg`);
        await uploadBytes(r, blob, {
          cacheControl: "public, max-age=2592000, s-maxage=2592000"
        });
        const u = new URL(await getDownloadURL(r));
        u.searchParams.append("version", await sha1(await blob.arrayBuffer()));
        photoURL = u.toString();
      }

      const uu = getFn("updateUser");
      await uu({ name, photoURL });
      await currentUser.reload();
      onClose();
    } finally {
      setBusy(false);
    }
  };

  const setAvatar = async (f: File) => {
    const uid = getAuth().currentUser?.uid;
    if (uid && f) {
      try {
        setBusy(true);
        const { blob, dataUrl } = await resizeImage(f, 300, 300, "JPEG", 95, 0);

        setBlob(blob);
        setDataUrl(dataUrl);
        //console.log("R", r);
      } finally {
        setBusy(false);
      }
    }
  };

  const onDrop = async (e: DragEvent<HTMLDivElement>) => {
    if (!profileImageEditEnabled) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    const f = e.dataTransfer.items?.[0]?.getAsFile();
    if (f) {
      setAvatar(f);
    }
  };

  const onDragOver = (e: DragEvent<HTMLDivElement>) => {
    if (!profileImageEditEnabled) {
      return;
    }
    e.preventDefault();
    return false;
  };

  return (
    <Modal open={true} onClose={onClose}>
      <ModalDialog
        sx={{
          width: 500,
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          gap: 1
        }}
      >
        <Typography level="h4" gutterBottom>
          Edit your profile
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            gap: 2
          }}
        >
          <FormControl>
            <FormLabel>Profile image</FormLabel>
            <label htmlFor="profileInput">
              {profileImageEditEnabled && (
                <input
                  id="profileInput"
                  type="file"
                  accept="image/*"
                  onChange={e => {
                    const f = e.target.files?.[0];
                    if (f) {
                      setAvatar(f);
                    }
                  }}
                  style={{ display: "none" }}
                ></input>
              )}
              <CurrentUserAvatar
                onDrop={onDrop}
                onDragOver={onDragOver}
                size="lg"
                src={dataUrl || getAuth().currentUser?.photoURL || ""}
                sx={{
                  width: 80,
                  height: 80,
                  [`&:hover`]: {
                    filter: profileImageEditEnabled
                      ? "brightness(70%)"
                      : "unset"
                  }
                }}
              />
            </label>
            {googleSignIn && (
              <Typography level="body3" sx={{ mt: 1 }}>
                Profile image from Google Account
              </Typography>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input value={name} onChange={e => setName(e.target.value)}></Input>
          </FormControl>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: 1,
              alignSelf: "end"
            }}
          >
            <Button
              disabled={busy}
              loading={busy}
              variant="soft"
              size="sm"
              sx={{ mt: 4, alignSelf: "center" }}
              onClick={save}
            >
              Save
            </Button>
            <Button
              disabled={busy}
              size="sm"
              variant="soft"
              color="neutral"
              sx={{ mt: 4, alignSelf: "center" }}
              onClick={onClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </ModalDialog>
    </Modal>
  );
}
