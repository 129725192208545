import {
  Box,
  Card,
  Modal,
  ModalDialog,
  Option,
  Select,
  Typography
} from "@mui/joy";
import Autocomplete from "@mui/joy/Autocomplete";
import { SxProps } from "@mui/material";
import { useEffect, useState } from "react";
import { CssInput } from "./CssInput";
import { FullscreenBusy } from "./FullscreenBusy";
import { TextStyle } from "./Model";
import { useGoogleFonts } from "./useGoogleFonts";
import { removeKeys } from "./util";

const useJson = <T,>({ url }: { url: string }) => {
  const [value, setValue] = useState<T>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const x = await (await fetch(url)).json();
        setValue(x);
      } finally {
        setLoading(false);
      }
    })();
  }, [url]);

  return { loading, value };
};

function SimpleSelect<T extends string>({
  options,
  value,
  onValue,
  sx = {}
}: {
  options: { label: string; value: any }[];
  value: T;
  onValue: (value: T) => void;
  sx?: SxProps;
}) {
  return (
    <Select
      value={value}
      size="sm"
      sx={{ ...sx }}
      onChange={(e, v) => {
        if (v) {
          onValue(v);
        }
      }}
    >
      {options.map(p => (
        <Option key={p.value} value={p.value}>
          <span style={{ textTransform: "capitalize" }}> {p.label}</span>
        </Option>
      ))}
    </Select>
  );
}

const Label = ({ label }: { label: string }) => (
  <Typography level="body2" sx={{ color: "black" }}>
    {label}
  </Typography>
);

const fontWeightsTable = [
  { value: "100", label: "Thin (Hairline)" },
  { value: "200", label: "Extra Light (Ultra Light)" },
  { value: "300", label: "Light" },
  { value: "400", label: "Normal (Regular)" },
  { value: "500", label: "Medium" },
  { value: "600", label: "Semi Bold (Demi Bold)" },
  { value: "700", label: "Bold" },
  { value: "800", label: "Extra Bold (Ultra Bold)" },
  { value: "900", label: "Black (Heavy)" },
  { value: "950", label: "Extra Black (Ultra Black)" }
];

type GoogleFontDesc = {
  family: string;
  variants: string[];
  subsets: string[];
  version: string[];
  lastModified: string[];
  category: string[];
  kind: string[];
};

type GoogleFontsList = { kind: string; items: GoogleFontDesc[] };

export const StyleEditorDialog = ({
  style,
  onStyle
}: {
  style: TextStyle;
  onStyle: (style: TextStyle | undefined) => void;
}) => {
  const [newStyle, setNewStyle] = useState(style);

  useGoogleFonts(newStyle.fontFamily ? [{ family: newStyle.fontFamily }] : []);

  const { value: fonts, loading } = useJson<GoogleFontsList>({
    url: "https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyBJSu-OJRhiarNsjcS5jiDL_Q54LW5MzFA&sort=popularity"
  });

  const googleFonts = (fonts || { items: [] }).items.map(i => i.family);

  const fontWeights = (family?: string) => {
    const font = fonts?.items.find(f => f.family === family);
    if (font) {
      const ws = font.variants.map(v => (v === "regular" ? "400" : v));
      return fontWeightsTable.filter(x => ws.indexOf(x.value) !== -1);
    } else {
      return fontWeightsTable;
    }
  };

  const TextProp = ({
    prop,
    label
  }: {
    prop: keyof TextStyle;
    label: string;
  }) => {
    return (
      <>
        <Label label={label} />
        <CssInput
          value={newStyle[prop] ?? ""}
          onValue={v => setNewStyle(s => ({ ...s, [prop]: v }))}
        />
      </>
    );
  };

  return (
    <Modal
      open={true}
      onClose={e => {
        onStyle(newStyle);
      }}
      slotProps={{
        backdrop: {
          invisible: true,
          "data-testid": "backdrop",
          sx: { backdropFilter: "blur(0px)", backgroundColor: "unset" }
        }
      }}
    >
      <ModalDialog
        sx={{
          minWidth: 350,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        {loading && <FullscreenBusy />}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%"
          }}
        >
          <Typography level="h4">Edit Style</Typography>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "max-content 1fr",
              alignItems: "center",
              columnGap: 2,
              rowGap: 2,
              mt: 2
            }}
          >
            <TextProp prop="name" label="Name" />
            <Label label="Element" />
            <SimpleSelect
              options={["h1", "h2", "h3", "h4", "p"].map(x => ({
                label: x,
                value: x
              }))}
              value={newStyle.element}
              onValue={v => v && setNewStyle(s => ({ ...s, element: v }))}
            />
            <Label label="Font" />
            <Autocomplete
              value={newStyle.fontFamily}
              onChange={(e, v) =>
                setNewStyle(s => {
                  const { fontFamily, ...rest } = s;
                  if (v) {
                    return { ...s, fontFamily: v };
                  } else {
                    return rest;
                  }
                })
              }
              size="sm"
              placeholder="Font"
              options={googleFonts}
              sx={{ width: 300 }}
              slotProps={{
                listbox: {
                  disablePortal: true
                }
              }}
            />
            <TextProp prop="fontSize" label="Font Size" />
            <TextProp prop="margin" label="Margin" />
            <Label label="Font Weight" />
            <SimpleSelect
              options={fontWeights(newStyle.fontFamily)}
              value={newStyle.fontWeight ?? ""}
              onValue={v => v && setNewStyle(s => ({ ...s, fontWeight: v }))}
            />

            <TextProp prop="lineHeight" label="Line Height" />
            <TextProp prop="letterSpacing" label="Letter Spacing" />
          </Box>

          <Box sx={{ mt: 2 }}>
            <Card variant="outlined" sx={{ boxShadow: "none" }}>
              <Box sx={removeKeys(newStyle, ["id", "name", "element"])}>
                PREVIEW
                <br />
                PREVIEW
              </Box>
            </Card>
          </Box>
        </Box>
      </ModalDialog>
    </Modal>
  );
};
