import AddIcon from "@mui/icons-material/Add";
import TableRowsIcon from "@mui/icons-material/TableRows";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Select,
  Switch,
  Textarea,
  Typography
} from "@mui/joy";
import { useContext, useRef, useState } from "react";
import { DocumentCtx, isBuiltinStyle, newId } from "./Infospot";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ListItemDecorator } from "@mui/joy";
import Option from "@mui/joy/Option";
import { ListItemText } from "@mui/material";
import produce from "immer";
import { Command, CommandMenu } from "./CommandMenu";
import { CssInput } from "./CssInput";
import { WithId } from "./data";
import { Body1 } from "./defaults";
import {
  BaseElement,
  GoogleFont,
  NamedColor,
  TextStyle,
  Workspace
} from "./Model";
import { StyleEditorDialog } from "./StyleEditorDialog";

import HorizontalAlignCenter from "./assets/horizontal-align-center.svg";
import HorizontalAlignEnd from "./assets/horizontal-align-end.svg";
import HorizontalAlignStart from "./assets/horizontal-align-start.svg";
import HorizontalAlignStretch from "./assets/horizontal-align-stretch.svg";
import VerticalAlignCenter from "./assets/vertical-align-center.svg";
import VerticalAlignEnd from "./assets/vertical-align-end.svg";
import VerticalAlignStart from "./assets/vertical-align-start.svg";
import VerticalAlignStretch from "./assets/vertical-align-stretch.svg";

const MAX_WIDTH = "10em";

function ColorPicker2({
  disabled = false,
  colors,
  color,
  onColor
}: {
  disabled?: boolean;
  colors: { [id: string]: NamedColor };
  color: string | undefined;
  onColor: (color: string | undefined) => void;
}) {
  return (
    <Select
      className="toolbar-item"
      disabled={disabled}
      value={color ?? "-"}
      size="sm"
      sx={{
        minWidth: MAX_WIDTH
      }}
      onChange={(e, v) => {
        onColor(v ?? undefined);
      }}
    >
      {Object.entries(colors)
        .filter(e => !e[1].deleted)
        .map(([id, c]) => (
          <Option key={id} value={id}>
            <ListItemDecorator>
              <div
                style={{
                  width: 20,
                  height: 20,
                  backgroundColor: colors[id]?.value ?? "red",
                  border: "1px solid #ccc",
                  borderRadius: "50%",
                  marginRight: 6
                }}
              />
            </ListItemDecorator>
            {c.name}
          </Option>
        ))}
    </Select>
  );
}

function AlignmentSelector2({
  direction,
  alignment,
  onAlignment
}: {
  direction: string;
  alignment: string | undefined;
  onAlignment: (alignment: string | undefined) => void;
}) {
  const renderIcon = (a: string) => {
    let img = "";
    if (direction === "row") {
      switch (a) {
        case "start":
          img = VerticalAlignStart;
          break;
        case "center":
          img = VerticalAlignCenter;
          break;
        case "end":
          img = VerticalAlignEnd;
          break;
        case "stretch":
          img = VerticalAlignStretch;
          break;
      }
    } else {
      switch (a) {
        case "start":
          img = HorizontalAlignStart;
          break;
        case "center":
          img = HorizontalAlignCenter;
          break;
        case "end":
          img = HorizontalAlignEnd;
          break;
        case "stretch":
          img = HorizontalAlignStretch;
          break;
      }
    }
    return <img src={img} style={{ width: 16, height: 16 }} alt={a} />;
  };
  return (
    <Select
      value={alignment}
      size="sm"
      sx={{
        minWidth: MAX_WIDTH
      }}
      onChange={(e, v) => {
        onAlignment(v ?? undefined);
      }}
    >
      {["start", "center", "end", "stretch"].map(p => (
        <Option key={p} value={p}>
          <ListItemDecorator sx={{ mr: 1 }}>{renderIcon(p)}</ListItemDecorator>
          <span style={{ textTransform: "capitalize" }}> {p ?? "center"}</span>
        </Option>
      ))}
    </Select>
  );
}

function FontSelector({
  fonts,
  font,
  onFont
}: {
  fonts: GoogleFont[];
  font: string | undefined;
  onFont: (alignment: string | undefined) => void;
}) {
  return (
    <Select
      value={font}
      size="sm"
      sx={{
        minWidth: MAX_WIDTH
      }}
      onChange={(e, v) => {
        onFont(v ?? undefined);
      }}
    >
      {fonts.map(f => (
        <Option key={f.family} value={f.family}>
          {f.family}
        </Option>
      ))}
    </Select>
  );
}

function DirectionSelector2({
  direction,
  onDirection
}: {
  direction: string;
  onDirection: (direction: string) => void;
}) {
  const renderIcon = (a: string) => {
    switch (a) {
      case "row":
        return <ViewColumnIcon />;
      case "column":
        return <TableRowsIcon />;
    }
  };

  return (
    <Select
      value={direction}
      size="sm"
      sx={{
        minWidth: MAX_WIDTH
      }}
      onChange={(e, v) => {
        if (v) {
          onDirection(v);
        }
      }}
    >
      {["row", "column"].map(p => (
        <Option key={p} value={p}>
          <ListItemDecorator sx={{ mr: 1 }}>{renderIcon(p)}</ListItemDecorator>
          <span style={{ textTransform: "capitalize" }}> {p}</span>
        </Option>
      ))}
    </Select>
  );
}

const PropertyBox = ({ label, children }: { label: string; children: any }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        mt: 0.5,
        mb: 0.5,
        pl: 2,
        pr: 2,
        justifyContent: "space-between"
      }}
    >
      <Typography level="body2" sx={{ color: "black" }}>
        {label}
      </Typography>

      {children}
    </Box>
  );
};

const StyleInput = ({
  value = "",
  onValue
}: {
  value: string;
  onValue: (v: string) => void;
}) => {
  //  const classes = useStyles();
  const state = useRef({ focus: false, value: "" });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dummy, setDummy] = useState(0);
  const ref = useRef<any>();

  return (
    <Textarea
      size="sm"
      minRows={4}
      sx={{
        backgroundColor: "white",
        mr: 2,
        ml: 2,
        fontSize: 14,
        fontFamily: "monospace"
      }}
      ref={ref}
      onFocus={e => {
        state.current.focus = true;
        state.current.value = value;
        e.target.select();
        //ref.current && ref.current.select();
      }}
      onBlur={() => {
        if (onValue) {
          onValue(state.current.value);
        }
        state.current.focus = false;
        state.current.value = "";
      }}
      onKeyDown={e => {
        if (e.code === "Enter" || e.code === "NumpadEnter") {
          const t = e.target as any;
          if (t.blur) {
            t.blur();
          }
        }
      }}
      onChange={e => {
        if (state.current.focus) {
          state.current.value = e.target.value;
          setDummy((d: number) => d + 1);
        }
      }}
      value={state.current.focus ? state.current.value : value}
    />
  );
};

const StyleEditors = ({ workspace }: { workspace: WithId<Workspace> }) => {
  const { doc, updateDoc, colors } = useContext(DocumentCtx);

  const selected = doc?.selection?.element
    ? doc.elements[doc.selection.element]
    : undefined;

  const updateStyle = (key: string, value: any) => {
    updateDoc(_ =>
      produce(doc, draft => {
        if (doc.selection?.element) {
          const el = draft.elements[doc.selection.element] as BaseElement;
          const style = el.style ?? ({} as any);
          style[key] = value;
          draft.elements[doc.selection.element].style = style;
        }
      })
    );
  };

  if (!selected) {
    return null;
  }
  return (
    <>
      <Divider />
      <PropertyBox label="Background Color">
        <ColorPicker2
          color={selected?.style?.backgroundColor ?? undefined}
          colors={colors}
          onColor={c => updateStyle("backgroundColor", c)}
        />
      </PropertyBox>
      <Divider />
      <PropertyBox label="Padding">
        <CssInput
          sx={{ maxWidth: MAX_WIDTH }}
          value={selected?.style?.padding ?? ""}
          onValue={v => updateStyle("padding", v)}
        />
      </PropertyBox>
      <Divider />
      <PropertyBox label="Border Radius">
        <CssInput
          sx={{ maxWidth: MAX_WIDTH }}
          value={selected?.style?.borderRadius ?? ""}
          onValue={v => {
            updateDoc(_ =>
              produce(doc, draft => {
                if (doc.selection?.element) {
                  const el = draft.elements[
                    doc.selection.element
                  ] as BaseElement;
                  const style = el.style ?? {};
                  style.borderRadius = v;
                  draft.elements[doc.selection.element].style = style;
                }
              })
            );
          }}
        />
      </PropertyBox>
      <PropertyBox label="Border">
        <Switch
          size="sm"
          checked={!!selected?.style?.borderEnabled}
          onChange={e => {
            updateStyle("borderEnabled", e.target.checked);
          }}
        />
      </PropertyBox>
      {!!selected?.style?.borderEnabled ? (
        <>
          <PropertyBox label="Border Width">
            <CssInput
              sx={{ maxWidth: MAX_WIDTH }}
              value={selected?.style?.borderWidth ?? ""}
              onValue={v => updateStyle("borderWidth", v)}
            />
          </PropertyBox>
          <PropertyBox label="Border Color">
            <ColorPicker2
              color={selected?.style?.borderColor ?? undefined}
              colors={colors}
              onColor={c => {
                updateDoc(_ =>
                  produce(doc, draft => {
                    if (doc.selection?.element) {
                      const e = draft.elements[
                        doc.selection.element
                      ] as BaseElement;
                      const style = e.style ?? {};
                      style.borderColor = c;
                      draft.elements[doc.selection.element].style = style;
                    }
                  })
                );
              }}
            />
          </PropertyBox>
          <Divider />
        </>
      ) : (
        <Divider />
      )}
      {selected.type === "section" && (
        <>
          <PropertyBox label="Direction">
            <DirectionSelector2
              direction={selected?.style?.direction ?? "column"}
              onDirection={d => updateStyle("direction", d)}
            />
          </PropertyBox>
          <Divider />
          <PropertyBox label="Alignment">
            <AlignmentSelector2
              direction={selected?.style?.direction ?? "column"}
              alignment={selected?.style?.alignItems ?? "stretch"}
              onAlignment={a => updateStyle("alignItems", a)}
            />
          </PropertyBox>
          <Divider />
        </>
      )}
      {selected?.type !== "page" && (
        <>
          <PropertyBox label="Max Width">
            <CssInput
              sx={{ maxWidth: MAX_WIDTH }}
              value={selected?.style?.maxWidth ?? ""}
              onValue={v => updateStyle("maxWidth", v)}
            />
          </PropertyBox>
          <Divider />
        </>
      )}
      {selected?.type !== "page" && (
        <>
          <PropertyBox label="Full Width">
            <Switch
              size="sm"
              checked={
                selected?.style?.attributes?.["data-fullWidth"] === "true"
              }
              onChange={e => {
                updateDoc(_ =>
                  produce(doc, draft => {
                    if (doc.selection?.element) {
                      const el = draft.elements[doc.selection.element];
                      const style = el.style ?? {};
                      const attr = style?.attributes ?? {};
                      attr["data-fullWidth"] =
                        attr["data-fullWidth"] === "true" ? "false" : "true";
                      style.attributes = attr;
                      el.style = style;
                    }
                  })
                );
              }}
            />
          </PropertyBox>
          <Divider />
        </>
      )}

      {false && (
        <>
          <PropertyBox label="Custom Styling">
            <Switch
              size="sm"
              checked={!!selected?.customStyleEnabled}
              onChange={e => {
                updateDoc(_ =>
                  produce(doc, draft => {
                    if (doc.selection?.element) {
                      const el = draft.elements[doc.selection.element];
                      el.customStyleEnabled = !el.customStyleEnabled;
                    }
                  })
                );
              }}
            />
          </PropertyBox>
          {!!selected?.customStyleEnabled ? (
            <>
              <StyleInput
                value={selected?.customStyle ?? ""}
                onValue={s => {
                  updateDoc(_ =>
                    produce(doc, draft => {
                      if (doc.selection?.element) {
                        const el = draft.elements[doc.selection.element];
                        el.customStyle = s;
                      }
                    })
                  );
                }}
              />

              <Divider />
            </>
          ) : (
            <Divider />
          )}
        </>
      )}
    </>
  );
};

const TextStyles = () => {
  const { doc, updateDoc } = useContext(DocumentCtx);

  const [editStyle, setEditStyle] = useState<TextStyle>();

  const styles = doc.styles || [];
  const commands: Command[] = [
    { id: "delete", label: "Delete", icon: DeleteOutlineIcon }
  ];

  const onCommand = (style: TextStyle, c: string) => {
    switch (c) {
      case "delete":
        deleteStyle(style);
        break;
    }
  };

  const deleteStyle = async (style: TextStyle) => {
    updateDoc(d => {
      if (d.styles) {
        d.styles = d.styles.filter(s => s.id !== style.id);
      }
    });
  };

  const updateStyle = async (style: TextStyle) => {
    updateDoc(d => {
      if (d.styles) {
        for (let i = 0; i < d.styles.length; i++) {
          if (d.styles[i].id === style.id) {
            d.styles[i] = style;
          }
        }
      }
    });
  };

  const addStyle = () => {
    updateDoc(d => {
      const ss = d.styles ?? [];
      ss.push({ ...Body1, id: newId(), name: "New Style" });
      d.styles = ss;
    });
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          pr: 2
        }}
      >
        <Typography level="h6" sx={{ pl: 2, pt: 2, pb: 2, color: "black" }}>
          Text Styles
        </Typography>
        <IconButton size="sm" variant="plain">
          <AddIcon onClick={addStyle} />
        </IconButton>
      </Box>
      <Divider />

      {editStyle && (
        <StyleEditorDialog
          style={editStyle}
          onStyle={s => {
            if (s) {
              updateStyle(s);
            }
            setEditStyle(undefined);
          }}
        />
      )}

      <List
        size="sm"
        //variant="outlined"
        sx={{
          p: 1,
          flex: 0,
          borderRadius: 0,
          backgroundColor: "#f0f0f0"
        }}
      >
        {styles.length === 0 && (
          <ListItem>
            <ListItemText>
              <i>No styles</i>
            </ListItemText>
          </ListItem>
        )}
        {styles.map(s => (
          <ListItem
            key={s.id}
            endAction={
              isBuiltinStyle(s.id) ? null : (
                <CommandMenu
                  commands={commands}
                  onCommand={c => onCommand(s, c)}
                />
              )
            }
          >
            <ListItemButton onClick={() => setEditStyle(s)}>
              <Box
                sx={{
                  display: "inline-flex",
                  fontSize: 14,
                  mr: 1,
                  backgroundColor: "black",
                  color: "white",
                  borderRadius: 4,
                  width: 32,
                  height: 32,
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                {s.element}
              </Box>
              {s.name}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </>
  );
};

export const StyleEditor = ({
  workspace
}: {
  workspace: WithId<Workspace>;
}) => {
  const { doc } = useContext(DocumentCtx);

  return (
    <Box
      sx={{
        display: "flex",
        minWidth: 350,
        flexDirection: "column",
        backgroundColor: "#f0f0f0",
        borderLeft: "1px solid #ccc"
      }}
    >
      <Box sx={{ backgroundColor: "white" }}>
        <Typography level="h6" sx={{ pl: 2, pt: 2, pb: 2, color: "black" }}>
          Block Style
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          minWidth: 350,
          flexDirection: "column",
          gap: 1
        }}
      >
        {/* NOTE: Remount the editors. Got strange callback errors before (old values from <Select>?) */}
        <StyleEditors
          key={doc.selection?.element ?? "no-sel"}
          workspace={workspace}
        />
      </Box>

      <TextStyles />
    </Box>
  );
};
