import { Box } from "@mui/joy";
import { Typography } from "@mui/material";

export function EmptyState({ msg }: { msg: string }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1

        //height: "100%"
      }}
    >
      <Typography onKeyDown={() => {}} sx={{ color: "text.secondary" }}>
        {msg}
      </Typography>
    </Box>
  );
}
