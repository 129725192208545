import { Box, Option, styled, Typography } from "@mui/joy";
import { Portal } from "@mui/material";
import deepEqual from "deep-equal";
import isHotkey from "is-hotkey";
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import {
  BaseEditor,
  createEditor,
  Descendant,
  Editor,
  Element as SlateElement,
  Node,
  Text,
  Transforms
} from "slate";
import { Editable, ReactEditor, Slate, useSlate, withReact } from "slate-react";
import { AlignmentSelector } from "./AlignmentSelector";
import { getColor } from "./Color";
import { ColorPicker } from "./ColorPicker";
import { DeleteWrapper } from "./DeleteWrapper";
import { DocumentCtx, styleToSx } from "./Infospot";
import { TextElement } from "./Model";
import { PlainSelect } from "./PlainSelect";
import { Toolbar } from "./Toolbar";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import { removeKeys } from "./util";
import { pageMaxWidth } from "./defaults";

const StyledEditable = styled(Editable)({});

const HOTKEYS: { [key: string]: string } = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code"
};

/*
slate.js undo and uncontrolled component

https://github.com/react-page/react-page/issues/1188
https://github.com/react-page/react-page/commit/ed7d2b8438a7dcf63536edc294980821bb2238c5#diff-b3b7bde30c2e6e9f379e434183f8f71935c64d61d22d236d092e3c7726131b84R57
https://github.com/ianstormtaylor/slate/issues/4992
*/

const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

const ToggleButton = styled("button")(
  ({ theme, "aria-pressed": pressed = "false" }) => ({
    padding: "0.5rem 1rem",
    //borderRadius: theme.vars.radius.xs,
    borderRadius: 0,
    display: "inline-flex",
    justifyContent: "center",
    gap: "8px",
    //minHeight: 40,
    fontFamily: theme.vars.fontFamily.body,
    fontSize: theme.vars.fontSize.md,
    fontWeight: theme.vars.fontWeight.md,
    alignItems: "center",
    border: "none",
    borderColor: theme.vars.palette.neutral.outlinedBorder,
    backgroundColor: theme.vars.palette.background.body,
    //boxShadow: theme.vars.shadow.md,
    [theme.focus.selector]: theme.focus.default,
    ...theme.variants.plain.neutral,
    ...(pressed === "false" && {
      "&:hover": theme.variants.plainHover.neutral,
      "&:active": theme.variants.plainActive.neutral
    }),
    ...(pressed === "true" && {
      color: theme.vars.palette.primary.softColor,
      //backgroundColor: theme.vars.palette.background.body
      backgroundColor: theme.vars.palette.neutral[200]
      //boxShadow: theme.shadow.sm.replace(/,/g, ", inset")
    })
  })
);

function SelectTextBlockType({
  disabled = false,
  blockType,
  onBlockType
}: {
  disabled?: boolean;
  blockType: string | undefined;
  onBlockType: (element: string | undefined) => void;
}) {
  const { doc } = useContext(DocumentCtx);
  const styles = doc.styles || [];

  return (
    <PlainSelect
      className="toolbar-item"
      disabled={disabled}
      size="sm"
      sx={{
        textTransform: "capitalize",
        minWidth: 150
      }}
      value={blockType}
      onChange={(e, v) => {
        e?.preventDefault();
        e?.stopPropagation();
        if (v) {
          onBlockType(v);
        }
      }}
    >
      {styles.map(c => (
        <Option key={c.id} value={c.id} style={{}}>
          <span style={{ textTransform: "capitalize" }}>{c.name}</span>
        </Option>
      ))}
    </PlainSelect>
  );
}

const setBlockType = (editor: any, type: string) => {
  /*  const isActive = isBlockActive(
    editor,
    type
    //TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
  );*/

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(type),
    split: true
  });
  const newProperties: Partial<CustomElement> = { type: type as any };
  Transforms.setNodes<SlateElement>(editor, newProperties);
};

const setAlignment = (editor: any, align: "start" | "center" | "end") => {
  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(align),
    split: true
  });
  const newProperties: Partial<CustomElement> = { align };
  Transforms.setNodes<SlateElement>(editor, newProperties);
};

const getMark = (editor: BaseEditor & ReactEditor, format: "color") => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] : undefined;
};

const TextEditorToolbar = ({ text }: { text: TextElement }) => {
  const { doc, selectionElement, colors } = useContext(DocumentCtx);

  const editor = useSlate();

  const current =
    editor.selection &&
    Editor.above(editor, {
      at: Editor.unhangRange(editor, editor.selection),
      match: n => !Editor.isEditor(n) && SlateElement.isElement(n)
    });
  const currentType = (current?.[0] as CustomElement)?.type;
  const currentAlignment = (current?.[0] as CustomElement)?.align;

  return (
    <Portal>
      <Toolbar
        open={doc.selection?.element === text.id && !!selectionElement}
        anchorEl={selectionElement}
        onClose={() => {
          //setSelectionElement(null);
        }}
      >
        <SelectTextBlockType
          blockType={currentType || "paragraph"}
          onBlockType={bt => {
            if (bt) {
              setBlockType(editor, bt);
              ReactEditor.focus(editor);
            }
          }}
        />
        <ColorPicker
          colors={colors}
          color={getMark(editor, "color")}
          onColor={c => {
            if (c) {
              // TODO: c sometimes undefined. Why!?
              Editor.addMark(editor, "color", c);
              ReactEditor.focus(editor);
            }
          }}
        />
        <AlignmentSelector
          alignment={currentAlignment ?? "start"}
          onAlignment={a => {
            if (a) {
              setAlignment(editor, a);
              ReactEditor.focus(editor);
            }
          }}
        />
        <ToggleButton
          className="toolbar-item"
          aria-pressed={isMarkActive(editor, "bold") ? "true" : "false"}
          onMouseDown={e => {
            e.preventDefault();
            toggleMark(editor, "bold");
          }}
        >
          <FormatBoldIcon sx={{ width: 20, height: 20 }} />
        </ToggleButton>
        <ToggleButton
          className="toolbar-item"
          aria-pressed={isMarkActive(editor, "italic") ? "true" : "false"}
          onMouseDown={e => {
            e.preventDefault();
            toggleMark(editor, "italic");
          }}
        >
          <FormatItalicIcon sx={{ width: 20, height: 20 }} />
        </ToggleButton>

        <ToggleButton
          className="toolbar-item"
          aria-pressed={isMarkActive(editor, "underline") ? "true" : "false"}
          onMouseDown={e => {
            e.preventDefault();
            toggleMark(editor, "underline");
          }}
        >
          <FormatUnderlinedIcon sx={{ width: 20, height: 20 }} />
        </ToggleButton>
      </Toolbar>
    </Portal>
  );
};

const SlateViewer = ({ value }: { value: Descendant[] }) => {
  // TODO: BAR below!??!
  return (
    <>
      {value.map((d, i) =>
        Text.isText(d) ? (
          <div>BAR</div>
        ) : (
          <Element
            key={i}
            attributes={{}}
            children={d.children.map((x, i) => (
              <Leaf attributes={{}} key={i} leaf={x} children={x.text} />
            ))}
            element={d}
          />
        )
      )}
    </>
  );
};

const TextView = ({ text }: { text: TextElement }) => {
  const dataAttributes = text?.style?.attributes ?? {};

  const renderElement = useCallback((props: any) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
  const editor = useMemo(() => withReact(createEditor()), []);
  const { doc, updateDoc, viewMode, colors, selectionElement } =
    useContext(DocumentCtx);

  const ref = useRef(text.text);

  useEffect(() => {
    if (!deepEqual(text.text, editor.children) /*text.text !== ref.current*/) {
      ref.current = text.text;
      editor.children = text.text;

      if (doc.selection?.slateSelection) {
        try {
          ReactEditor.focus(editor);
        } catch (e) {
          // ignore, can happen
        }
        // update seleciton, if changed from outside (e.g. through undo)
        Transforms.select(editor, doc.selection.slateSelection);
      } else {
        Transforms.deselect(editor);
      }
    }
  }, [text.text]);

  const onChange = useCallback(
    (value: Descendant[]) => {
      if (!deepEqual(editor.children, text.text)) {
        const isAstChange = editor.operations.some(
          op => "set_selection" !== op.type
        );

        if (isAstChange) {
          if (!deepEqual(doc.elements[text.id], value)) {
            updateDoc(d => {
              (d.elements[text.id] as TextElement).text = value;
              d.selection = {
                element: text.id,
                slateSelection: editor.selection
              };
              ref.current = value;
            });
          }
        }
      }
    },
    [doc, editor, text, updateDoc]
  );

  return (
    <DeleteWrapper element={text}>
      {viewMode === "view" ? (
        <Box
          className="text"
          {...dataAttributes}
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            maxWidth: `${pageMaxWidth}px`,
            ...styleToSx(text.style, colors)
          }}
        >
          <SlateViewer value={text.text} />
        </Box>
      ) : (
        <Slate editor={editor} value={text.text} onChange={onChange}>
          {doc.selection?.element === text.id && !!selectionElement && (
            <TextEditorToolbar text={text} />
          )}

          <StyledEditable
            className="text"
            {...dataAttributes}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder="Text..."
            sx={{
              flex: 1,
              maxWidth: `${pageMaxWidth}px`,
              ...styleToSx(text.style, colors)
            }}
            spellCheck
            onKeyDown={event => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event as any)) {
                  event.preventDefault();
                  const mark = HOTKEYS[hotkey];
                  toggleMark(editor, mark);
                }
              }
              if (event.key === "Enter" && editor.selection) {
                // https://github.com/ianstormtaylor/slate/issues/97
                const selectedElement = Node.descendant(
                  editor,
                  editor.selection.anchor.path.slice(0, -1)
                );

                // Replace 'title' with the type of the element which you wish to "break out" from
                if (
                  !Text.isText(selectedElement) &&
                  (selectedElement.type.startsWith("heading-") ||
                    selectedElement.type.startsWith("display-"))
                ) {
                  event.preventDefault();
                  const selectedLeaf = Node.descendant(
                    editor,
                    editor.selection.anchor.path
                  );

                  if (
                    Text.isText(selectedLeaf) &&
                    selectedLeaf.text.length === editor.selection.anchor.offset
                  ) {
                    Transforms.insertNodes(editor, {
                      type: "paragraph",
                      children: [{ text: "" /*, marks: []*/ }]
                    });
                  } else {
                    Transforms.splitNodes(editor, { always: true });
                    Transforms.setNodes(editor, { type: "paragraph" });
                  }
                }
              }
            }}
          />
        </Slate>
      )}
    </DeleteWrapper>
  );
};

const toggleMark = (editor: any, format: any) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

/*
const isBlockActive = (editor: any, format: any) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format
    })
  );

  return !!match;
};
*/
const isMarkActive = (editor: any, format: "bold" | "italic" | "underline") => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Element = ({
  attributes,
  children,
  element
}: {
  attributes: any;
  children: any;
  element: CustomElement;
}) => {
  const style = { textAlign: element.align, fontFamily: "unset" };
  const { doc } = useContext(DocumentCtx);
  const styles = doc.styles || [];

  const textStyle = styles.find(x => x.id === element.type);
  if (textStyle) {
    const E = textStyle.element;
    return (
      <E
        style={{
          margin: 0, // reset default
          ...style,
          ...styleToSx(removeKeys(textStyle, ["id", "name", "element"]), {})
        }}
        {...attributes}
      >
        {children}
      </E>
    );
  }

  // TODO: Do we even get here now with the styles?

  switch (element.type) {
    case "display-1":
      return (
        <Typography level="display1" style={style} {...attributes}>
          {children}
        </Typography>
      );
    case "display-2":
      return (
        <Typography level="display2" style={style} {...attributes}>
          {children}
        </Typography>
      );
    case "heading-1":
      return (
        <Typography level="h1" style={style} {...attributes}>
          {children}
        </Typography>
      );
    case "heading-2":
      return (
        <Typography level="h2" style={style} {...attributes}>
          {children}
        </Typography>
      );
    case "heading-3":
      return (
        <Typography level="h3" style={style} {...attributes}>
          {children}
        </Typography>
      );
    case "heading-4":
      return (
        <Typography level="h4" style={style} {...attributes}>
          {children}
        </Typography>
      );
    case "large-text":
      return (
        <Typography style={{ ...style, fontSize: 19 }} {...attributes}>
          {children}
        </Typography>
      );
    default:
      return (
        <Typography style={style} {...attributes}>
          {children}
        </Typography>
      );
  }
};

const Leaf = ({
  attributes,
  children,
  leaf
}: {
  attributes: any;
  children: any;
  leaf: CustomText;
}) => {
  const { colors } = useContext(DocumentCtx);
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <i>{children}</i>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return (
    <span
      style={{ color: getColor(leaf.color ?? "black", colors) }}
      {...attributes}
    >
      {children}
    </span>
  );
};

type ParagraphElement = {
  type: "paragraph";
};

type LargeTextElement = {
  type: "large-text";
};

type HeadingDisplayOneElement = {
  type: "display-1";
};

type HeadingDisplayTwoElement = {
  type: "display-2";
};

type HeadingOneElement = {
  type: "heading-1";
};

type HeadingTwoElement = {
  type: "heading-2";
};

type HeadingThreeElement = {
  type: "heading-3";
};

type HeadingFourElement = {
  type: "heading-4";
};

type CustomElement = {
  align?: "start" | "center" | "end";
  children: CustomText[];
} & (
  | ParagraphElement
  | LargeTextElement
  | HeadingDisplayOneElement
  | HeadingDisplayTwoElement
  | HeadingOneElement
  | HeadingTwoElement
  | HeadingThreeElement
  | HeadingFourElement
);

type CustomText = {
  text: string;
  color?: string;
  bold?: true;
  italic?: true;
  underline?: true;
  code?: true;
  red?: true;
};

declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor; //& HistoryEditor
    Element: CustomElement;
    Text: CustomText;
  }
}

export default TextView;
