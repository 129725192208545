import { Box, Typography } from "@mui/joy";
import { SxProps } from "@mui/material";

export function EmptyState2({
  sx = {},
  title,
  message
}: {
  sx?: SxProps;
  title: string;
  message: JSX.Element | string;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "center",
        ...sx
      }}
    >
      <Typography level="h3" gutterBottom>
        {title}
      </Typography>
      <Typography
        level="body1"
        sx={{ fontSize: "1.2rem", maxWidth: 500, textAlign: "center" }}
      >
        {message}
      </Typography>
    </Box>
  );
}
