import { useEffect } from "react";
import { GoogleFont } from "./Model";

const familyStr = (family: string, weights: string[]) => {
  const opts =
    "ital,wght@" +
    weights.map(x => `0,${x}`).join(";") +
    ";" +
    weights.map(x => `1,${x}`).join(";");

  return `family=${family.replaceAll(" ", "+")}:${opts}`;
};

export const googleFontsToLink = (fonts: GoogleFont[]) => {
  const allWeights = [
    "100",
    "200",
    "300",
    "400",
    "500",
    "600",
    "700",
    "800",
    "900",
    "950"
  ];

  const families = fonts
    .map(f => familyStr(f.family, f.weights || allWeights))
    .join("&");
  const href = `https://fonts.googleapis.com/css2?${families}&display=swap`;
  return href;
};

export const useGoogleFonts = (fonts: GoogleFont[]) => {
  const href = googleFontsToLink(fonts);

  useEffect(() => {
    if (!href) return;
    const link = document.createElement("link");
    link.href = href;
    link.rel = "stylesheet";

    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, [href]);
};
