import { Box } from "@mui/joy";

export function TopBar({ children }: { children: any }): JSX.Element {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        p: 1,
        backgroundColor: "white",
        borderBottom: "1px solid #ccc"
      }}
    >
      {children}
    </Box>
  );
}
