import { DndContext } from "@dnd-kit/core";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import {
  Box,
  CssBaseline,
  CssVarsProvider,
  extendTheme,
  Option,
  Select,
  styled
} from "@mui/joy";
import { useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { getColors } from "./Color";
import { WithId } from "./data";
import { DocumentContainer } from "./DocumentContainer";
import { DocumentStyles } from "./DocumentStyles";
import { ElementView } from "./ElementView";
import { DocumentCtx, docFonts } from "./Infospot";
import { Document, GoogleFont, Workspace } from "./Model";
import { googleFontsToLink } from "./useGoogleFonts";

function DoDocPreview({
  doc_,
  workspaceDoc
}: {
  doc_: WithId<Document>;
  workspaceDoc: WithId<Workspace>;
}) {
  const colors = useMemo(() => getColors(workspaceDoc), [workspaceDoc]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      {/* TODO: DndContext as we have useDndMonitor in SectionView... better solution!?? */}
      <DndContext>
        <DocumentCtx.Provider
          value={{
            public: false,
            viewMode: "view",
            presentation: false,
            doc: { ...doc_, selection: null },
            workspace: workspaceDoc._id,
            colors,
            //setDoc,
            updateDoc: () => {},
            fileDropActive: false,
            selectionElement: null,
            setSelectionElement: () => {},
            renderContext: "other"
          }}
        >
          <DocumentContainer doc={doc_}>
            <ElementView eid={doc_.root} />
          </DocumentContainer>
        </DocumentCtx.Provider>
      </DndContext>
    </Box>
  );
}

const PreviewIframe = styled("iframe")(({ width }: { width: string }) => ({
  border: "none",
  height: "100%",
  width
}));

const PreviewPortal = ({
  width,
  fonts,
  children
}: {
  width: string;
  fonts: GoogleFont[];
  children: any;
}) => {
  const [contentRef, setContentRef] = useState<any>(null);
  const mountNode = contentRef?.contentWindow?.document?.body;
  const headMountNode = contentRef?.contentWindow?.document?.head;
  const cache = createCache({
    key: "mycss",
    container: contentRef?.contentWindow?.document?.head,
    prepend: true
  });
  return (
    <PreviewIframe ref={setContentRef} width={width}>
      {mountNode &&
        createPortal(
          <CacheProvider value={cache}>
            <CssVarsProvider theme={extendTheme({ cssVarPrefix: "iframe" })}>
              <CssBaseline />
              <DocumentStyles />
              {children}
            </CssVarsProvider>
          </CacheProvider>,
          mountNode
        )}
      {headMountNode &&
        createPortal(
          <>
            <meta charSet="UTF-8" />

            <link rel="icon" type="image/svg+xml" href="/vite.svg" />
            <link
              href="https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,600;1,700;1,800&display=swap"
              rel="stylesheet"
              crossOrigin="anonymous"
            />
            <link
              rel="preconnect"
              href="https://fonts.googleapis.com"
              crossOrigin="anonymous"
            />
            <link
              rel="preconnect"
              href="https://fonts.gstatic.com"
              crossOrigin="anonymous"
            />
            <link
              href="https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@800&family=Dosis:wght@300;400&family=PT+Sans:wght@400;700&display=swap"
              rel="stylesheet"
              crossOrigin="anonymous"
            />
            <link
              href={googleFontsToLink(fonts)}
              rel="stylesheet"
              crossOrigin="anonymous"
            />

            <meta
              name="viewport"
              id="viewport"
              content="width=device-width, initial-scale=1.0"
            />
          </>,
          headMountNode
        )}
    </PreviewIframe>
  );
};

export function DocPreview({
  doc_,
  workspaceDoc,
  disableSizeSelect = false
}: {
  doc_: WithId<Document>;
  workspaceDoc: WithId<Workspace>;
  disableSizeSelect?: boolean;
}) {
  const sizes = { Desktop: "100%", Mobile: "400px" };
  const [size, setSize] = useState(sizes["Desktop"]);
  const fonts = docFonts(doc_);

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      {!disableSizeSelect && (
        <Select
          sx={{
            position: "absolute",
            top: 8,
            left: 8,
            minWidth: 100
          }}
          size="sm"
          value={size}
          onChange={(_, v) => v && setSize(v)}
        >
          {Object.entries(sizes).map(([k, v]) => (
            <Option key={k} value={v}>
              {k}
            </Option>
          ))}
        </Select>
      )}
      {disableSizeSelect ? (
        <DoDocPreview doc_={doc_} workspaceDoc={workspaceDoc} />
      ) : (
        <PreviewPortal width={size} fonts={fonts}>
          <DoDocPreview doc_={doc_} workspaceDoc={workspaceDoc} />
        </PreviewPortal>
      )}
    </Box>
  );
}
